import React from "react";
import Tooltip from "@mui/material/Tooltip";

const Avatars = (props: { order?: string; items: any }) => {
  const { items, order } = props;

  if (Array.isArray(items)) {
    return (
      <div className={items.length >4 ?"avatars l4": "avatars l"+items.length}>
        {items
          .slice(0, 3)
          .map((avatar: { color: string; label: string }, index: number) => {
            return (
              <Tooltip key={index} title={avatar.label}>
                <div
                  className="contractor_thumbnail"
                  style={{ backgroundColor: avatar.color, color: "#FFF" }}
                >
                  {avatar.label.substring(0, 2)}
                </div>
              </Tooltip>
            );
          })}
        {items.length > 3 && (
          <div className="contractor_thumbnail more">+ {items.length - 3}</div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default Avatars;
