/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

/** Storage */

import { setItem } from "Utils/Storage";

/** Images */
import backgroundImage  from "Assets/Images/backgrounds/1.jpg";

/** API */
import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

function Validate() {
  const { t } = useTranslation();
  const { hash } = useParams();

  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const [accountValidated, setAccountValidated] = useState(false);
  const [hashIsUsed, sethashIsUsed] = useState(false);
  useEffect(() => {
    if (hash && !hashIsUsed) {
      userApi.postValidate(hash).then(
        (result: any) => {
          if (result.status === "ok") {
            sethashIsUsed(true);
            setAccountValidated(true);
            setPageLoading(false);
            if (result?.token) {
              setItem("token", result.token);
            }
          }
        },
        (error) => {
          console.log(error);
          setPageLoading(false);
        }
      );
    } else {
      setPageLoading(false);
    }
  }, [hash, hashIsUsed]);

  return (
    <div className="login_container">
      {pageLoading && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
      <div className="form">
        <div className="content">
          {accountValidated ? (
            <div className="switch_account_action success">
              <strong>{t("ACCOUNT.FORM.SUCCESS.account_validated")}</strong>
              <span>{t("GLOBAL.LABELS.perfect")}!</span>
              <br />
              <span>
                {t("ACCOUNT.FORM.SUCCESS.account_validated_with_login")}
              </span>
              <div className="actions">
                <Button
                  variant="contained"
                  onClick={() => navigate("/dashboard")}
                >
                  {t("ACCOUNT.ACTIONS.start")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="switch_account_action error">
              <strong>{t("ACCOUNT.FORM.ERRORS.account_not_validated")}</strong>
              <span>
                {t(
                  "ACCOUNT.FORM.ERRORS.account_already_validate_or_missing_hash"
                )}
              </span>
              <span>{"ACCOUNT.FORM.ERRORS.still_error"}</span>
              <Link to="/support">{t("GLOBAL.ACTIONS.contact_support")}</Link>
            </div>
          )}
        </div>
      </div>
      <div
        className="presentation"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
    </div>
  );
}

export default Validate;
