/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import COLORS from "Utils/colors";

import Alert from "@mui/material/Alert";

import config from "Config";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUser, setUser, checkToken } from "Redux/Reducers/User/UserSlice";
import { setProject } from "Redux/Reducers/Project/ProjectSlice";

import Editor from "Components/Parts/Editor";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import ProjectApi from "Api/Models/Project";

import { defaultProject } from "Types/Project";
import { setError } from "Redux/Reducers/App/AppSlice";

import logo from "Assets/Images/logo/logo.png";

const projectApi = new ProjectApi();

function Wizard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const [step, setStep] = React.useState(1);
  const [currentUser, setCurrentUser] = React.useState(user);
  const [currentProject, setCurrentProject] = React.useState(defaultProject);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [animated, setAnimated] = React.useState(false);
  const [postLoading, setPostLoading] = React.useState(false);

  const finishWizard = () => {
    setPostLoading(true);
    if (!currentProject?.name) {
      console.log("error");
    } else {
      const now = new Date();
      now.setHours(now.getHours() + 1);

      const userToSave = structuredClone(currentUser);
      userToSave.wizard_done = true;
      currentProject.start_date = now
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      if (userToSave.id) {
        currentProject.id_user = userToSave.id;
      }

      currentProject.attr = { is_new: true };

      projectApi.post(currentProject).then(
        (result: any) => {
          if (result.id) {
            dispatch(setUser(userToSave));
            dispatch(setProject(currentProject));
            setTimeout(() => {
              window.location.href = "/project/" + result.uuid;
            }, 200);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const setAdress = (address: any) => {
    const projectWithAdress = { ...currentProject };

    if (address?.terms[0]) {
      if (address?.terms.length > 2) {
        projectWithAdress.address =
          address?.terms[0].value + ", " + address?.terms[1].value;
        if (address?.terms.length > 3) {
          projectWithAdress.zip = address?.terms[2].value;
        }
      } else {
        projectWithAdress.address = address?.terms[0].value;
      }

      projectWithAdress.city = address?.terms[address?.terms.length - 1]?.value;
    } else {
      projectWithAdress.address = address.description;
    }
    setCurrentProject(projectWithAdress);
  };

  const nextStep = () => {
    switch (step) {
      case 1:
        //setAnimated(false);
        setStep(2);
        setTimeout(() => {
          const input_to_focus = document.getElementById("project_name");
          input_to_focus?.focus();
        }, 300);

        break;
      case 2:
        if (currentProject.name) {
          //setAnimated(false);
          setTimeout(() => {
            const input_to_focus: any = document.querySelector(
              "#google_autocomplete input"
            );
            input_to_focus?.focus();
          }, 300);
          setStep(3);
        } else {
          setError(true);
        }
        break;
      case 3:
        //setAnimated(false);
        setTimeout(() => {
          const input_to_focus = document.getElementById("input_amount");
          input_to_focus?.focus();
        }, 300);
        setStep(4);

        break;
      default:
        //setAnimated(false);
        setStep(step + 1);
        break;
    }
    setTimeout(() => setError(false), 3000);
  };

  React.useEffect(() => {
    dispatch(checkToken({}));
    setLoading(false);
  }, [dispatch]);

  React.useEffect(() => {
    if (!loading) {
      if (!user.logged) {
        navigate("/login");
      } else {
        setCurrentUser(user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    setTimeout(() => {
      setAnimated(true);
    }, 1000);
  }, [step]);

  return (
    <div className="wizard">
      <div className="content">
        <div className="sayto">
          <img
            src={logo}
            alt="Sayto"
            className={animated ? "animate logo" : "logo"}
          />
        </div>
        <div className="steps">
          <div className={step === 1 ? "step step1 active" : "step step1"}>
            <div
              className={
                animated ? "animate welcome_message" : "welcome_message"
              }
            >
              {user.firstname && (
                <span>
                  {t("WIZARD.LABELS.hello")} {user.firstname}
                </span>
              )}
              <span>{t("WIZARD.LABELS.welcome")}</span>
            </div>

            {!loading && (
              <div
                className={
                  animated ? "animate animation_element" : "animation_element"
                }
              >
                {user.firstname && (
                  <div className="form">
                    <span>{t("WIZARD.LABELS.create_project_title")}</span>
                    <Button variant="contained" onClick={nextStep}>
                      {t("WIZARD.LABELS.create_project")}
                    </Button>
                  </div>
                )}
                {!user.firstname && (
                  <div className="form">
                    <span>{t("WIZARD.LABELS.question_name")}</span>
                    <div className="row">
                      <div className="col">
                        <input
                          placeholder="Votre prénom"
                          onChange={(element) => {
                            setCurrentUser({
                              ...currentUser,
                              firstname: element.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col">
                        <input
                          placeholder="Votre nom"
                          onChange={(element) => {
                            setCurrentUser({
                              ...currentUser,
                              name: element.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <Button variant="contained" onClick={nextStep}>
                      {t("WIZARD.LABELS.create_project")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={step === 2 ? "step step2 active" : "step step2"}>
            <div
              className={
                animated ? "animate animation_element" : "animation_element"
              }
            >
              <strong className="question">
                {t("WIZARD.LABELS.question_project")}
              </strong>

              <input
                className="project_name"
                id="project_name"
                value={currentProject.name}
                placeholder="Ex: construction d'une annexe au premier étage"
                onChange={(element: any) => {
                  setCurrentProject({
                    ...currentProject,
                    name: element.target.value,
                  });
                }}
              />

              <div className="description">
                <span>{t("WIZARD.LABELS.add_description")}</span>
                <br />
                <span>{t("WIZARD.LABELS.example_description")}</span>
              </div>

              <Editor
                placeholder="Décrivez de votre projet"
                content={currentProject.description}
                onEdit={(value: string) =>
                  setCurrentProject({ ...currentProject, description: value })
                }
              />
            </div>
          </div>
          <div className={step === 3 ? "step step3 active" : "step step2"}>
            <strong className="question">
              {t("WIZARD.LABELS.question_place")}
            </strong>
            <div className="google_autocomplete" id="google_autocomplete">
              <GooglePlacesAutocomplete
                apiKey={config.googleApi}
                apiOptions={{
                  language: "fr",
                  region: "be",
                }}
                selectProps={{
                  theme: (theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e238324f",
                      primary50: "#e2383287",
                      primary: COLORS[3],
                    },
                  }),
                  placeholder: "Ex: avenue des Champs Elysée 4, Paris",
                  onChange: (addr: any) => {
                    setAdress(addr.value);
                  },
                }}
              />
            </div>
          </div>
          <div className={step === 4 ? "step step4 active" : "step step2"}>
            <strong className="question">
              {t("WIZARD.LABELS.question_budget")}
            </strong>

            <div className="budget">
              <input
                id="input_amount"
                type="number"
                value={currentProject.budget ? currentProject.budget : ""}
                placeholder="0"
                onChange={(element: any) => {
                  setCurrentProject({
                    ...currentProject,
                    budget: element.target.value,
                  });
                }}
              />
              <span>€</span>
            </div>
          </div>
        </div>

        <div className={step > 1 ? "actions row active" : "actions row"}>
          <div className="col col3">
            <Button
              disabled={step < 3}
              variant="outlined"
              onClick={() => setStep(step - 1)}
            >
              {t("GLOBAL.ACTIONS.back")}
            </Button>
          </div>
          <div className="col col6">
            {error && (
              <Alert severity="error">{t("ERRORS.missing_fields")}</Alert>
            )}
          </div>
          <div className="col col3">
            {step < 4 ? (
              <Button variant="contained" onClick={nextStep}>
                {t("GLOBAL.ACTIONS.continue")}
              </Button>
            ) : (
              <LoadingButton
                loading={postLoading}
                onClick={finishWizard}
                variant="contained"
              >
                {t("GLOBAL.ACTIONS.done")}
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wizard;
