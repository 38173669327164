/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";

function Transactions() {
  const { t } = useTranslation();

  return <span className="emptyMessage">{t("ACCOUNT.transactions")}</span>;
}

export default Transactions;
