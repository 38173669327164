/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Grid, Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

/** Components */

/** API */
import ProjectApi from "Api/Models/Project";

import { BudgetType } from "Types/Project";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";
import {
  setFilters
} from "Redux/Reducers/Project/EventsSlice";

import { amount_format} from 'Utils/Locale';


function Budget() {
  const budgetType: BudgetType = {
    expenses: [],
    amount_by_date: [],
    total_expenses: 0,
    total_estimates: 0,
    budget: 0,
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const project = useAppSelector(getProject);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState(budgetType);

  const filterByType = (type: string) => () => {
      dispatch(setFilters({filetype: type}));
      navigate("/project/"+project.id);
  };



  useEffect(() => {
    const projectApi = new ProjectApi();
    let eventList: any = [];

    projectApi.getBudget(project.id).then(
      (result: any) => {
        if (result) {
          setBudget(result.data);
          setLoading(false);
        }
      },
      (error: string) => {
        console.log(error);
      }
    );
  }, [project]);

  return (
    <div className="tabContent files">
      <h3>{t("BUDGET.TITLES.main")}</h3>
      {loading ? (
        <span className="emptyMessage">{t("LOADING")}</span>
      ) : (
        <div className="scrollable_container">
              <Grid container>
                <Grid item md={4}>
                  <Card sx={{ margin: 1 }}>
                  <CardActionArea >
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div">
                          {t("BUDGET.TITLES.intial_budget")}
                        </Typography>
                        <Typography variant="h5">
                          {amount_format(project.datas.budget,"€")}
                        </Typography>
                        <Box sx={{mx: 0.5}}>&nbsp;</Box>
                      </CardContent>
                      </CardActionArea>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card sx={{ margin: 1 }}>
                    <CardActionArea onClick={ filterByType('estimate')}>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div">
                          {t("BUDGET.TITLES.total_estimates")}
                        </Typography>
                        <Typography variant="h5">
                          { amount_format(budget?.total_estimates,"€") }
                          
                          
                        </Typography>
                        <Box
                        sx={{
                          color: 'success.main',
                          display: 'inline',
                          fontWeight: 'bold',
                          mx: 0.5,
                          fontSize: 14,
                        }}
                          >
                          { ((budget.total_estimates / project.datas.budget) * 100).toFixed(2) } %
                        </Box>
                        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>
                         {t("BUDGET.of_the_initial_budget")}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  <Card sx={{ margin: 1 }}>
                    <CardActionArea onClick={ filterByType('invoice')}>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div">
                          {t("BUDGET.TITLES.total_expenses")}
                        </Typography>
                        <Typography variant="h5">
                          {amount_format(budget.total_expenses,"€")}
                        </Typography>
                        <Box
                        sx={{
                          color: 'warning.main',
                          display: 'inline',
                          fontWeight: 'bold',
                          mx: 0.5,
                          fontSize: 14,
                        }}
                          >
                          { ((budget.total_expenses / project.datas.budget) * 100).toFixed(2) } %
                        </Box>
                        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>
                         {t("BUDGET.of_the_initial_budget")}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
          </Grid>

         
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <h2>{t("BUDGET.TITLES.chart_by_date")}</h2>
                  <BarChart 
                    width={600}
                    height={400}
                    data={budget.amount_by_date}
                    margin={{
                      top: 30,
                      right: 5,
                      left: 5,
                      bottom: 30,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="expenses" fill="#EA5040" />
                    <Bar dataKey="estimates" fill="#82ca9d" />
                  </BarChart>


                </Grid>
                
            </Grid>
        </div>
      )}
    </div>
  );
}

export default Budget;
