import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
	getNofications,
	deleteNotification,
} from "Redux/Reducers/App/AppSlice";

const AppNotifications = (props: {
	position?: { vertical: string; horizontal: string };
}) => {
	const { position } = props;

	const [open, setOpen] = useState(false);
	const notifications = useAppSelector(getNofications);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	/*
  const vertical = position?.vertical ? position.vertical : "bottom";
  const horizontal = position?.horizontal ? position.horizontal : "right";
*/

	const vertical = "bottom";
	const horizontal = "left";

	const handleClose = (index: number) => {};

	useEffect(() => {
		console.log("notifications", notifications);
	}, [notifications]);

	return (
		<>
			{notifications.map((notification: any, index: number) => {

				console.log(notification);
				return (
					<Snackbar
						key={index}
						open={true}
						className="notification"
						autoHideDuration={4000}
						onClose={() => {
							dispatch(deleteNotification(index));
						}}
						anchorOrigin={{ vertical, horizontal }}
						style={{ bottom: (notifications.length - index) * 60 + "px" }}
					>
						<Alert
							variant="filled"
							severity={notification?.type ? notification.type : "error"}
							onClose={() => {
								dispatch(deleteNotification(index));
							}}
						>
							{notification.message
								? notification.message
								: t("ERRORS.default")}
						</Alert>
					</Snackbar>
				);
			})}
		</>
	);
};

export default AppNotifications;
