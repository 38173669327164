import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Form components */
import TextField from "Components/Parts/TextField";
import Button from "@mui/material/Button";

/** Icons */
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

/** Redux */

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
  getProject,
  setCoordinates,
} from "Redux/Reducers/Project/ProjectSlice";

/** Editor */
import Editor from "Components/Parts/Editor";

/** Position */
import { getPosition } from "Utils/Geocoder";

function EditProject(props: {
  project: any;
  open: boolean;
  onClose: Function;
  onSave: Function;
}) {
  const { open, onClose, onSave, project } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [projectDatas, setProjectDatas] = useState(project);
  const [loading, setLoading] = useState(false);

  const handleEdit = () => {
    setLoading(true);
    onSave(projectDatas);
    handleEditAddress();
    onClose();
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const handleEditAddress = () => {
    const address =
      projectDatas.datas.address +
      " " +
      projectDatas.datas.zip +
      " " +
      projectDatas.datas.city;

    getPosition(address).then(
      (result) => {
        if (result) {
          dispatch(setCoordinates(result));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setProjectDatas(project);
  }, [project]);

  return (
    <div className={open ? "modal add_project open" : "modal add_project"}>
      <div className="overlay" onClick={handleClose}></div>
      <div className="content">
        <div className="title">
          <button className="closeModal" onClick={handleClose}>
            <CloseIcon />
          </button>
          <span>Modifier mon </span>
          <strong>Projet</strong>
        </div>
        <div className="separator"></div>
        <div className="row">
          <div className="col md8">
            <TextField
              placeholder={"Nom de votre projet"}
              value={projectDatas?.datas?.name}
              onUpdate={(element: string) => {
                const newProject = structuredClone(projectDatas);
                newProject.datas.name = element;
                setProjectDatas(newProject);
              }}
            />
          </div>
          <div className="col md4">
            <TextField
              placeholder={"Budget"}
              type="numeric"
              icon="euro"
              value={projectDatas?.datas?.budget}
              onUpdate={(element: string) => {
                const newProject = structuredClone(projectDatas);
                newProject.datas.budget = parseFloat(element)
                  ? parseFloat(element)
                  : 0;
                setProjectDatas(newProject);
              }}
            />
          </div>
        </div>

        <div className="sep row">
          <div className="col">
            <TextField
              placeholder={"Addresse du projet"}
              value={projectDatas?.datas?.address}
              onUpdate={(element: string) => {
                const newProject = structuredClone(projectDatas);
                newProject.datas.address = element;
                setProjectDatas(newProject);
              }}
            />
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <TextField
                  placeholder={"Code postal"}
                  value={projectDatas?.datas?.zip}
                  onUpdate={(element: string) => {
                    const newProject = structuredClone(projectDatas);
                    newProject.datas.zip = element;
                    setProjectDatas(newProject);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  placeholder={"Ville"}
                  value={projectDatas?.datas?.city}
                  onUpdate={(element: string) => {
                    const newProject = structuredClone(projectDatas);
                    newProject.datas.city = element;
                    setProjectDatas(newProject);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <Button
          className="action_button"
          variant="contained"
          onClick={handleEdit}
        >
          {loading ? <CircularProgress /> : <>{t("GLOBAL.ACTIONS.save")}</>}
        </Button>
      </div>
    </div>
  );
}

export default EditProject;
