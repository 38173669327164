
export const hasSomeParentTheClass: any = (element: any, classNames: []) => {
    if (element.classList) {
        if (classNames.some((className: string) => element.classList.contains(className))) {
            return true
        }
    } else {
        return false;
    }


    return element.parentNode && hasSomeParentTheClass(element.parentNode, classNames);
}

export const getProfessionIcon = (profession: string) => {
    let path = '';

    try {
        path = require(`Assets/Images/roles/${profession}.png`);
    } catch (e) {
        path = require(`Assets/Images/roles/1.png`);
    }
    return path;
}