export interface EventState {
  list: any;
  filters: any;
  isOpen: boolean;
  modalAction: 'edit' | 'delete' | 'add' | 'waiting';
  amounts: any;
  current: EventType,
}
export type EventType = {
  comments: number;
  id: number;
  title?: string;
  type: string;
  content: string;
  id_user: number;
  id_project: number;
  creation_date: any;
  event_start: any;
  event_end: any;
  attr: any;
  size: number;
};

export type EventCalendarType = {
  backgroundColor?: string;
  extendedProps: any;
  id: string;
  title: string;
  date: string;
}

export type CommentType = {
  id: number;
  id_user: number;
  id_event: number;
  comment: string;
};

export type SoundRecordType = {
  name: string;
  path: string;
  base64: string;
  time: string;
  status: string;
  position: number;
  visible: boolean;
};

export type ImageRecordType = {
  name: string;
  uri: string;
  base64: string;
  visible: boolean;
};

export type EventPropsType = {
  events: EventType[],
  onOpen: Function
}

export const defaultEvent: EventType = {
  id: 0,
  id_project: 0,
  type: "",
  content: "",
  id_user: 0,
  event_start: "",
  creation_date: "",
  event_end: "",
  attr: {},
  comments: 0,
  size: 0,
};

export const initialState: EventState = {
  list: [],
  filters: {},
  isOpen: false,
  modalAction:'waiting',
  amounts: { files: 0, events: 0, tasks: 0 },
  current: defaultEvent,
};