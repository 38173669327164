import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Config from "./Config";

import App from "./App";

import "./Styles/App.css";
import "./Utils/i18n";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./Styles/Theme";

const logo = require("Assets/Images/logo/logo.png");

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<Provider store={store}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Sayto</title>
			<meta name="robots" content="noindex,nofollow" />
		</Helmet>
		<GoogleOAuthProvider clientId={Config.googleClientId}>
			<ThemeProvider theme={theme}>
				<div className="deviceView">
					<img className="logo" src={logo} alt="Sayto" />
				</div>
				<div className="desktopView">
					<App />
				</div>
			</ThemeProvider>
		</GoogleOAuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/*
function sendToAnalytics({ id, name, value }) {
  ga('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}


reportWebVitals(sendToAnalytics);

*/
