/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import multiMonthPlugin from "@fullcalendar/multimonth";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

/** Components */
import Details from "Components/Pages/Project/Events/Details";

/** Redux */
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  getProject,
  getContractors,
} from "Redux/Reducers/Project/ProjectSlice";
import {
  getEvents,
  setCurrentEvent,
  setFilters,
  getFilters,
} from "Redux/Reducers/Project/EventsSlice";
import COLORS from "Utils/colors";

function Calendar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const filters = useAppSelector(getFilters);
  const project = useAppSelector(getProject);
  const events = useAppSelector(getEvents);
  const contractors = useAppSelector(getContractors);

  const [eventsForCalendar, setEventsForCalendar] = useState([]);
  const calendarRef = React.createRef<FullCalendar>();

  useEffect(() => {
    const api = calendarRef?.current?.getApi();

    const activeStart: Date | undefined = api?.view.activeStart;
    const activeEnd: Date | undefined = api?.view.activeEnd;

    dispatch(
      setFilters({
        id_project: project.id,
        from: activeStart?.toISOString(),
        to: activeEnd?.toISOString(),
        limit: 30000,
      })
    );
  }, [project.id, dispatch]);

  useEffect(() => {
    const formattedEvents: any = [];

    // eslint-disable-next-line array-callback-return
    events.map((item: any, index: number) => {
      const attributes =
        typeof item.attr === "string" ? JSON.parse(item.attr) : item.attr;

      let isAllDay = true;

      if (!item.event_end || item.event_end === item.event_start) {
        isAllDay = false;
      }

      const firstContractorId =
        item?.attr?.contractors && item?.attr?.contractors.length > 0
          ? item.attr.contractors[0]
          : null;

      const firstContractor =
        contractors && Array.isArray(contractors)
          ? contractors.find((contractor: any) => {
              return contractor.id === firstContractorId;
            })
          : null;

      let phaseColor =
        firstContractor && firstContractor?.color
          ? firstContractor?.color
          : COLORS[2];

      // Full calendar is end date exclusive, need to add 1 day more
      const endDate = new Date(item.event_end);
      const startDate = new Date(item.event_start);
      if (endDate > startDate) {
        endDate.setDate(endDate.getDate() + 1);
      }

      const eventDatas = {
        id: item.id,
        backgroundColor: phaseColor,
        title: item.title,
        start: item.event_start,
        end: endDate,
        allDay: isAllDay,
        extendedProps: item,
      };

      if (item.type !== "date" && eventDatas.end) {
        let start = endDate;
        eventDatas.start = start.setDate(start.getDate() - 1);
      }

      formattedEvents.push(eventDatas);
    });

    setEventsForCalendar(formattedEvents);
  }, [events]);

  const reloadFromCalendar = (view: string) => {
    const api = calendarRef?.current?.getApi();
    api?.changeView(view);

    const activeStart: Date | undefined = api?.view.activeStart;
    const activeEnd: Date | undefined = api?.view.activeEnd;
    dispatch(
      setFilters({
        ...filters,
        from: activeStart?.toISOString(),
        to: activeEnd?.toISOString(),
      })
    );
  };

  const placeholders = {
    year: t("GLOBAL.TIME.year"),
    months_3: t("GLOBAL.TIME.months_3"),
    month: t("GLOBAL.TIME.month"),
    week: t("GLOBAL.TIME.week"),
  };
  return (
    <div>
      <Details />
      <div className="calendar">
        <FullCalendar
          ref={calendarRef}
          locale={frLocale}
          plugins={[dayGridPlugin, multiMonthPlugin, interactionPlugin]}
          initialView="multiMonthYear"
          views={{
            multiMonthCustom: {
              type: "multiMonth",
              duration: { months: 3 },
            },
            multiYearCustom: {
              type: "multiMonth",
              duration: { months: 12 },
            },
          }}
          events={eventsForCalendar}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "multiYearCustom,multiMonthCustom,monthView,weekView",
          }}
          customButtons={{
            multiYearCustom: {
              text: placeholders.year,
              click: () => {
                reloadFromCalendar("multiMonthYear");
              },
            },
            multiMonthCustom: {
              text: placeholders.months_3,
              click: () => {
                reloadFromCalendar("multiMonthCustom");
              },
            },
            monthView: {
              text: placeholders.month,
              click: () => {
                reloadFromCalendar("dayGridMonth");
              },
            },
            weekView: {
              text: placeholders.week,
              click: () => {
                reloadFromCalendar("dayGridWeek");
              },
            },
          }}
          dayMaxEvents={3}
          eventClick={(element) => {
            dispatch(setCurrentEvent(element.event.extendedProps));
          }}
          dateClick={(date: any) => {
            console.log(date);
          }}
        />
      </div>
    </div>
  );
}

export default Calendar;
