import {
	getProject,
	postProject,
	deleteProject,
} from "Api/Controllers/ProjectController";

import {
	getAmounts,
	getEvents,
	putEvent,
	deleteEvent,
} from "Api/Controllers/EventController";

import { getProfessions } from "Api/Controllers/CategoriesController";
import { search } from "Api/Controllers/ContractorController";

import { getUser, putUser, postSettings } from "Api/Controllers/UserController";

const AppMiddleware = (store: any) => (next: Function) => (action: any) => {
	const returnAction = { ...action };
	const globalState = store.getState();

	switch (action.type) {
		/** Pojects */
		case "project/loadProject":
			if (action.payload?.id) {
				getProject(action.payload?.id).then(
					(returnDatas: any) => {
						if (returnDatas?.status === "success") {
							next({
								type: "project/setProject",
								payload: returnDatas?.data.project,
							});
							next({
								type: "project/setContractors",
								payload: returnDatas?.data.contractors,
							});
							next(returnAction);
						}
					},
					(error: any) => {
						next({
							type: "app/setError",
							payload: {
								type: error?.type,
								message: error?.message,
							},
						});
						next({
							type: "project/setProject",
							payload: {
								id: -1,
							},
						});
					}
				);
			}
			break;
		case "project/setContractors":
			const projectWithContractors = {
				...globalState.project.datas,
				id: globalState.project.id,
			};

			projectWithContractors.attr = {
				contractors: action.payload,
				is_new: false,
			};

			postProject(projectWithContractors).then(
				(result: any) => {
					if (result.status === "success") {
						next(action);
					}
				},
				(error: any) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;
		case "project/setProject":
			const projectToSave = { ...action.payload.datas };
			if (globalState.project.id) {
				projectToSave.id = globalState.project.id;
			}
			projectToSave.attr = {
				...action.payload.attributes,
				is_new: false,
			};

			postProject(projectToSave).then(
				(result: any) => {
					if (result.status === "success") {
						next(action);
					}
				},
				(error: any) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;
		case "project/deleteProject":
			deleteProject(globalState.project.id).then(
				(result: any) => {
					if (result.status === "success") {
						next(action);
					}
				},
				(error: any) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;

		/* Events */
		case "event/loadEvents":
			getAmounts(globalState.project.id).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						next({
							type: "event/setAmounts",
							payload: returnDatas?.data.amount,
						});
					}
				},
				(error) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;
		case "event/setFilters":
			const filters = { ...action.payload, id_project: globalState.project.id };
			next(returnAction);

			/*
      getEvents(filters).then(
        (returnDatas: any) => {
          if (returnDatas?.status === "success") {
            next({
              type: "event/setEvents",
              payload: returnDatas?.data.events,
            });
          }
        },
        (error) => {
          next({
            type: "app/setError",
            payload: {
              type: error?.type,
              message: error?.message,
            },
          });
        }
      );
      */

			break;
		case "event/refreshEvents":
			const refreshFilters = {
				...globalState.event.filters,
				id_project: globalState.project.id,
			};
			getEvents(refreshFilters).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						next({
							type: "event/setEvents",
							payload: returnDatas?.data.events,
						});
						next(returnAction);
					}
				},
				(error) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;

		case "event/saveEvent":
			const currentEvent = structuredClone(globalState.event.current);
			currentEvent.size = parseInt(currentEvent.size);
			putEvent(currentEvent).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						next(returnAction);
						next({
							type: "app/setNotification",
							payload: {
								type: "success",
								message: "L'évènement a bien été enregistré.",
							},
						});
					}
				},
				(error) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);

			break;
		case "event/deleteEvent":
			if (globalState.event.current.id) {
				deleteEvent(globalState.event.current.id).then(
					(result: any) => {
						if (result.status === "success") {
							next(action);
							next({ type: "event/refreshEvents", payload: {} });
						}
					},
					(error: any) => {
						next({
							type: "app/setError",
							payload: {
								type: error?.type,
								message: error?.message,
							},
						});
					}
				);
			}

			break;

		/** Account */
		case "user/checkToken":
			getUser().then(
				(result: any) => {
					if (result.status === "success") {
						next({
							type: "user/setUser",
							payload: result.data,
						});
						next(action);
					}
				},
				(error: any) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});

					next({
						type: "user/disconnect",
						payload: {},
					});
				}
			);

			break;

		case "user/setUser":
			if (action.payload?.id) {
				putUser(action.payload).then(
					(returnDatas: any) => {
						if (returnDatas?.status === "success") {
							next({
								type: "user/setUser",
								payload: returnDatas?.data,
							});
						}
					},
					(error) => {
						next({
							type: "app/setError",
							payload: {
								type: error?.type,
								message: error?.message,
							},
						});
					}
				);
			} else {
				next({
					type: "app/setError",
					payload: {
						type: "user_put_error",
						message: "",
					},
				});
			}

			break;

		case "user/setSettings":
			postSettings(action.payload).then(
				(returnDatas: any) => {
					next(returnAction);
				},
				(error) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;

		/** Categories */

		case "category/loadCategories":
			getProfessions().then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						next({
							type: "category/setProfessions",
							payload: returnDatas?.data,
						});
						next(returnAction);
					}
				},
				(error) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;
		case "category/loadContractors":
			search("").then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						next({
							type: "category/setContractors",
							payload: returnDatas?.data,
						});
						next(returnAction);
					}
				},
				(error) => {
					next({
						type: "app/setError",
						payload: {
							type: error?.type,
							message: error?.message,
						},
					});
				}
			);
			break;
	
		default:
			next(action);
			break;
	}
};

export default AppMiddleware;
