import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

const MySubscription = () => {
  const { t } = useTranslation();

  return (
    <Button
      className=""
      variant="text"
      onClick={() => { window.location.href = "/account/subscription"; }}
    >
      {t("ACCOUNT.ACTIONS.mysubscription")}
    </Button>
  );
};

export default MySubscription;
