/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Compressor from "compressorjs";

/** Types */
import { EventType } from "Types/Event";

const filesUpload: any = {
	file: ".xls,.xlsx,.csv,.doc,.docx,.page,.pdf",
	picture: "image/png, image/gif, image/jpeg,image/HEIC, image/*",
	video: "video/mp4,video/x-m4v,video/*",
	sound: "*",
};

function AddFile(props: {
	type: string;
	filesSource: any;
	onChange: Function;
}) {
	const { type, filesSource, onChange } = props;
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [files, setFiles] = useState(filesSource);
	const { t } = useTranslation();

	const handleUploadClick = () => {
		inputRef.current?.click();
	};
	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}

		const newFiles: any = [];

		Array.from(e.target.files).forEach(async (file) => {
			let fileToConvert = file;

			if (type === "picture") {
				new Compressor(file, {
					quality: 0.7,
					async success(result) {
						const newFileEvent = await processFile(result);
						newFiles.push(newFileEvent);
						setFiles(newFiles);
						onChange(newFiles);
					},
					async error(err) {
						console.log("error", err);
					},
				});
			} else {
				const newFileEvent = await processFile(fileToConvert);
				newFiles.push(newFileEvent);
				setFiles(newFiles);
				onChange(newFiles);
			}
		});
	};

	const processFile = (fileToConvert: any) => {
		return new Promise((resolve, reject) => {
			const newFileEvent = {
				name: fileToConvert.name,
				size: fileToConvert.size,
				uri: fileToConvert.name,
				type: fileToConvert.type,
				base64: "",
			};
			const reader = new FileReader();
			reader.readAsDataURL(fileToConvert);

			reader.onload = function () {
				let base64 = reader?.result?.toString() || "";
				base64 = base64.split("base64,")[1];
				newFileEvent.base64 = base64;

				if (type === "picture") {
					const img = new Image();
					let formattedBase64 = reader.result as string;
					img.src = formattedBase64;
					img.onload = function () {
						// Définissez la taille souhaitée de l'image redimensionnée
						const MAX_WIDTH = 1200;
						const MAX_HEIGHT = 1200;
						let width = img.width;
						let height = img.height;
						if (width > height) {
							if (width > MAX_WIDTH) {
								height *= MAX_WIDTH / width;
								width = MAX_WIDTH;
							}
						} else {
							if (height > MAX_HEIGHT) {
								width *= MAX_HEIGHT / height;
								height = MAX_HEIGHT;
							}
						}

						const canvas = document.createElement("canvas");
						canvas.width = width;
						canvas.height = height;
						const ctx = canvas.getContext("2d");
						ctx?.drawImage(img, 0, 0, width, height);

						const dataUrl = canvas.toDataURL("image/jpeg");
						console.log("with double compression");
						newFileEvent.base64 = dataUrl.split("base64,")[1];
						resolve(newFileEvent);
					};
				}else{
					resolve(newFileEvent);
				}
			};
			reader.onerror = function (error) {
				console.log("Error: ", error);
				reject(error);
			};
		});
	};

	const removeFile = (file: any) => {
		const newFiles = [...files];
		delete newFiles[newFiles.indexOf(file)];
		const cleanFiles = newFiles.filter((file) => file.base64 !== "");
		setFiles(cleanFiles);
		onChange(cleanFiles);
	};

	useEffect(() => {
		setFiles(filesSource);
	}, [filesSource]);

	return (
		<>
			<Button
				className="add_file_button"
				variant="outlined"
				onClick={handleUploadClick}
			>
				Ajouter un ou plusieurs fichiers
			</Button>

			<input
				type="file"
				ref={inputRef}
				onChange={handleFileChange}
				style={{ display: "none" }}
				accept={filesUpload[type]}
				multiple
			/>

			<ul className="file_list">
				{files.length ? (
					<>
						{files.map((file: any, index: number) => {
							return (
								<li key={index}>
									{file.name}
									<button onClick={() => removeFile(file)}>x</button>
								</li>
							);
						})}
					</>
				) : (
					<span>{t("GLOBAL.EMPTY.file")}</span>
				)}
			</ul>
		</>
	);
}

export default AddFile;
