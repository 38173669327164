export enum Language {
    FR = "fr",
    EN = "en",
    NL = "nl",
}

export const availableLanguages = [
    { value: "fr", label: "Français" },
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
    { value: "de", label: "Deutsch" },
    { value: "it", label: "Italiano" },
    { value: "pt", label: "Português" },
    { value: "nl", label: "Nederlands" },

]