import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import COLORS from "Utils/colors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import Textarea from "@mui/joy/Textarea";

/** Icons */
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

/** APi */

import { postShare } from "Api/Controllers/EventController";
/** Redux */

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getContractors } from "Redux/Reducers/Project/ProjectSlice";
import { getCurrentEvent } from "Redux/Reducers/Project/EventsSlice";
import { setError } from "Redux/Reducers/App/AppSlice";

const ShareEvent = (props: {
  open: boolean;
  onClose: Function;
  onReject?: Function;
}) => {
  const { open, onClose, onReject } = props;

  const contributors = useAppSelector(getContractors);
  const event = useAppSelector(getCurrentEvent);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [formattedContributors, setFormattedContributors] = useState<any>([]);
  const [selectedContributor, setSelectedContributor] = useState<any>(null);
  const [message, setMessage] = useState("");

  /** Functions */

  const handleClose = () => {
    setSuccess(false);
    setMessage("");
    setSelectedContributor(null);
    onClose();
    setLoading(false);
  };

  const handleConfirm = () => {
    setLoading(true);
    postShare(event.id, selectedContributor.value, message).then(
      (result: any) => {
        if (result.status === "success") {
          setSuccess(true);
          setLoading(false);
          setTimeout(() => {
            handleClose();
          }, 3000);
        } else {
          setLoading(false);
        }
      },
      (error: any) => {
        setLoading(false);
        dispatch(setError(error));
      }
    );
  };

  const handleReject = () => {
    if (onReject) {
      onReject();
    }

    onClose();
  };

  /** Hooks */

  useEffect(() => {
    if (
      contributors &&
      contributors.length > 0 &&
      Array.isArray(contributors)
    ) {
      const formattedContributors = contributors.map((contributor: any) => {
        return {
          value: contributor.email,
          label: contributor.company
            ? contributor.company
            : contributor.lastname + " " + contributor.firstname,
        };
      });

      setFormattedContributors(formattedContributors);
    }
  }, [contributors]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("EVENT.ACTIONS.share_with_contractor")}
      </DialogTitle>
      <DialogContent className="share_content">
        <DialogContentText id="alert-dialog-description">
          {success ? (
            <p className="success">
              <ThumbUpIcon />
              <span>L'évènement a été envoyé!</span>
            </p>
          ) : (
            <>
              <Select
                classNamePrefix="react-select"
                aria-label="Selectionner un intervenant"
                placeholder="Selectionner un intervenant"
                options={formattedContributors}
                name="Contributors"
                isMulti={false}
                isSearchable={true}
                isClearable={false}
                value={selectedContributor}
                onChange={(selected) => {
                  setSelectedContributor(selected);
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#e238324f",
                    primary50: "#e2383287",
                    primary: COLORS[0],
                  },
                })}
              />

              <textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Ajouter un message"
              ></textarea>

              {event.id &&
                selectedContributor &&
                selectedContributor.value !== undefined && (
                  <div>
                    <span>
                      Ce message sera envoyé à l'adresse email :{" "}
                      {selectedContributor.value}
                    </span>
                  </div>
                )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject}>{t("GLOBAL.ACTIONS.cancel")}</Button>

        <LoadingButton
          size="small"
          onClick={() => {
            handleConfirm();
          }}
          endIcon={<SendIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          disabled={
            !event.id ||
            !selectedContributor ||
            selectedContributor.value === undefined
          }
        >
          <span>Partager</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShareEvent;
