/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import Button from "@mui/material/Button";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";
import { setNotification } from "Redux/Reducers/App/AppSlice";
import {
	setFilters,
	getFilters,
	setCurrentEvent,
	getCurrentEvent,
	getAmounts,
	loadEvents,
	setIsOpen,
	getModalAction,
	setModalAction,
} from "Redux/Reducers/Project/EventsSlice";

import { setPictures, setVideo } from "Redux/Reducers/Lightbox/LightboxSlice";


/** Components */
import Details from "Components/Pages/Project/Events/Details";

//import List from "Components/Pages/Project/Views/ActivitiesList";
import List from "Components/Pages/Project/Views/ActivitiesFlux";
import Grid from "Components/Pages/Project/Views/ActivitiesGrid";
import Filters from "Components/Pages/Project/Views/ActivitiesFilters";

/** utils */
import { setItem, getItem } from "Utils/Storage";

/** Images */
import gridButton from "Assets/Images/clear/filters/grid.png";
import listButton from "Assets/Images/clear/filters/list.png";

/** Types */
import { EventType,defaultEvent } from "Types/Event";

/** Api */

import { getEvents } from "Api/Controllers/EventController";

function Activities() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const project = useAppSelector(getProject);
	const filters = useAppSelector(getFilters);
	const amounts = useAppSelector(getAmounts);
	const modalAction = useAppSelector(getModalAction);
	const currentEvent = useAppSelector(getCurrentEvent);

	const defaultFilters: any = { ...filters };

	const [filtersLoading, setFiltersLoading] = useState(true);
	const [ActiveFilters, setActiveFilters] = useState(defaultFilters);
	const [mode, setMode] = useState(getItem("project_mode"));
	const [fromAnotherPage, setFromAnotherPage] = useState(true);
	const [loading, setLoading] = useState(false);

	const [events, setEvents] = useState([] as any);

	/** FUNCTIONS */

	const openDetails = (event: EventType) => {
		dispatch(setCurrentEvent(event));
		dispatch(setIsOpen(true));
	};

	const switchMode = (modeEvent: string) => {
		setMode(modeEvent);
	};

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const newFilters = structuredClone(filters);

		if (scrollTop <= 5) {
			newFilters.page = newFilters.page ? newFilters.page - 1 : 1;
			dispatch(setFilters(newFilters));
			increaseEvents(false);
		}
		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;
			const currentPage = filters.page ? filters.page : 1;
			increaseEvents(true);
		}
	};

	/** reload Events */

	const reloadEvents = (currentFilters: any) => {
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;
		activeFilters.page = 1;
		activeFilters.limit = 20;
		dispatch(setFilters(activeFilters));
		getEvents(activeFilters).then((returnDatas: any) => {
			if (returnDatas?.status === "success") {
				setEvents(returnDatas?.data.events);
			}
		});
	};

	/** increase Events */

	const increaseEvents = (add: boolean) => {
		const activeFilters = structuredClone(filters);
		if (add) {
			activeFilters.page = activeFilters.page ? activeFilters.page + 1 : 2;
		} else {
			activeFilters.page = activeFilters.page ? activeFilters.page - 1 : 1;
		}
		activeFilters.id_project = project.id;
		activeFilters.limit = 20;
		if (
			activeFilters.page > 1 &&
			(activeFilters.page - 1) * 20 <= amounts.events
		) {
			dispatch(setFilters(activeFilters));
			setLoading(true);
			getEvents(activeFilters).then((returnDatas: any) => {
				if (returnDatas?.status === "success") {
					const newEvents = [...events, ...returnDatas?.data.events];
					setEvents(newEvents);
					setLoading(false);
				}
			});
		}
	};
	/**  */

	/** HOOKS */

	useEffect(() => {
		let filters = { ...ActiveFilters };

		if (fromAnotherPage) {
			filters = { search: "" };
			setFiltersLoading(false);
			if (ActiveFilters.filetype) {
				filters.filetype = ActiveFilters.filetype;
			}
		}
		dispatch(setFilters(filters));
		setActiveFilters(filters);
	}, [dispatch]);

	useEffect(() => {
		filters.search &&
			setActiveFilters({ ...ActiveFilters, search: filters.search });
	}, [filters.search]);

	useEffect(() => {
		setLoading(true);
		dispatch(setFilters(ActiveFilters));
		reloadEvents(ActiveFilters);
	}, [dispatch, ActiveFilters]);

	useEffect(() => {
		const newMode: string = mode ? mode : "list";
		setMode(newMode);
		setItem("project_mode", newMode);
	}, [mode]);

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			modalAction === "add" && reloadEvents(filters);

			const updatingEvents = { ...events };

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				dispatch(setCurrentEvent(defaultEvent));
				dispatch(setPictures([] as any));
				dispatch(setVideo({} as any));
			}

			if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
					dispatch(setCurrentEvent(defaultEvent));
					dispatch(setPictures([] as any));
					dispatch(setVideo({} as any));
				}, 300);
			}

			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	return (
		<div className="flow">
			<div className="filters">
				<h3 className="title"></h3>
				{false && !project.attributes.is_new && (
					<div className="switch">
						<button
							className={mode === "list" ? "switchBtn active" : "switchBtn"}
							onClick={() => {
								switchMode("list");
							}}
						>
							<img src={listButton} alt="List" />
						</button>
						<button
							className={mode === "grid" ? "switchBtn active" : "switchBtn"}
							onClick={() => {
								switchMode("grid");
							}}
						>
							<img src={gridButton} alt="Grid" />
						</button>
					</div>
				)}

				{!project.attributes.is_new && !filtersLoading && (
					<Filters
						filtersParams={ActiveFilters}
						onFilter={(filters: any) => {
							setActiveFilters(filters);
							setFromAnotherPage(false);
						}}
					/>
				)}
			</div>
			<Details />

			<AnimatePresence>
				{events && events.length > 0 ? (
					<div className="scrollhandler" onScroll={handleScrollList}>
						<List events={events} onOpen={openDetails} />
					</div>
				) : (
					<>
						{project.attributes.is_new ? (
							<div className="intro">
								<span>Voici l'écran principal de votre projet!</span>
								<br />
								<span>
									Pour commencer, vous pouvez ajouter un ou plusieurs
									intervenants à votre projet.
								</span>
								<Button
									variant="contained"
									onClick={() =>
										navigate("/project/" + project.id + "/contractors/add")
									}
								>
									+ Ajouter des intervenants
								</Button>
								<br />
								<span>
									Sinon, vous pouvez dès à présent créer votre premier évènement
									via le bouton <AddCircleOutlinedIcon /> en bas à droite
								</span>
							</div>
						) : (
							<span className="emptyProject">{t("PROJECT.empty_filters")}</span>
						)}
					</>
				)}
			</AnimatePresence>
		</div>
	);
}

export default Activities;
