import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { setItem, getItem } from "Utils/Storage";

import { initialState } from "Types/Event";

import { getPosition } from "Utils/Geocoder";

/** Redux */
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { getUserId } from "Redux/Reducers/User/UserSlice";

import {
  loadProject,
  getProject,
  clearProject,
  setLoading,
  setCoordinates,
} from "Redux/Reducers/Project/ProjectSlice";

import { loadEvents, setAmounts } from "Redux/Reducers/Project/EventsSlice";
import { loadCategories } from "Redux/Reducers/App/CategorySlice";
import { setError } from "Redux/Reducers/App/AppSlice";

import ProjectApi from "Api/Models/Project";

function ProjectLoader(params: { onLoad: Function }) {
  const { onLoad } = params;

  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(getUserId);

  const { id } = useParams();
  const [progress, setProgress] = useState(0);

  const project = useAppSelector(getProject);

  const [projectId, setProjectId] = useState(id);

  const getProjectCoords = () => {
    if (project.id > 0) {
      const address =
        project.datas.address +
        " " +
        project.datas.zip +
        " " +
        project.datas.city;

      getPosition(address).then(
        (result) => {
          if (result) {
            dispatch(setCoordinates(result));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    if (projectId === undefined) {
      const storedProjectId = getItem("current_project");
      if (storedProjectId) {
        setProjectId(storedProjectId);
      } else if (currentUserId) {
        const projectApi = new ProjectApi();

        projectApi.getAll({ id_user: currentUserId }).then(
          (result: any) => {
            if (result.length > 0) {
              const id = result[0].uuid;
              setProjectId(id);
              setItem("current_project", id);
            }
          },
          (error) => {
            dispatch(setError(error));
          }
        );
      }
    }
  }, [currentUserId, dispatch]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(clearProject({}));
    dispatch(setAmounts(initialState.amounts));
    dispatch(loadProject({ id: projectId }));
    setProgress(10);
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project.id === -1) {
      onLoad(false);
      setProgress(100);
    } else if (project.id) {
      setProgress(50);
      dispatch(loadEvents({}));
      dispatch(loadCategories({}));
      getProjectCoords();
      setTimeout(() => {
        dispatch(setLoading(false));
        setProgress(100);
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);

  useEffect(() => {
    if (progress >= 100) {
      onLoad(project.id > 0 ? true : false);
    }
  }, [progress, onLoad, project.id]);

  return (
    <LinearProgress
      variant="determinate"
      value={progress ? progress : 0}
      className={progress >= 100 ? "done appLoader" : "appLoader"}
    />
  );
}

export default ProjectLoader;
