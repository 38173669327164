import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
	refreshEvents,
	loadEvents,
	setModalAction,
} from "Redux/Reducers/Project/EventsSlice";
import { getProject, setProject } from "Redux/Reducers/Project/ProjectSlice";
import { getUser } from "Redux/Reducers/User/UserSlice";

import Button from "@mui/material/Button";

import TextField from "Components/Parts/TextField";
import Editor from "Components/Parts/Editor";

/** Add actions */

import AddTask from "../Modals/AddTask";
import AddFile from "../Modals/AddFile";
import AddDate from "../Modals/AddDate";

/** Icons */
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

/** Types */
import { EventType } from "Types/Event";

/** API */
import EventApi from "Api/Models/Event";
import { postEvent } from "Api/Controllers/EventController";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

/** Redux */

const defaultEvent: EventType = {
	id: 0,
	id_project: 0,
	type: "",
	content: "",
	title: "",
	id_user: 1,
	creation_date: "",
	event_start: "",
	event_end: "",
	attr: {},
	comments: 0,
	size: 0,
};
const defaultFiles: any = [];

const EventModal = (props: {
	type: string;
	status: boolean;
	onEnd: Function;
	onClose: Function;
}) => {
	const { type, status, onEnd, onClose } = props;
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const project = useAppSelector(getProject);
	const user = useAppSelector(getUser);

	const [clearContent, setClearContent] = useState(false);
	const [event, setEvent] = useState(defaultEvent);
	const [files, setFiles] = useState(defaultFiles);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [showAmount, setShowAmount] = useState(false);
	const [fileType, setFileType] = useState("");

	const placeholders = {
		title: t("GLOBAL.PLACEHOLDERS.title"),
		description: t("GLOBAL.PLACEHOLDERS.description"),
		add_comment: t("GLOBAL.PLACEHOLDERS.add_comment"),
	};

	/** Functions */
	const postCurrentEvent = () => {
		let errorCount = 0;
		setLoading(true);
		const savingEvent = structuredClone(event);

		const now = new Date();
		now.setHours(now.getHours() + 1);

		if (savingEvent.event_start === "") {
			savingEvent.event_start = now
				.toISOString()
				.slice(0, 19)
				.replace("T", " ");
		}

		if (savingEvent.event_end === "") {
			savingEvent.event_end = now.toISOString().slice(0, 19).replace("T", " ");
		}

		savingEvent.type = type;
		savingEvent.id_project = project.id;
		savingEvent.id_user = user.id;

		if (
			type === "file" ||
			type === "picture" ||
			type === "sound" ||
			type === "video"
		) {
			savingEvent.attr.files = files;

			if (files.length === 0) {
				errorCount++;
			}
		}

		delete savingEvent.id;

		if (!errorCount) {
			const startTime = new Date();
			setError(false);
			postEvent(savingEvent).then(
				(result: any) => {
					const currentProject = structuredClone(project);
					currentProject.attributes.is_new = false;

					setEvent(defaultEvent);
					setError(false);
					setLoading(false);
					setFiles(defaultFiles);
					setClearContent(true);
					dispatch(setProject(currentProject));
					dispatch(loadEvents({}));
					dispatch(refreshEvents({}));
					dispatch(setModalAction("add"));

					onEnd();

					const endTime = new Date();
					var timeDiff = endTime.getTime() - startTime.getTime(); //in ms
					// strip the ms
					timeDiff /= 1000;

					// get seconds
					var seconds = Math.round(timeDiff);
					console.log("POST EVENT in " + seconds + " seconds");
				},
				(error) => {
					console.log(error);
				}
			);
		} else {
			setError(true);
			setLoading(false);
		}
	};

	const handleClose = () => {
		setClearContent(true);

		onClose();
	};

	const handleRadioChange = (
		eventInput: React.ChangeEvent<HTMLInputElement>
	) => {
		const newValue = (eventInput.target as HTMLInputElement).value;
		switch (newValue) {
			case "estimate":
			case "invoice":
				//console.log("change to " + newValue);
				setShowAmount(true);
				setFileType(newValue);
				break;
			default:
				//console.log("reset to default");
				setShowAmount(false);
				setFileType("");
				break;
		}

		const newEvent = { ...event, attr: { ...event.attr, fileType: newValue } };
		setEvent(newEvent);
		//console.log(newEvent);
	};

	/** Hooks */

	useEffect(() => {
		setEvent(defaultEvent);
		setError(false);
		setLoading(false);
		setFiles(defaultFiles);
		setClearContent(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<div className={status ? "open modalEvent" : "modalEvent"}>
			<div className="overlay" onClick={handleClose}></div>
			<div className="content">
				<div className="title">
					<button className="closeModal" onClick={handleClose}>
						<CloseIcon />
					</button>
					<strong>{t("TYPE." + type)}</strong>
				</div>
				<div className="row">
					<div className="col">
						<TextField
							placeholder={placeholders.title}
							value={event.title}
							onUpdate={(newTitle: string) => {
								const newEvent = { ...event, title: newTitle };
								setEvent(newEvent);
							}}
						/>

						{(type === "file" || type === "picture") && (
							<div>
								<span>De quelle nature est votre {t("TYPE." + type)} ?</span>

								<RadioGroup
									row
									aria-labelledby="demo-radio-buttons-group-label"
									defaultValue="none"
									name="radio-buttons-group"
									onChange={handleRadioChange}
								>
									<FormControlLabel
										value="none"
										control={<Radio />}
										label="Sans importance"
									/>
									<FormControlLabel
										value="estimate"
										control={<Radio />}
										label="Devis"
									/>
									<FormControlLabel
										value="invoice"
										control={<Radio />}
										label="Dépense"
									/>
								</RadioGroup>

								{showAmount &&
									(fileType === "estimate" || fileType === "invoice") && (
										<TextField
											placeholder="Montant"
											type="number"
											icon={'euro'}
											value={event.attr.amount ? event.attr.amount : ""}
											onUpdate={(newAmount: string) => {
												const newEvent = {
													...event,
													attr: { ...event.attr, amount: newAmount },
												};
												setEvent(newEvent);
											}}
										/>
									)}
							</div>
						)}

						<Editor
							type={"min"}
							content={event.content}
							placeholder={placeholders.add_comment}
							title={placeholders.description}
							clear={clearContent}
							onEdit={(newContent: string) => {
								const newEvent = { ...event, content: newContent };
								setEvent(newEvent);
							}}
						/>
					</div>

					{type !== "text" && (
						<div className="col">
							{(type === "picture" ||
								type === "video" ||
								type === "sound" ||
								type === "file") && (
								<AddFile
									type={type}
									filesSource={files}
									onChange={(newFiles: any) => setFiles(newFiles)}
								/>
							)}

							{type === "task" && (
								<AddTask
									event={event}
									onAdd={(newEvent: any) => setEvent(newEvent)}
								/>
							)}

							{type === "date" && (
								<AddDate
									event={event}
									onAdd={(newEvent: any) => setEvent(newEvent)}
								/>
							)}
						</div>
					)}
				</div>

				{error && (
					<Alert severity="error">
						Attention, aucun fichier n'a été sélectionné.
					</Alert>
				)}
				<Button
					className="add_event_button"
					variant="contained"
					onClick={postCurrentEvent}
				>
					{loading ? <CircularProgress /> : <>{t("GLOBAL.ACTIONS.add")}</>}
				</Button>
			</div>
		</div>
	);
};

export default EventModal;
