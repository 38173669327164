import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import {
	open,
	getStatus,
	setPictures,
	setVideo,
	setCurrent,
} from "Redux/Reducers/Lightbox/LightboxSlice";

function Gallery(props: any) {
	const { pictures } = props;
	const dispatch = useAppDispatch();
	const lightBoxStatus = useAppSelector(getStatus);

	useEffect(() => {
		dispatch(setVideo({} as any));
		dispatch(setPictures(pictures));
	}, [dispatch, pictures, lightBoxStatus]);

	return (
		<div className={"gallery"}>
			{pictures &&
				Array.isArray(pictures) &&
				pictures.map(
					(picture: { url: string; name: string }, index: number) => {
						return (
							<motion.img							
								whileTap={{ scale: 0.9 }}
								key={index}
								src={picture.url}
								alt={picture.name}
								onClick={() => {
									dispatch(setCurrent(index));
									dispatch(open());
								}}
							/>
						);
					}
				)}
		</div>
	);
}

export default Gallery;
