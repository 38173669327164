/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import Confirm from "Components/Parts/Confirm";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import TextField from "Components/Parts/TextField";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import { getUser, setUser, disconnect } from "Redux/Reducers/User/UserSlice";

import { postPassword, deleteUser } from "Api/Controllers/UserController";

import { setItem, removeItem, getItem } from "Utils/Storage";

function Generals() {
  const { t } = useTranslation();
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();

  const [currentUser, setCurrentUser] = useState(user);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [errors, setErrors] = useState({
    profile: false,
    password: false,
    address: false,
  });

  const [success, setSuccess] = useState({
    profile: false,
    password: false,
    address: false,
  });

  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  const saveInfos = () => {
    dispatch(setUser(currentUser));
    setSettingsLoading(true);

    setTimeout(() => {
      setSettingsLoading(false);
      setSuccess({ ...success, profile: true });

      setTimeout(() => {
        setSuccess({ ...success, profile: false });
      }, 3000);
    }, 1000);
  };

  const savePassword = () => {
    setPasswordLoading(true);

    if (newPassword) {
      postPassword(newPassword).then(
        (result: any) => {
          if (result.status === "success") {
            const token = result.data.token;
            if (token) {
              setItem("token", token);
              setTimeout(() => {
                setNewPassword("");
                setPasswordLoading(false);
                setSuccess({ ...success, password: true });
                window.location.reload();
              }, 500);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setErrors({ ...errors, password: true });
      setPasswordLoading(false);
    }
  };

  const requestDelete = () => {
    deleteUser(user.id).then(
      (result: any) => {
        if (result.status === "success") {
          dispatch(disconnect({}));
          console.log(getItem("token"));
          removeItem("token");
          window.location.href = "/login";
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const placeholders = {
    firstname: t("ACCOUNT.FORM.LABEL.firstname"),
    lastname: t("ACCOUNT.FORM.LABEL.lastname"),
    email: t("ACCOUNT.FORM.LABEL.e-mail"),
    password: t("ACCOUNT.FORM.LABEL.password"),
    address: t("ACCOUNT.FORM.LABEL.address"),
    city: t("ACCOUNT.FORM.LABEL.city"),
    zip: t("ACCOUNT.FORM.LABEL.zip"),
    delete_confirm: t("ACCOUNT.LABELS.confirm_delete"),
    delete_content: t("ACCOUNT.LABELS.delete_content"),
    delete_account: t("ACCOUNT.ACTIONS.delete_request"),
  };
  return (
    <div className="tabContent row">
      <Confirm
        status={showConfirmDelete}
        title={placeholders.delete_confirm}
        content={placeholders.delete_content}
        onConfirm={() => {
          requestDelete();
          setShowConfirmDelete(false);
        }}
        onClose={() => setShowConfirmDelete(false)}
        confirmButton={placeholders.delete_account}
      />
      <div className="col">
        <Card className="settings_card">
          <CardHeader title={t("ACCOUNT.LABELS.my_informations")} />
          <CardContent>
            <div className="row">
              <div className="col">
                <TextField
                  placeholder={placeholders.firstname}
                  value={currentUser.firstname}
                  onUpdate={(firstname: string) => {
                    const newUser = { ...currentUser, firstname: firstname };
                    setCurrentUser(newUser);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  placeholder={placeholders.lastname}
                  value={currentUser.name}
                  onUpdate={(name: string) => {
                    const newUser = { ...currentUser, name: name };
                    setCurrentUser(newUser);
                  }}
                />
              </div>
            </div>

            <TextField
              disabled={user.settings?.provider ? true : false}
              placeholder={placeholders.email}
              value={currentUser.email}
              onUpdate={(email: string) => {
                const newUser = { ...currentUser, email: email };
                setCurrentUser(newUser);
              }}
            />
            {user.settings?.provider && (
              <span className="lock_provider">
                {t("ACCOUNT.LABELS.lock_provider")}
              </span>
            )}
            <br />
            <br />

            <TextField
              placeholder={placeholders.address}
              value={currentUser?.address ? currentUser.address : ""}
              onUpdate={(address: string) => {
                const newUser = { ...currentUser, address: address };
                setCurrentUser(newUser);
              }}
            />
            <div className="row">
              <div className="col">
                <TextField
                  placeholder={placeholders.zip}
                  value={currentUser?.zip ? currentUser.zip : ""}
                  onUpdate={(zip: string) => {
                    const newUser = { ...currentUser, zip: zip };
                    setCurrentUser(newUser);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  placeholder={placeholders.city}
                  value={currentUser?.city ? currentUser.city : ""}
                  onUpdate={(city: string) => {
                    const newUser = { ...currentUser, city: city };
                    setCurrentUser(newUser);
                  }}
                />
              </div>
            </div>
          </CardContent>
          <CardActions disableSpacing className="settings_actions">
            {success.profile && (
              <span className="success_message">
                {t("ACCOUNT.LABELS.success_profile")}
              </span>
            )}
            <LoadingButton
              loading={settingsLoading}
              onClick={saveInfos}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              {t("ACCOUNT.ACTIONS.save")}
            </LoadingButton>
          </CardActions>
        </Card>
      </div>
      <div className="col">
        <Card className="settings_card">
          <CardHeader title={t("ACCOUNT.LABELS.change_password")} />
          <CardContent>
            <TextField
              disabled={user.settings?.provider ? true : false}
              placeholder={placeholders.password}
              value={newPassword}
              type="password"
              error={errors.password}
              showPasswordIcon={true}
              onUpdate={(newPassword: string) => {
                setNewPassword(newPassword);
              }}
            />
            {user.settings?.provider && (
              <span className="lock_provider">
                {t("ACCOUNT.LABELS.lock_provider")}
              </span>
            )}
          </CardContent>

          <CardActions disableSpacing className="settings_actions">
            {errors.password && (
              <span className="error_message">
                {t("ACCOUNT.LABELS.error_password")}
              </span>
            )}

            {success.password && (
              <span className="success_message">
                {t("ACCOUNT.LABELS.success_password")}
              </span>
            )}
            {(!user.settings?.provider ||
              user.settings.provider === undefined ||
              user.settings.provider === "") && (
              <LoadingButton
                loading={passwordLoading}
                onClick={savePassword}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                {t("ACCOUNT.ACTIONS.create_new_password")}
              </LoadingButton>
            )}
          </CardActions>
        </Card>
        <Card className="settings_card">
          <CardHeader title={t("ACCOUNT.LABELS.delete_request_title")} />
          <CardContent className="delete_content">
            <span>{t("ACCOUNT.LABELS.delete_request_content")}</span>
            <button
              className="delete_button"
              onClick={() => setShowConfirmDelete(true)}
            >
              {t("ACCOUNT.ACTIONS.delete_request")}
            </button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Generals;
