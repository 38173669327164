/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import EventModal from "./Modals/EventModal";

/* Images */
import FileIcon from "Assets/Images/clear/events/Files.png";
import ImageIcon from "Assets/Images/clear/events/Picture.png";
import SoundIcon from "Assets/Images/clear/events/Sound.png";
import VideoIcon from "Assets/Images/clear/events/Camera.png";
import CommentIcon from "Assets/Images/clear/events/Text.png";
import TaskIcon from "Assets/Images/clear/events/Task.png";
import DateIcon from "Assets/Images/clear/events/Date.png";

function AddEvent() {
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const { t } = useTranslation();

  const openModal = (type: string) => {
    setType(type);
    setOpen(true);
  };
  return (
    <>
      <div className={showNav ? "open addEvent" : "addEvent"}>
        <div className="overlay" onClick={() => setShowNav(!showNav)}></div>
        <button className="buttonAdd" onClick={() => setShowNav(!showNav)}>
          +
        </button>
        <div className="menu">
          <div className="group">
            <button
              className="action"
              onClick={() => {
                openModal("file");
              }}
            >
              <img src={FileIcon} alt="file" />
              <span>{t("TYPE.file")}</span>
            </button>
            <button
              className="action"
              onClick={() => {
                openModal("picture");
              }}
            >
              <img src={ImageIcon} alt="file" />
              <span>{t("TYPE.picture")}</span>
            </button>
            <button
              className="action"
              onClick={() => {
                openModal("sound");
              }}
            >
              <img src={SoundIcon} alt="file" />
              <span>{t("TYPE.sound")}</span>
            </button>
            <button
              className="action"
              onClick={() => {
                openModal("video");
              }}
            >
              <img src={VideoIcon} alt="file" />
              <span>{t("TYPE.video")}</span>
            </button>
          </div>
          <div className="group">
            <button
              className="action"
              onClick={() => {
                openModal("text");
              }}
            >
              <img src={CommentIcon} alt="file" />
              <span>{t("TYPE.text")}</span>
            </button>
            <button
              className="action"
              onClick={() => {
                openModal("task");
              }}
            >
              <img src={TaskIcon} alt="file" />
              <span>{t("TYPE.task")}</span>
            </button>
          </div>
          <div className="group">
            <button
              className="action"
              onClick={() => {
                openModal("date");
              }}
            >
              <img src={DateIcon} alt="file" />
              <span>{t("TYPE.date")}</span>
            </button>
          </div>
        </div>
      </div>
      <EventModal
        type={type}
        status={open}
        onEnd={() => {
          setOpen(false);
          setShowNav(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

export default AddEvent;
