/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import { useTranslation } from "react-i18next";
import Select from "react-select";

import TextField from "Components/Parts/TextField";
import Confirm from "Components/Parts/Confirm";
import COLORS from "Utils/colors";
import { BlockPicker } from "react-color";
import FormControl from "@mui/material/FormControl";

import { selectDatasFormatter } from "Utils/SelectFormatter";

/** MUI Components */
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

/** Icons */
import StartIcon from "@mui/icons-material/Start";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopy from "@mui/icons-material/ContentCopy";

/** API */
import ProjectApi from "Api/Models/Project";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getProfessions } from "Redux/Reducers/App/CategorySlice";

const projectApi = new ProjectApi();

function Contributors(props: {
  index: number;
  open: boolean;
  contributor: any;
  onDelete: Function;
  onClose: Function;
  onSave: Function;
}) {
  const { index, contributor, open, onDelete, onClose, onSave } = props;
  const { t } = useTranslation();

  const [showNav, setShowNav] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const professions = useAppSelector(getProfessions);
  const [showPicker, setShowPicker] = useState(false);

  const boxControl = useAnimation();
  const detailsControl = useAnimation();
  const [roles, setRoles] = useState([{ value: -1, label: "", key: "" }]);

  const [currentContributor, setCurrentContributor] = useState(contributor);

  const [newContributorFunction, setNewContributorFunction] = useState({
    value: 0,
    label: "",
    key: "",
  });

  const openDetails = () => {
    boxControl.start({
      zIndex: 9,
      opacity: 1,
      transition: { duration: 0.3 },
    });
    setTimeout(() => {
      detailsControl.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.2 },
      });
    }, 400);
  };

  const closeDetails = () => {
    saveContributor();
    closeModal();
  };

  const closeModal = () => {
    detailsControl.start({
      x: "50%",
      opacity: 0,
      transition: { duration: 0.3 },
    });

    setTimeout(() => {
      boxControl.start({
        zIndex: -1,
        opacity: 0,
        transition: { duration: 0.2 },
      });
    }, 400);

    setTimeout(onClose, 600);
  };

  const saveContributor = () => {
    const contributorToSave = {
      ...currentContributor,
      function: newContributorFunction.label,
      function_id: newContributorFunction.value,
    };

    onSave(contributorToSave, index);
  };

  /** Hooks */

  useEffect(() => {
    if (professions.length) {
      const TranslatedRoles = professions.map((item: any) => {
        if (item.name === "other") return null;
        return {
          ...item,
          name: t("PROJECT.PROFESSIONS." + item.name),
          key: item.name,
        };
      });

      const formattedRoles = selectDatasFormatter(TranslatedRoles);

      formattedRoles.push({
        value: "",
        label: t("PROJECT.PROFESSIONS.other"),
        key: "other",
      });

      setRoles(formattedRoles);
      setNewContributorFunction(formattedRoles[0]);
    }
  }, [professions]);

  useEffect(() => {
    if (open) {
      openDetails();
    }
  }, [open]);

  useEffect(() => {
    setCurrentContributor(contributor);

    const currentFunction = roles.find((item: any) => {
      return item.label === contributor.function;
    });

    if (currentFunction) {
      setNewContributorFunction(currentFunction);
    } else if (
      contributor.function !== "" &&
      contributor.function !== undefined
    ) {
      setNewContributorFunction({
        value: 9,
        label: t("PROJECT.PROFESSIONS." + contributor.function),
        key: contributor.function,
      });
    }
  }, [contributor]);

  /** Placeholders */

  const placeholder = {
    company: t("PROJECT.CONTRIBUTORS.LABELS.company"),
    email: t("PROJECT.CONTRIBUTORS.LABELS.email"),
    lastname: t("PROJECT.CONTRIBUTORS.LABELS.lastname"),
    firstname: t("PROJECT.CONTRIBUTORS.LABELS.firstname"),
    function: t("PROJECT.CONTRIBUTORS.LABELS.function"),
    function_select: t("PROJECT.CONTRIBUTORS.LABELS.function_select"),
    function_define: t("PROJECT.CONTRIBUTORS.LABELS.function_define"),
    phone: t("PROJECT.CONTRIBUTORS.LABELS.phone"),
    fulladdress: t("PROJECT.CONTRIBUTORS.LABELS.fulladdress"),
    delete_contributor: t("EVENT.ACTIONS.delete_contributor"),
  };
  return (
    <motion.div animate={boxControl} className={"eventDetailsContainer"}>
      <Confirm
        status={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        onConfirm={() => {
          onDelete(index);
          closeModal();
        }}
        title={placeholder.delete_contributor}
        content={
          "Vous-êtes sur le point de supprimer l'intervenant \"" +
          contributor.company +
          '". Voulez-vous continuer ?'
        }
      />
      <div
        className={"overlay"}
        onClick={() => {
          closeDetails();
        }}
      ></div>
      <motion.div animate={detailsControl} className={"eventDetails"}>
        <div className="details_header">
          <div className="details_infos">
            <h2>{currentContributor.company}</h2>
          </div>
          <div className="details_actions">
            <Button
              onClick={() => {
                setShowNav(!showNav);
              }}
            >
              <MoreHorizIcon />
            </Button>
            <Button className={"closeButton"} onClick={closeDetails}>
              <StartIcon />
            </Button>

            {showNav && (
              <Paper
                className="event_actions_menu"
                sx={{ width: 180, maxWidth: "100%" }}
              >
                <MenuList>
                  <MenuItem>
                    <ListItemIcon>
                      <ContentCopy fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("GLOBAL.ACTIONS.duplicate")}</ListItemText>
                  </MenuItem>

                  <Divider />
                  <MenuItem
                    className="delete_action"
                    onClick={() => setConfirmOpen(true)}
                  >
                    <ListItemIcon>
                      <DeleteForeverRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("GLOBAL.ACTIONS.delete")}</ListItemText>
                  </MenuItem>
                </MenuList>
              </Paper>
            )}
          </div>
        </div>

        <div className={"infos"}>
          <div className={"content "}>
            <div className="row">
              <div className="col">
                <TextField
                  placeholder={placeholder.company}
                  value={currentContributor.company}
                  onUpdate={(element: string) => {
                    const newContributor = {
                      ...currentContributor,
                      company: element,
                    };
                    setCurrentContributor(newContributor);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  placeholder={placeholder.email}
                  value={currentContributor.email}
                  onUpdate={(element: string) => {
                    const newContributor = {
                      ...currentContributor,
                      email: element,
                    };
                    setCurrentContributor(newContributor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  placeholder={placeholder.firstname}
                  value={currentContributor.firstname}
                  onUpdate={(element: string) => {
                    const newContributor = {
                      ...currentContributor,
                      firstname: element,
                    };
                    setCurrentContributor(newContributor);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  placeholder={placeholder.lastname}
                  value={currentContributor.name}
                  onUpdate={(element: string) => {
                    const newContributor = {
                      ...currentContributor,
                      name: element,
                    };
                    setCurrentContributor(newContributor);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <TextField
                placeholder={"Adresse complète"}
                value={currentContributor?.fulladdress}
                onUpdate={(element: string) => {
                  setCurrentContributor({
                    ...currentContributor,
                    fulladdress: element,
                  });
                }}
              />
            </div>
            <div className="row">
              <div className="col md6">
                <TextField
                  placeholder={placeholder.phone}
                  value={currentContributor.phone}
                  onUpdate={(element: string) => {
                    const newContributor = {
                      ...currentContributor,
                      phone: element,
                    };
                    setCurrentContributor(newContributor);
                  }}
                />
              </div>
              <div className="col md6">
                <div className="row row_color">
                  <div className="col md6 color_picker_box">
                    <span>Attribuer une couleur</span>
                    <FormControl
                      className="select_field"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <button
                        className="colorPickerButton"
                        onClick={() => {
                          setShowPicker(true);
                        }}
                      >
                        <div
                          style={{ backgroundColor: currentContributor.color }}
                        ></div>
                      </button>
                      {showPicker && (
                        <BlockPicker
                          className="colorPicker"
                          colors={COLORS}
                          color={currentContributor.color}
                          onChangeComplete={(color) => {
                            setCurrentContributor({
                              ...currentContributor,
                              color: color.hex,
                            });

                            setShowPicker(false);
                          }}
                        />
                      )}
                    </FormControl>
                  </div>
                  <div className="col md6">
                    <div className="function_field">
                      <Select
                        classNamePrefix="react-select"
                        aria-label="Sélectionner une fonction"
                        placeholder="Sélectionner une fonction"
                        options={roles}
                        name="Fonction"
                        value={newContributorFunction}
                        isSearchable={true}
                        isClearable={false}
                        onChange={(element: any) => {
                          const newFunctionObj = element;
                          setNewContributorFunction(newFunctionObj);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#e238324f",
                            primary50: "#e2383287",
                            primary: COLORS[0],
                          },
                        })}
                      />

                      {newContributorFunction?.value == 999 && (
                        <div>
                          <TextField
                            placeholder={"Définir la fonction"}
                            value={newContributorFunction.label}
                            onUpdate={(element: string) => {
                              const newFunctionObj = {
                                value: newContributorFunction.value,
                                label: element,
                                key: newContributorFunction.key,
                              };
                              setNewContributorFunction(newFunctionObj);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Contributors;
