import abstractApiObject from "./abstractApiObject";

class categoryApiObject extends abstractApiObject {
  constructor() {
    super();
    this.controller = "category";
  }

  getProfessions() {
    const params = {
      url: this.controller + "/profession",
      method: "GET",
      body: {},
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result: any) => {
          if (result?.status === "ok") {
            resolve(result.data);
          } else {
            reject(result);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  postTips(datas: { id_project: number; id_profession: number; lang: string }) {
    const params = {
      url: this.controller + "/createtips",
      method: "POST",
      body: datas,
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default categoryApiObject;
