/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Masonry from "@mui/lab/Masonry";
import { useAppSelector, useAppDispatch } from "Redux/hooks";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  setFilters,
  getFilters,
  getAmounts,
  getEvents,
} from "Redux/Reducers/Project/EventsSlice";

/** Utils */
import { convertStringToDate } from "Utils/DateFormatter";

/** Types */
import { EventType, EventPropsType } from "Types/Event";

function Grid(props: EventPropsType) {
  const { onOpen,events } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const amounts = useAppSelector(getAmounts);

  const currentFilters = useAppSelector(getFilters);

  const handleHtml = (content = "") => {
    return { __html: content };
  };

  const handleScrollList = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      // refresh events
      const newFilters = structuredClone(currentFilters);
      newFilters.page = newFilters.page ? newFilters.page + 1 : 2;

      console.log("refresh events", newFilters, amounts.events);

      if (newFilters.page * 10 <= amounts.events + 10) {
        dispatch(setFilters(newFilters));
      }
    }
  };

  return (
    <div className="gridContainer" onScroll={handleScrollList}>
      {events.length === 0 && (
        <span className="emptyProject">
          Ce projet n'a pas encore d'évènement.
        </span>
      )}
      <Masonry columns={4} spacing={3}>
        {events.map((event: EventType, index: number) => (
          <motion.div
            key={index}
            className={"item " + event.type}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              onOpen(event);
            }}
          >
            <div className="content">
              <div className="topInfos">
                {/*}
                <div className="contributor">
                  <img src={defaultUser} alt="" />
                </div>
              */}
                <div className="type">
                  {event.type === "picture" && (
                    <span className="picture">{t("TYPE.picture")}</span>
                  )}
                  {event.type === "text" && (
                    <span className="comment">{t("TYPE.comment")}</span>
                  )}
                  {event.type === "sound" && (
                    <span className="sound">{t("TYPE.sound")}</span>
                  )}
                  {event.type === "file" && (
                    <span className="file">{t("TYPE.file")}</span>
                  )}
                  {event.type === "date" && (
                    <span className="date">{t("TYPE.date")}</span>
                  )}
                  {event.type === "task" && (
                    <span className="task">{t("TYPE.task")}</span>
                  )}
                </div>
                <div className="date">
                  <span>{convertStringToDate(event.event_start)}</span>
                </div>
              </div>
              <div className="mainContent">
                {event.type === "text" && (
                  <>
                    {event.title ? (
                      <div
                        dangerouslySetInnerHTML={handleHtml(event.title)}
                      ></div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={handleHtml(
                          event.content.length > 100
                            ? event.content.substring(0, 100) + "..."
                            : event.content
                        )}
                      ></div>
                    )}
                  </>
                )}
                {event.type === "sound" && (
                  <div className={"sound"}>
                    {event?.attr?.sounds?.length > 0 && (
                      <audio
                        controls={true}
                        src={event.attr?.sounds[0].url}
                      ></audio>
                    )}
                  </div>
                )}
                {event.type === "file" && (
                  <div className={"files"}>
                    {Array.isArray(event.attr.files) &&
                      event?.attr?.files?.length > 0 && (
                        <ul>
                          {event.attr.files
                            .slice(0, 3)
                            .map((file: any, index: number) => {
                              return (
                                <li key={index}>
                                  <a href={file.url} download={file.name}>
                                    {file.name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      )}
                  </div>
                )}
                {event.type === "date" && (
                  <>
                    {event.content && (
                      <div
                        dangerouslySetInnerHTML={handleHtml(
                          event.content.length > 100
                            ? event.content.substring(0, 100) + "..."
                            : event.content
                        )}
                      ></div>
                    )}
                    {!event.content && <span>Nouvelle phase</span>}
                  </>
                )}
                {event.type === "picture" && (
                  <div
                    className={
                      "pictures " +
                      (event?.attr?.files?.length > 1
                        ? "multiple count_" +
                          event.attr.files.slice(0, 3).length
                        : "")
                    }
                  >
                    {event?.attr?.files && Array.isArray(event?.attr?.files) ? (
                      <>
                        {event.attr.files
                          .slice(0, 3)
                          .map((picture: any, index: number) => {
                            return (
                              <motion.img
                                key={index}
                                whileHover={{
                                  scale: 1.01,
                                }}
                                whileTap={{ scale: 0.9 }}
                                src={picture.url}
                                alt={picture.name}
                              />
                            );
                          })}

                        {event?.attr?.length > 2 && (
                          <span>+ {event?.attr?.length - 2}</span>
                        )}
                      </>
                    ) : (
                      <span className="empty">{t("GLOBAL.EMPTY.picture")}</span>
                    )}
                  </div>
                )}
                {event.type === "task" && (
                  <>
                    <div className="task_content">
                      {event?.attr?.status === "done" ? (
                        <CheckCircleIcon className="done" />
                      ) : (
                        <CheckCircleOutlineIcon />
                      )}
                      <span>
                        {event.title ? event.title : "Nouvelle tâche"}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </motion.div>
        ))}
      </Masonry>
    </div>
  );
}

export default Grid;
