import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const md5 = require("md5");

export interface UserState {
  id: number;
  email: string;
  id_plan: number;
  name: string;
  firstname: string;
  logged: boolean;
  wizard_done: boolean;
  picture: string;
  company: string;
  vat: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  settings: any;
}

const initialState: UserState = {
  id: 0,
  email: "",
  id_plan: 1,
  name: "",
  firstname: "",
  logged: false,
  wizard_done: false,
  picture: "",
  company: "",
  vat: "",
  address: "",
  zip: "",
  city: "",
  country: "BE",
  settings: {},
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    checkToken: (state, action: PayloadAction<any>) => {
      state.logged = true;
    },
    setUser: (state, action: PayloadAction<any>) => {
      console.log("action.payload", action.payload);
      state.id = action.payload?.id;
      state.id_plan = action.payload?.id_plan;
      state.name = action.payload?.name
        ? action.payload.name
        : action.payload?.lastname;
      state.firstname = action.payload?.firstname;
      state.email = action.payload?.email;
      state.company = action.payload?.company;
      state.email = action.payload?.email;
      state.email = action.payload?.email;
      state.picture = action.payload?.picture;
      state.vat = action.payload?.vat;
      state.address = action.payload?.address;
      state.zip = action.payload?.zip;
      state.city = action.payload?.city;
      state.country = action.payload?.country;
      state.wizard_done = parseInt(action.payload?.wizard_done) ? true : false;

      state.picture = action?.payload?.picture
        ? action.payload.picture
        : "https://www.gravatar.com/avatar/" +
          md5(action.payload?.email) +
          "?d=mp";
    },
    disconnect: (state, action: PayloadAction<any>) => {
      state = initialState;
    },
    setSettings: (state, action: PayloadAction<any>) => {
      state.settings = action.payload;
    },
  },
});

export const { setUser, checkToken, disconnect, setSettings } = UserSlice.actions;
export const getUser = (state: RootState) => state.user;
export const getUserId = (state: RootState) => state.user.id;

export const getSettings = (state: RootState) => state.user.settings;


export default UserSlice.reducer;
