import React from "react";
import Chip from "@mui/material/Chip";

const Tags = (props: {
	order?: string;
	tags: string[];
	handleClick?: Function;
}) => {
	const { tags, order, handleClick } = props;

	if (Array.isArray(tags)) {
		return (
			<>
				{order === "right" && tags.length > 3 && (
					<span className="tagsLeft">+{tags.length - 3}</span>
				)}
				{tags.slice(0, 3).map((tag: string, index: number) => {
					return (
						<Chip
							label={tag}
							key={index}
							size="small"
							onClick={() => {
								handleClick && handleClick(tag);
							}}
							className={handleClick ? "clickable tag" : "tag"}
						/>
					);
				})}
				{order !== "right" && tags.length > 3 && (
					<span className="tagsLeft">+{tags.length - 3}</span>
				)}
			</>
		);
	} else {
		return <></>;
	}
};

export default Tags;
