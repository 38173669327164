import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

/** Models */
import eventApiObject from "Api/Models/Event";
const eventApi = new eventApiObject();

/** Actions */

export const postEvent = (datas: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	return new Promise((resolve, reject) => {
		delete datas.comments;
		eventApi.post(datas).then(
			(eventFromApi: any) => {
				if (eventFromApi) {
					const attributes =
						typeof eventFromApi.attr === "string"
							? JSON.parse(eventFromApi.attr)
							: eventFromApi.attr;
					const eventDatas = { ...eventFromApi, attr: attributes };
					returnDatas.status = "success";
					returnDatas.message = "";
					returnDatas.data = eventDatas;
					resolve(returnDatas);
				}
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};
export const getEvent = (id_event: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	return new Promise((resolve, reject) => {
		eventApi.getOne(id_event).then(
			(eventFromApi: any) => {
				if (eventFromApi) {
					const attributes =
						typeof eventFromApi.attr === "string"
							? JSON.parse(eventFromApi.attr)
							: eventFromApi.attr;
					const eventDatas = { ...eventFromApi, attr: attributes };
					returnDatas.status = "success";
					returnDatas.message = "";
					returnDatas.data = eventDatas;
					resolve(returnDatas);
				}
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const getEvents = (params: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;	
	return new Promise((resolve, reject) => {
		if (params?.id_project) {
			eventApi.getByProject(params).then(
				(eventsFromApi: any) => {
					returnDatas.status = "success";
					returnDatas.message = "";
					returnDatas.data = {
						events: eventsFromApi,
					};
					resolve(returnDatas);
				},
				(error: string) => {
					reject(returnDatas);
				}
			);
		} else {
			reject(returnDatas);
		}
	});
};

export const getAmounts = (id_project: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	const eventsAmount: any = { events: 0, files: 0, tasks: 0,medias:0 };
	return new Promise((resolve, reject) => {
		eventApi.getAmounts(id_project).then(
			(amountFromApi: any) => {
				for (const item of amountFromApi) {
					eventsAmount.events += parseInt(item.count);

					if (item.type === "task") {
						eventsAmount.tasks = parseInt(item.count);
					}

					if (item.type === "file") {
						eventsAmount.files = parseInt(item.count);
					}

					if (item.type === "video" || item.type === 'picture' || item.type === 'sound') {
						eventsAmount.medias += parseInt(item.count);
					}
				}

				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = {
					amount: eventsAmount,
				};
				resolve(returnDatas);
			},
			(error: string) => {
				reject(returnDatas);
			}
		);
	});
};

export const putEvent = (eventToSave: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	const savingEvent = structuredClone(eventToSave);

	if (Array.isArray(savingEvent.attr)) {
		savingEvent.attr = Object.assign({}, savingEvent.attr);
	}
	if (savingEvent?.attr?.files) {
		delete savingEvent.attr.files;
	}

	return new Promise((resolve, reject) => {
		eventApi.put(savingEvent).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postShare = (id: number, email: string, message: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.postShare(id, email, message).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postConfirmDate = (id: number, message: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.postConfirmDate(id, message).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postCancelDate = (id: number, message: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.postCancelDate(id, message).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const deleteEvent = (id_event: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.delete(id_event).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postComment = (datas: {
	comment: string;
	id_event: number;
	id_user: number;
}) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.postComent(datas).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = result.datas;
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const getHistory = (id_event: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.getHistory(id_event).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = result.datas;
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postDuplicate = (id: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.postDuplicate(id).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = result.datas;
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postStatus = (hash: string | number, status: string, isHash?: boolean) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		eventApi.postStatus(hash, status, isHash).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = result.datas;
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};
