const MODE = "dev"; // dev, prod,local

const config = {
	/** Google API */
	googleApi: "AIzaSyB8qhtIGUbu9IjoloWV0yJRBDwO8BfJCLc",
	googleClientId:
		"806881025493-p1en6mkfveb3n6buga3ucrulvqd2ui1q.apps.googleusercontent.com",

	/** Facebook API */
	facebookAppId: "1220240088693614",
	/** API URL */
	apiUrl:
		MODE === "prod"
			? "https://api.saytoapp.com/"
			: MODE === "dev"
			? "https://apidev.saytoapp.com/"
			: "http://api.sayto.local:85/",
	baseUrl:
		MODE === "prod"
			? "https://my.saytoapp.com/"
			: MODE === "dev"
			? "https://dev.saytoapp.com/"
			: "http://localhost:3000/",
};

export default config;
