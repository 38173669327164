/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { BlockPicker } from "react-color";
import { TagsInput } from "react-tag-input-component";
import { Link } from "react-router-dom";
import SkeletonDetails from "../Skeletons/Details";

/** MUI Components */
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";

/** Icons */
import StartIcon from "@mui/icons-material/Start";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EventBusyIcon from "@mui/icons-material/EventBusy";

/** Components */
import Editor from "Components/Parts/Editor";
import TextField from "Components/Parts/TextField";
import Confirm from "Components/Parts/Confirm";
import Comments from "./Comments";
import Gallery from "./Gallery";
import SoundPlayer from "./SoundPlayer";
import VideoPlayer from "./VideoPlayer";
import DateEvent from "./DateEvent";
import TaskEvent from "./TaskEvent";
import ShareEvent from "./Modals/ShareEvent";
import ConfirmDate from "./Modals/ConfirmDate";
import CancelDate from "./Modals/CancelDate";
import Tags from "Components/Parts/Tags";
import Avatars from "Components/Parts/Avatars";
/** Redux */

import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
	getContractors,
	getProject,
} from "Redux/Reducers/Project/ProjectSlice";
import {
	getCurrentEvent,
	saveEvent,
	deleteEvent,
	setCurrentEvent,
	setIsOpen,
	isOpen,
	setModalAction,
} from "Redux/Reducers/Project/EventsSlice";

import COLORS from "Utils/colors";
import { hasSomeParentTheClass } from "Utils/Globals";

import { postDuplicate, postStatus } from "Api/Controllers/EventController";

const commentType: any = [];
const contributorIdsArray = [] as any[];
const tagsArray = [] as string[];

const fileTypes = [
	{ value: "estimate", label: "Devis" },
	{ value: "invoice", label: "Dépense" },
	{ value: "other", label: "Autre" },
];

function Details() {
	/** VARS */
	const dispatch = useAppDispatch();

	const project = useAppSelector(getProject);
	const event = useAppSelector(getCurrentEvent);
	const contributorsList = useAppSelector(getContractors);
	const open = useAppSelector(isOpen);

	/** Refs */

	const eventTitleInput: any = createRef();
	const editTagInput: any = createRef();

	/** STATE */
	const [showNav, setShowNav] = useState(false);
	const [editContractors, setEditContractors] = useState(false);
	const [editTags, setEditTags] = useState(false);
	const [editTitle, setEditTitle] = useState(false);
	const [editContent, setEditContent] = useState(false);
	const [clearContent, setClearContent] = useState(false);
	const [loading, setLoading] = useState(true);
	const [avatarsContractors, setAvatarsContractors] = useState<any>([]);
	const [contributorsOptions, setContributorsOptions] =
		useState(contributorIdsArray);
	const [selectedContributors, setSelectedContributors] =
		useState(contributorIdsArray);
	const [selectEventFileType, setSelectEventFleType] = useState({
		value: "-1",
		label: "",
	});
	const [amount, setAmount] = useState(0);
	const [tags, setTags] = useState(tagsArray);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [shareOpen, setShareOpen] = useState(false);
	const [confirmDateOpen, setConfirmDateOpen] = useState(false);
	const [confirmCancelDate, setConfirmCancelDate] = useState(false);
	const [editorContent, setEditorContent] = useState("");
	const [dateStatus, setDateStatus] = useState("planned");
	const [isEditing, setIsEditing] = useState(false);

	const [hasChanged, setHasChanged] = useState(false);

	const { t } = useTranslation();

	const boxControl = useAnimation();
	const detailsControl = useAnimation();

	/** FUNCTIONS */

	/** Modal functions */
	const openDetails = () => {
		setClearContent(false);
		setHasChanged(false);

		boxControl.start({
			zIndex: 9,
			opacity: 1,
			transition: { duration: 0.3 },
		});
		setTimeout(() => {
			detailsControl.start({
				x: 0,
				opacity: 1,
				transition: { duration: 0.2 },
			});
		}, 400);
	};

	const closeDetails = () => {
		console.log("close request", hasChanged);
		setIsEditing(false);
		setShowNav(false);
		setEditContractors(false);
		setSelectedContributors(contributorIdsArray);
		setEditTags(false);
		setEditTitle(false);
		setEditContent(false);
		setClearContent(true);
		dispatch(setIsOpen(false));

		hasChanged && dispatch(setModalAction("edit"));
		hasChanged && dispatch(saveEvent({}));
		!hasChanged && dispatch(setCurrentEvent({}));

		detailsControl.start({
			x: "50%",
			opacity: 0,
			transition: { duration: 0.3 },
		});

		setTimeout(() => {
			boxControl.start({
				zIndex: -1,
				opacity: 0,
				transition: { duration: 0.2 },
			});
		}, 400);
	};

	/** content Functions */
	const handleHtml = (content = "") => {
		return { __html: content };
	};

	const handleChangeContributors = (selected: any) => {
		const newEvent = structuredClone(event);
		newEvent.attr.contractors = selected.map((element: any) => {
			return element.value;
		});
		setHasChanged(true);
		dispatch(setCurrentEvent(newEvent));
		setEditContractors(false);
	};

	const handleChangeFileType = (type: any) => {
		const newEvent = structuredClone(event);
		newEvent.attr.fileType = type.value;

		const selectedType = fileTypes.filter((element: any) => {
			return element.value === type.value ? element : false;
		});
		setSelectEventFleType(selectedType[0]);
		dispatch(setCurrentEvent(newEvent));
		setHasChanged(true);
	};

	const handleCloseOpenElements = (element: any) => {
		const classForNav = ["event_actions_menu", "event_actions_trigger"];
		!hasSomeParentTheClass(element.target, classForNav) && setShowNav(false);
	};

	const handleConfirmCancelDate = () => {
		setConfirmCancelDate(true);
	};

	/** Event edition functions */

	const requestUpdate = () => {
		dispatch(setModalAction("edit"));
		dispatch(saveEvent({}));
		closeDetails();
	};

	const requestDelete = () => {
		dispatch(setModalAction("delete"));
		dispatch(deleteEvent({}));
		closeDetails();
	};

	const requestDuplicate = () => {
		postDuplicate(event.id).then((response: any) => {
			if (response.status === "success") {
				dispatch(setModalAction("add"));
				closeDetails();
			}
		});
	};

	const requestTransform = (type: string) => {
		const currentEvent = { ...event, type: type };
		dispatch(setCurrentEvent(currentEvent));
		dispatch(saveEvent({}));
	};

	/** HOOKS */

	// prepare contractors
	useEffect(() => {
		const contributorsListArray =
			typeof contributorsList === "string"
				? JSON.parse(contributorsList)
				: contributorsList;
		if (contributorsListArray && Array.isArray(contributorsListArray)) {
			const contributorsArray: any = [];
			contributorsListArray.map((contributor: any, index: number) => {
				if (contributor?.email) {
					const label =
						contributor.company !== ""
							? contributor.company
							: contributor.firstname + " " + contributor.lastname;
					contributorsArray.push({
						value: contributor.id,
						label: label,
					});
				}

				return contributor;
			});
			setContributorsOptions(contributorsArray);
		}
	}, [contributorsList]);

	// toggle modal view
	useEffect(() => {
		if (open) {
			openDetails();
		}
	}, [open]);

	// Prepare events
	useEffect(() => {
		if (event.attr) {
			const attributes =
				typeof event.attr === "string" ? JSON.parse(event.attr) : event.attr;

			if (attributes) {
				let tagsAttr =
					typeof attributes.tags === "string"
						? JSON.parse(attributes.tags)
						: attributes.tags;
				let contributorsAttr =
					typeof attributes.contractors === "string"
						? JSON.parse(attributes.contractors)
						: attributes.contractors;

				if (attributes.amount) {
					const amount =
						typeof attributes.amount === "string"
							? attributes.amount.replaceAll('"', "")
							: attributes.amount;
					setAmount(amount);
				}

				if (attributes.fileType) {
					let fileType =
						typeof attributes.fileType === "string"
							? attributes.fileType.replaceAll('"', "")
							: attributes.fileType;

					const selectedType = fileTypes.filter((element: any) => {
						return element.value === fileType ? element : false;
					});
					setSelectEventFleType(selectedType[0]);
				} else {
					setSelectEventFleType({
						value: "",
						label: "",
					});
				}
				if (tagsAttr) {
					setTags(tagsAttr);
				} else {
					setTags(tagsArray);
				}

				if (contributorsAttr && Array.isArray(contributorsAttr)) {
					const contractors = contributorsOptions.filter((option: any) => {
						return contributorsAttr.includes(option.value);
					});

					if (contributorsList && contributorsAttr) {
						let AvatarContractors: any = contributorsList.filter(
							(item: any) => {
								return contributorsAttr.includes(item.id);
							}
						);
						AvatarContractors = AvatarContractors.map((item: any) => {
							return { value: item.id, label: item.company, color: item.color };
						});
						setAvatarsContractors(AvatarContractors);
						setSelectedContributors(contractors);
					} else {
						setSelectedContributors(contributorIdsArray);
					}
				}
			}
		}

		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, [event]);

	// prepare Editor
	useEffect(() => {
		setEditorContent(event.content);
	}, [event.content]);

	/** Placeholders */

	const placeholders = {
		no_options: t("EVENT.ACTIONS.no_options"),
		delete_event_title: t("EVENT.ACTIONS.delete"),
		delete_event_confirm: t("EVENT.ACTIONS.delete_confirm_message"),
		delete_event_continue: t("EVENT.ACTIONS.delete_event_continue"),
	};

	return (
		<motion.div animate={boxControl} className={"eventDetailsContainer"}>
			<ShareEvent
				open={shareOpen}
				onClose={() => {
					setShareOpen(false);
				}}
			/>
			<ConfirmDate
				open={confirmDateOpen}
				onClose={() => {
					setConfirmDateOpen(false);
				}}
			/>
			<CancelDate
				open={confirmCancelDate}
				onClose={() => {
					setConfirmCancelDate(false);
				}}
			/>
			<Confirm
				status={confirmOpen}
				onClose={() => {
					setConfirmOpen(false);
				}}
				onConfirm={requestDelete}
				title={placeholders.delete_event_title}
				content={
					placeholders.delete_event_confirm +
					' "' +
					event.title +
					'" ' +
					placeholders.delete_event_continue
				}
			/>
			<div
				className={"overlay"}
				onClick={() => {
					closeDetails();
				}}
			></div>

			<motion.div
				animate={detailsControl}
				className={"eventDetails"}
				onClick={handleCloseOpenElements}
			>
				{loading ? (
					<SkeletonDetails />
				) : (
					<>
						<div className="details_header">
							<div className="details_infos">
								<div className="actions">
									{event.type === "task" && (
										<Button
											onClick={() => {
												const newEvent = structuredClone(event);
												newEvent.attr.status = "done";
												setHasChanged(true);
												dispatch(setCurrentEvent(newEvent));
											}}
											variant="outlined"
											className={
												event?.attr?.status === "done"
													? "done task_actions"
													: "task_actions"
											}
										>
											<CheckCircleOutlinedIcon />
											<span>
												{event?.attr?.status === "done"
													? t("TASK.STATUS.done")
													: t("TASK.ACTIONS.check_as_done")}
											</span>
										</Button>
									)}

									{(event.type === "picture" || event.type === "file") && (
										<div className="file_actions">
											<div className="row">
												<div className="col">
													<FormControl
														className="select_field"
														variant="standard"
														sx={{ m: 1, minWidth: 120 }}
													>
														<Select
															classNamePrefix="react-select"
															aria-label="Nature de l'évènement"
															placeholder="Nature de l'évènement"
															options={fileTypes}
															value={
																selectEventFileType.value !== ""
																	? selectEventFileType
																	: null
															}
															name="fileTypes"
															onChange={(element: any) => {
																handleChangeFileType(element);
															}}
															theme={(theme) => ({
																...theme,
																borderRadius: 0,
																colors: {
																	...theme.colors,
																	primary25: "#e238324f",
																	primary50: "#e2383287",
																	primary: COLORS[0],
																},
															})}
														/>
													</FormControl>
												</div>
												<div className="col">
													{ (selectEventFileType?.value && selectEventFileType.value !== "other") && (
														<FormControl
															className="select_field"
															variant="standard"
															sx={{ m: 1, minWidth: 120 }}
														>
															<TextField
																type="numeric"
																icon="euro"
																value={amount >= 0 ? amount : 0}
																onUpdate={(element: string) => {
																	const newEvent = { ...event };
																	const newAttributes = {
																		...newEvent.attr,
																		amount: parseFloat(element),
																	};
																	newEvent.attr = newAttributes;
																	setHasChanged(true);
																	dispatch(setCurrentEvent(newEvent));
																}}
															/>
														</FormControl>
													)}
												</div>
											</div>
										</div>
									)}

									{event.type === "date" && (
										<div className="date_actions">
											<div className="row">
												<div className="col">
													<div className="item">
														<div className="action">
															{event.attr.date_status === "confirmed" ? (
																<div className="status confirmed">
																	<div className="label">
																		<EventAvailableIcon />
																		<span className="">Confirmé</span>
																	</div>

																	<Button
																		variant="text"
																		onClick={handleConfirmCancelDate}
																		color="error"
																		disableElevation
																		style={{ color: "#fff" }}
																	>
																		Annuler
																	</Button>
																</div>
															) : event.attr.date_status === "cancelled" ? (
																<div className="status cancelled">
																	<div className="label">
																		<EventBusyIcon />
																		<span>Annulé</span>
																	</div>
																</div>
															) : event.attr.date_status === "pending" ? (
																<div className="status">
																	<div className="label">
																		<ForwardToInboxIcon />
																		<span>Invitation envoyée</span>
																	</div>
																</div>
															) : (
																<div className="status">
																	<Button
																		variant="contained"
																		onClick={() => setConfirmDateOpen(true)}
																		color="success"
																		style={{ color: "#fff" }}
																		disableElevation
																	>
																		Envoyer la demande de confirmation
																	</Button>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="details_actions">
								<Button
									variant="contained"
									onClick={() => setShareOpen(true)}
									className="share_action"
									disableElevation
								>
									<ShareIcon />
									<span>{t("EVENT.ACTIONS.share_with_contractor")}</span>
								</Button>
								<Button
									className="event_actions_trigger"
									onClick={() => {
										setShowNav(!showNav);
									}}
								>
									<MoreHorizIcon />
								</Button>
								<Button
									className={"closeButton"}
									onClick={() => {
										closeDetails();
									}}
								>
									<StartIcon />
								</Button>
								{showNav && (
									<Paper
										className="event_actions_menu"
										sx={{ width: 250, maxWidth: "100%" }}
									>
										<MenuList>
											{isEditing ? (
												<MenuItem
													onClick={() => {
														dispatch(saveEvent({}));
														setIsEditing(false);
													}}
												>
													<ListItemIcon>
														<SaveIcon fontSize="small" />
													</ListItemIcon>
													<ListItemText>
														{t("GLOBAL.ACTIONS.save")}
													</ListItemText>
												</MenuItem>
											) : (
												<MenuItem
													onClick={() => {
														setIsEditing(true);
														setShowNav(false);
													}}
												>
													<ListItemIcon>
														<EditRoundedIcon fontSize="small" />
													</ListItemIcon>
													<ListItemText>
														{t("GLOBAL.ACTIONS.edit")}
													</ListItemText>
												</MenuItem>
											)}

											<MenuItem onClick={requestDuplicate}>
												<ListItemIcon>
													<ContentCopy fontSize="small" />
												</ListItemIcon>
												<ListItemText>
													{t("GLOBAL.ACTIONS.duplicate")}
												</ListItemText>
											</MenuItem>
											{(event.type === "task" || event.type === "mail") && (
												<>
													<Divider />

													<MenuItem onClick={() => requestTransform("text")}>
														<ListItemIcon>
															<ChatBubbleIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText>{t("GLOBAL.ACTIONS.transform")} {t("TYPE.text")}</ListItemText>
													</MenuItem>
												</>
											)}
											{(event.type === "text" || event.type === "mail") && (
												<>
													<Divider />

													<MenuItem onClick={() => requestTransform("task")}>
														<ListItemIcon>
															<AssignmentTurnedInIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText>{t("GLOBAL.ACTIONS.transform")} {t("TYPE.task")}</ListItemText>
													</MenuItem>
												</>
											)}

											<Divider />
											<MenuItem
												className="delete_action"
												onClick={() => setConfirmOpen(true)}
											>
												<ListItemIcon>
													<DeleteForeverRoundedIcon fontSize="small" />
												</ListItemIcon>
												<ListItemText>
													{t("GLOBAL.ACTIONS.delete")}
												</ListItemText>
											</MenuItem>
										</MenuList>
									</Paper>
								)}
							</div>
						</div>

						<div className={"infos"}>
							<input
								className={
									isEditing || editTitle ? "edit input_title" : " input_title"
								}
								ref={eventTitleInput}
								type="text"
								value={event.title ? event.title : ""}
								placeholder="Aucun titre"
								onClick={() => {
									setEditTitle(true);
								}}
								onChange={(element: any) => {
									const newEvent = { ...event, title: element.target.value };
									setHasChanged(true);
									console.log(newEvent);
									dispatch(setCurrentEvent(newEvent));
								}}
								onBlur={() => {
									setEditTitle(false);
								}}
							/>
							<div className="event_general_details">
								<div className="row">
									
										{!editContent && !isEditing && (
											<div
												className="description"
												onClick={() => setEditContent(true)}
												dangerouslySetInnerHTML={handleHtml(
													editorContent !== "<p></p>" && editorContent !== ""
														? editorContent
														: "<span style='color:grey;font-style:italic'>Aucune description</span>"
												)}
											></div>
										)}
										{(editContent || isEditing) && (
											<Editor
												type="min"
												placeholder={"Description"}
												content={event.content}
												clear={clearContent}
												onEdit={(value: string) => {
													const newEvent = { ...event, content: value };
													setHasChanged(true);
													dispatch(setCurrentEvent(newEvent));
												}}
											/>
										)}
								</div>
								<div className="row">

									<div className="col">
										<div
											className="item contractors"
											onClick={(element) => {
												!hasSomeParentTheClass(element.target, [
													"select_field",
													"react-select__control",
												]) && setEditContractors(!editContractors);
											}}
										>
											<strong>Intervenant(s)</strong>
											<div className="action ">
												{!editContractors && !isEditing && (
													<>
														{contributorsOptions.length === 0 ? (
															<div className="empty_contributors">
																<span>{t("EVENT.LABELS.no_contributor")}</span>
																<Link
																	to={"/project/" + project.id + "/contractors"}
																>
																	<Button
																		size="small"
																		variant="contained"
																		onClick={requestUpdate}
																	>
																		<AddIcon />{" "}
																		{t("EVENT.ACTIONS.add_contributor")}
																	</Button>
																</Link>
															</div>
														) : (
															<>
																{event.attr?.contractors &&
																event.attr?.contractors.length > 0 ? (
																	<Avatars items={avatarsContractors} />
																) : (
																	<Button size="small" variant="outlined">
																		+ Ajouter un intervenant
																	</Button>
																)}
															</>
														)}
													</>
												)}
												{(editContractors || isEditing) && (
													<FormControl
														className="select_field"
														sx={{ m: 1, minWidth: 120 }}
													>
														<Select
															classNamePrefix="react-select"
															aria-label="Selectionner un ou plusieurs intervenants"
															placeholder="Selectionner un ou plusieurs intervenants"
															options={contributorsOptions}
															name="Contributors"
															isMulti={true}
															isSearchable={true}
															isClearable={false}
															value={selectedContributors}
															onChange={handleChangeContributors}
															onBlur={() => setEditContractors(false)}
															theme={(theme) => ({
																...theme,
																borderRadius: 0,
																colors: {
																	...theme.colors,
																	primary25: "#e238324f",
																	primary50: "#e2383287",
																	primary: COLORS[0],
																},
															})}
														/>
													</FormControl>
												)}
											</div>
										</div>
									</div>
									<div className="col">
										<div
											className="item tags"
											onClick={(element) => {
												!hasSomeParentTheClass(element.target, [
													"select_tags",
													"rti--tag",
												]) && setEditTags(!editTags);

												if (!editTags) {
													setTimeout(() => {
														const tagInput: HTMLElement | null =
															document.querySelector(
																".select_tags input"
															) as HTMLElement;
														tagInput?.focus();
													}, 10);
												}
											}}
										>
											<strong>Étiquettes </strong>

											<div className="action">
												{!editTags && !isEditing && (
													<>
														{tags && tags.length > 0 ? (
															<Tags tags={tags} />
														) : (
															<Button variant="outlined" size="small">
																+ Ajouter un tag
															</Button>
														)}
													</>
												)}

												{(editTags || isEditing) && (
													<div className="select_tags">
														<TagsInput
															value={tags}
															onChange={(newTags: any) => {
																const newEvent = { ...event };
																const newAttributes = {
																	...newEvent.attr,
																	tags: newTags,
																};
																newEvent.attr = newAttributes;
																dispatch(setCurrentEvent(newEvent));
																setHasChanged(true);
															}}
															name="tags"
															placeHolder="Ex.: bois, peinture"
														/>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="row"></div>
							</div>

							<div className={"content " + event.type}>
								{event.type === "picture" && (
									<>
										{event.attr.files && event.attr.files.length > 0 ? (
											<Gallery pictures={event.attr.files} />
										) : (
											<span className={"empty"}>Aucune image envoyée</span>
										)}
									</>
								)}
								{event.type === "sound" && (
									<>
										{event.attr.files && event.attr.files.length > 0 ? (
											<SoundPlayer sounds={event.attr.files} />
										) : (
											<span className={"empty"}>{t("EMPTY.sound")}</span>
										)}
									</>
								)}
								{event.type === "video" && (
									<>
										{event.attr.files && event.attr.files.length > 0 ? (
											<VideoPlayer video={event.attr.files[0]} />
										) : (
											<span className={"empty"}>{t("EMPTY.sound")}</span>
										)}
									</>
								)}
								{event.type === "file" && (
									<>
										{event.attr.files && event.attr.files.length > 0 ? (
											<div className="fileList">
												<span>Fichiers envoyés</span>
												<br />
												{Array.isArray(event.attr.files) &&
													event.attr.files.map((file: any, index: number) => {
														return (
															<a
																download={file.name}
																href={file.url}
																key={index}
															>
																{file.name}
															</a>
														);
													})}
											</div>
										) : (
											<span className={"empty"}>{t("EMPTY.file")}</span>
										)}
									</>
								)}
								{event.type === "date" && (
									<DateEvent
										event={event}
										editing={isEditing}
										onEdit={(newEvent: any) => {
											setHasChanged(true);
											dispatch(setCurrentEvent(newEvent));
										}}
									/>
								)}
								{event.type === "task" && (
									<TaskEvent
										event={event}
										onEdit={(newEvent: any) => {
											setHasChanged(true);
											dispatch(setCurrentEvent(newEvent));
										}}
									/>
								)}
							</div>

							<Comments />
						</div>
					</>
				)}
			</motion.div>
		</motion.div>
	);
}

export default Details;
