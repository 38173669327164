/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
function LostPage() {
  const {t} = useTranslation();

  return (
    <div className="lost">
      <SentimentVeryDissatisfiedIcon className='lostIcon' />
      <span>404</span>
      <Link to="/dashboard">{t('GLOBAL.ACTIONS.lost_go_back')}</Link>
    </div>
  );
}

export default LostPage;
