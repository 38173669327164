export type PlanType = {
    id: number;
    name: string;
    storagelimit: number;
    projectlimit: number;
    userlimit: number;
    price: number;
};


export const defaultPlan: PlanType = {
    id: 1,
    name: "free",
    storagelimit: 100,
    projectlimit: 1,
    userlimit: 0,
    price: 0
}
