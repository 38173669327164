/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";

import { Link, useParams } from "react-router-dom";

function Tabs() {
  const { t } = useTranslation();
  const { id } = useParams();
  const current = id ? id : "settings";

  return (
    <ul className="tabs">
      <li>
        <Link
          to={"/account/settings"}
          className={
            current === "settings" || current === "account" ? "active" : ""
          }
        >
          {t("TABS.settings")}
        </Link>
      </li>
      <li>
        <Link
          to={"/account/subscription"}
          className={current === "subscription" ? "active" : ""}
        >
          {t("TABS.subscription")}
        </Link>
      </li>

      <li>
        <Link
          to={"/account/transactions"}
          className={current === "transactions" ? "active" : ""}
        >
          {t("TABS.transactions")}
        </Link>
      </li>
    </ul>
  );
}

export default Tabs;
