/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import config from "Config";

import {
	LoginSocialGoogle,
	LoginSocialFacebook,
	IResolveParams,
} from "reactjs-social-login";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";

/** Backgrounds */

import bg1 from "Assets/Images/backgrounds/1.jpg";
import bgTest from "Assets/Images/backgrounds/test.jpg";
import logo from "Assets/Images/logo/logo.png";
import logoAlt from "Assets/Images/logo/logo_red_white.png";


/** Form components */
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

/** Storage */
import { setItem, removeItem } from "Utils/Storage";

/** Redux */
import { useAppDispatch } from "Redux/hooks";
import { setError } from "Redux/Reducers/App/AppSlice";

/** Form components */
import TextField from "Components/Parts/TextField";

/** API */
import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

function Login() {
	const { id } = useParams();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [error, setLoginError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [tokenHasbeenCheched, setTokenHasbeenCheched] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState(bg1);

	const [errorMessage, setErrorMessage] = useState(
		t("ACCOUNT.FORM.ERRORS.field_check")
	);

	useEffect(() => {
		let backgroundImage = bg1;

		setBackgroundImage(backgroundImage);
	}, [id]);

	useEffect(() => {}, [tokenHasbeenCheched]);

	/** Attempt login */
	const attemptLogin = () => {
		setLoading(true);
		setLoginError(false);
		if (login === "" || password === "") {
			setLoginError(true);
			setLoading(false);
		} else {
			userApi.postLogin(login, password).then(
				(result: any) => {
					if (result.status === "ok") {
						setItem("token", result.token);
						removeItem("current_project");
						window.location.href = "/project";
						setLoginError(false);
						setLoading(false);
					} else {
						setErrorMessage(t(result.message));
						setLoginError(true);
						setLoading(false);
					}
				},
				(error) => {
					console.log(error);
					setLoginError(true);
					setLoading(false);
				}
			);
		}
	};

	const handleOauthLogin = (provider: string, token: any) => {
		setLoading(true);
		userApi.postCredentials(provider, token).then(
			(result: any) => {
				if (result.status === "success" && result.data !== null) {
					setItem("token", result.data);
					removeItem("current_project");
					window.location.href = "/project";
					setLoginError(false);
					setLoading(false);
				} else {

          console.log(t(result.message));
					setErrorMessage(t(result.message));
					setLoginError(true);
					setLoading(false);
				}
			},
			(error) => {
				setLoginError(true);
				setLoading(false);
			}
		);
	};

	/** Placeholders */

	const placeholder = {
		login: t("ACCOUNT.FORM.LABEL.login"),
		password: t("ACCOUNT.FORM.LABEL.password"),
	};

	return (
		<div className="login_container">
			{pageLoading && (
				<div className="loader">
					<CircularProgress />
				</div>
			)}

			<div className="form">
				<div className="content">
					<div className="switch_account_action">
						<strong>{t("ACCOUNT.LABELS.connect")}</strong>
					</div>

					<div className="oauth_connect">
						<span> {t("ACCOUNT.LABELS.connect_with")}</span>
						<LoginSocialFacebook
							className="login_button facebook"
							appId={config.facebookAppId || ""}
							fieldsProfile={"id,first_name,last_name,picture,,email"}
							redirect_uri={"https://www.sayto.be"}
							onResolve={({ provider, data }: IResolveParams) => {
								handleOauthLogin("facebook", data?.accessToken);
							}}
							auth_type="rerequest"
							onReject={(err) => {
								console.log(err);
							}}
						>
							<FacebookRoundedIcon />
						</LoginSocialFacebook>
						<LoginSocialGoogle
							className="login_button google"
							client_id={config.googleClientId || ""}
							redirect_uri={""}
							scope="openid profile email"
							discoveryDocs="claims_supported"
							access_type="offline"
							onResolve={({ provider, data }: IResolveParams) => {
								handleOauthLogin(provider, data?.access_token);
							}}
							onReject={(err) => {
								console.log(err);
							}}
						>
							<GoogleIcon />
						</LoginSocialGoogle>
					</div>

					<span className="connect_with">{t("GLOBAL.LABELS.or")}</span>
					<div className="fields">
						<TextField
							value={login}
							placeholder={placeholder.login}
							onUpdate={(newLogin: string) => setLogin(newLogin)}
						/>
					</div>

					<div className="fields">
						<TextField
							value={password}
							placeholder={placeholder.password}
							onUpdate={(newLogin: string) => setPassword(newLogin)}
							showPasswordIcon={true}
							type="password"
							onKeyPress={(e: any) => {
								if (e.keyCode === 13) {
									attemptLogin();
								}
							}}
						/>
					</div>
					<div className="actions">
						<FormControl className="fields" sx={{ width: "80%" }}>
							<Link to="/account/recover">
								{t("ACCOUNT.LABELS.lost_password")}
							</Link>
							<Button
								disabled={loading}
								variant="contained"
								onClick={attemptLogin}
							>
								{loading ? (
									<CircularProgress />
								) : (
									<>{t("ACCOUNT.ACTIONS.login")}</>
								)}
							</Button>
						</FormControl>
						{error && (
							<Alert severity="error" className="error">
								{errorMessage}
							</Alert>
						)}
					</div>

					<div className="switch_account_action">
						<span>{t("ACCOUNT.LABELS.not_registered_yet")}</span>
						<Link to={"/register/" + (id !== undefined ? id : "")}>
							{t("ACCOUNT.ACTIONS.create_an_account")}
						</Link>
					</div>
				</div>
			</div>

			<div
				className="presentation"
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			>
				{/*<img src={logo} alt="Sayto" />*/}
			</div>
		</div>
	);
}

export default Login;
