import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

import { removeItem } from "Utils/Storage";

const Account = () => {
	const { t } = useTranslation();
	const user = useAppSelector(getUser);

	const logout = () => {
		removeItem("token");
		removeItem("current_project");
		window.location.href = "/login";
	};

	return (
		<Link to="/account" className={"userConnected"}>
			{user.picture === "" && <span>SA</span>}
			{user.picture !== "" && (
				<Tooltip title={user.name + " " + user.firstname}>
					<img src={user.picture} alt="user thumbnail" />
				</Tooltip>
			)}
		</Link>
	);
};

export default Account;
