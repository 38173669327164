/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import config from "Config";

import {
	LoginSocialGoogle,
	LoginSocialFacebook,
	IResolveParams,
} from "reactjs-social-login";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";

/** Backgrounds */

import bg1 from "Assets/Images/backgrounds/1.jpg";

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

/** Storage */
import { setItem } from "Utils/Storage";

/** Form components */
import TextField from "Components/Parts/TextField";

/** API */
import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

function Register() {
	const { id } = useParams();
	const { t } = useTranslation();
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [registerIsDone, setRegisterIsDone] = useState(false);

	const [acceptCGU, setAcceptCGU] = useState(false);
	const [CGUError, setCGUError] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState(bg1);
	const [errorMessage, setErrorMessage] = useState(
		t("ACCOUNT.FORM.ERRORS.field_check")
	);

	useEffect(() => {
		let backgroundImage = bg1;

		setBackgroundImage(backgroundImage);
	}, [id]);

	useEffect(() => {
		userApi.checkToken().then(
			(result: any) => {
				if (result && result.status === "ok") {
					window.location.href = "/project";
				} else {
					setPageLoading(false);
				}
			},
			(error) => {
				setPageLoading(false);
			}
		);
	}, []);
	const attemptRegister = () => {
		setLoading(true);
		setError(false);
		if (acceptCGU) {
			if (login === "" || password === "") {
				setError(true);
				setLoading(false);
			} else {
				userApi.postRegister(login, password, "BE").then(
					(result: any) => {
						if (result.status === "ok") {
							setRegisterIsDone(true);
							setError(false);
							setLoading(false);
						} else {
							setError(true);
							setErrorMessage(t(result.message));
							setLoading(false);
						}
					},
					(error) => {
						console.log(error);
						setError(true);
						setLoading(false);
					}
				);
			}
		} else {
			setCGUError(true);
			setLoading(false);
		}
	};
	const handleOauthLogin = (provider: string, token: any) => {
		setLoading(true);
		userApi.postCredentials(provider, token).then(
			(result: any) => {
				if (result.status === "success" && result.data !== null) {
					setItem("token", result.data);
					window.location.href = "/dashboard";
					setError(false);
					setLoading(false);
				} else {
					setError(true);
					setErrorMessage(t(result.message));
					setLoading(false);
				}
			},
			(error) => {
				setError(true);
				setLoading(false);
			}
		);
	};

	/** Placeholders */

	const placeholder = {
		login: t("ACCOUNT.FORM.LABEL.login"),
		password: t("ACCOUNT.FORM.LABEL.password"),
	};

	return (
		<div className="login_container">
			{pageLoading && (
				<div className="loader">
					<CircularProgress />
				</div>
			)}

			<div className="form">
				<div className="content">
					{registerIsDone ? (
						<>
							<div className="switch_account_action success">
								<CheckCircleOutlineIcon />
								<strong>{t("ACCOUNT.FORM.SUCCESS.account_created")}</strong>
								<span>
									{t("ACCOUNT.FORM.SUCCESS.email_sent")} {login}
								</span>
							</div>
						</>
					) : (
						<>
							<div className="switch_account_action">
								<strong>{t("ACCOUNT.LABELS.welcome")}</strong>
								<span>{t("ACCOUNT.LABELS.registered_yet")}</span>
								<Link to={"/login/" + (id !== undefined ? id : "")}>
									{t("ACCOUNT.ACTIONS.login")}
								</Link>
							</div>
							<div className="oauth_connect">
								<span> {t("ACCOUNT.LABELS.register_with")}</span>
								<LoginSocialFacebook
									className="login_button facebook"
									appId={config.facebookAppId || ""}
									fieldsProfile={"id,first_name,last_name,picture,,email"}
									redirect_uri={"https://www.sayto.be"}
									onResolve={({ provider, data }: IResolveParams) => {
										handleOauthLogin("facebook", data?.accessToken);
									}}
									auth_type="rerequest"
									onReject={(err) => {
										console.log(err);
									}}
								>
									<FacebookRoundedIcon />
								</LoginSocialFacebook>
								<LoginSocialGoogle
									className="login_button google"
									client_id={config.googleClientId || ""}
									redirect_uri={""}
									scope="openid profile email"
									discoveryDocs="claims_supported"
									access_type="offline"
									onResolve={({ provider, data }: IResolveParams) => {
										handleOauthLogin(provider, data?.access_token);
									}}
									onReject={(err) => {
										console.log(err);
									}}
								>
									<GoogleIcon />
								</LoginSocialGoogle>
							</div>
							<div className="fields">
								<TextField
									value={login}
									placeholder={placeholder.login}
									onUpdate={(newLogin: string) => setLogin(newLogin)}
								/>
							</div>
							<div className="fields">
								<TextField
									value={password}
									placeholder={placeholder.password}
									onUpdate={(newPassword: string) => setPassword(newPassword)}
									showPasswordIcon={true}
									type="password"
									onKeyPress={(e: any) => {
										if (e.keyCode === 13) {
											attemptRegister();
										}
									}}
								/>
								<FormControl component="fieldset" className="cgu_accept">
									<FormControlLabel
										value="cgu_accept"
										control={<Checkbox />}
										label={t("ACCOUNT.FORM.LABEL.accept_cgu")}
										labelPlacement="end"
										onChange={(e: any) => {
											setAcceptCGU(!acceptCGU);
										}}
									/>
									<a href="https://sayto.be/cgu" target="_blank">
										{t("GLOBAL.LABELS.cgu")}
									</a>
								</FormControl>
							</div>
							<div className="actions">
								<FormControl className="fields" sx={{ width: "80%" }}>
									<Button variant="contained" onClick={attemptRegister}>
										{loading ? (
											<CircularProgress />
										) : (
											<>{t("ACCOUNT.ACTIONS.register")}</>
										)}
									</Button>
								</FormControl>
								{error && (
									<Alert severity="error" className="error">
										{t("ACCOUNT.FORM.ERRORS.field_check")}
									</Alert>
								)}
								{CGUError && (
									<Alert severity="error" className="error">
										{t("ACCOUNT.FORM.ERRORS.accept_cgu")}
									</Alert>
								)}
							</div>
						</>
					)}
				</div>
			</div>
			<div
				className="presentation"
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			></div>
		</div>
	);
}

export default Register;
