/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import Skeleton from "@mui/material/Skeleton";

function SkeletonDetails() {
  return (
    <>
      <div className="details_header">
        <div className="details_infos">
          <Skeleton
            className=""
            width={120}
            height={40}
            style={{ marginRight: 25 }}
          />
        </div>
      </div>
      <div className={"infos"}>
        <Skeleton
          className=""
          width={800}
          height={40}
          style={{ marginRight: 25 }}
        />
        <Skeleton variant="rectangular" width={400} height={150} />
      </div>
    </>
  );
}

export default SkeletonDetails;
