/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Details from "../Events/Details";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import Filters from "Components/Pages/Project/Views/ActivitiesFilters";
import Empty from "Components/Parts/Empty";
import {
	convertStringToDate,
	convertStringToDatetime,
} from "Utils/DateFormatter";
import { formatFileSize } from "Utils/Locale";

/** API */
import { getEvents } from "Api/Controllers/EventController";
/** Types */
import { EventType } from "Types/Event";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";
import {
	getFilters,
	getAmounts,
	setFilters,
	setCurrentEvent,
	setIsOpen,
	clearEvents,
	getModalAction,
	getCurrentEvent,
	setModalAction,
	loadEvents,
} from "Redux/Reducers/Project/EventsSlice";

function Files() {
	const { t } = useTranslation();
	const project = useAppSelector(getProject);
	const currentFilters = useAppSelector(getFilters);
	const amounts = useAppSelector(getAmounts);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);
	const filters = useAppSelector(getFilters);
	const modalAction = useAppSelector(getModalAction);
	const currentEvent = useAppSelector(getCurrentEvent);

	const defaultFilters: any = { ...filters };

	const [filtersLoading, setFiltersLoading] = useState(true);
	const [ActiveFilters, setActiveFilters] = useState(defaultFilters);
	const [events, setEvents] = useState([] as any);

	/** Functions */

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const newFilters = structuredClone(filters);

		if (scrollTop <= 5) {
			newFilters.page = newFilters.page ? newFilters.page - 1 : 1;
			dispatch(setFilters(newFilters));
			increaseEvents(false);
		}
		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;
			const currentPage = filters.page ? filters.page : 1;
			increaseEvents(true);
		}
	};
	const reloadEvents = (currentFilters: any) => {
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;
		activeFilters.page = 1;
		activeFilters.limit = 20;
		activeFilters.type = "file";
		dispatch(setFilters(activeFilters));
		getEvents(activeFilters).then(
			(returnDatas: any) => {
				if (returnDatas?.status === "success") {
					setEvents(returnDatas?.data.events);
				}
			},
			(error: any) => {
				console.log("error", error);
			}
		);
	};

	const increaseEvents = (add: boolean) => {
		const activeFilters = structuredClone(filters);
		if (add) {
			activeFilters.page = activeFilters.page ? activeFilters.page + 1 : 2;
		} else {
			activeFilters.page = activeFilters.page ? activeFilters.page - 1 : 1;
		}
		activeFilters.id_project = project.id;
		activeFilters.limit = 20;
		if (
			activeFilters.page > 1 &&
			(activeFilters.page - 1) * 20 <= amounts.events
		) {
			dispatch(setFilters(activeFilters));
			setLoading(true);
			getEvents(activeFilters).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						const newEvents = [...events, ...returnDatas?.data.events];
						setEvents(newEvents);
						setLoading(false);
					}
				},
				(error: any) => {
					console.log("error", error);
				}
			);
		}
	};

	/** Hooks */
	useEffect(() => {
		dispatch(clearEvents({}));
		setLoading(true);

		const filters = {
			id_project: project.id,
			type: "task",
			page: 1,
			limit: 20,
		};
		dispatch(setFilters(filters));
	}, [dispatch, project.id]);

	useEffect(() => {
		setLoading(false);
	}, [events]);

	useEffect(() => {
		setLoading(true);
		dispatch(setFilters(ActiveFilters));
		reloadEvents(ActiveFilters);
	}, [project.id, ActiveFilters]);

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			modalAction === "add" && reloadEvents(filters);

			const updatingEvents = { ...events };

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
			}

			if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
				}, 300);
			}

			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	return (
		<div className="tabContent files">
			<Details />
			<div className="filters">
				<h3 className="title">{t("EVENT.LABELS.list_files")}</h3>
				<Filters
					filtersParams={ActiveFilters}
					onFilter={(filters: any) => {
						setActiveFilters(filters);
					}}
				/>
			</div>

			{loading ? (
				<Empty empty_label={t("EVENT.LABELS.loading_files")} />
			) : (
				<>
					<div className={"listContainer"}>
						<div className="columns">
							<div className="item">
								<div className="size">
									<span>{t("LIST.event")}</span>
								</div>
								<div className="task">
									<span>{t("LIST.file")}</span>
								</div>
								<div className="due_date">
									<span>{t("LIST.date")}</span>
								</div>
								<div className="size">
									<span>{t("LIST.size")}</span>
								</div>
								<div className="actions">
									<span>{t("LIST.actions")}</span>
								</div>
							</div>
						</div>
						<div className="list" onScroll={handleScrollList}>
							{loading && (
								<div className="loading">
									<CircularProgress />
								</div>
							)}

							{events.map((event: any, index: number) => {
								return <FileLine event={event} key={index} index={index} />;
							})}
						</div>
					</div>
					{}
				</>
			)}
		</div>
	);
}
function FileLine(props: { event: any; index: number }) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { event, index } = props;

	const [showMore, setShowMore] = useState(false);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (event?.attr?.files) {
			setFiles(event.attr.files);
		}
	}, [event]);

	if (files && files.length > 0) {
		return (
			<>
				{files.map((file: any, index: number) => {
					if (!showMore && index > 4) return null;
					return (
						<div className="item file" key={index}>
							<div className="size">
								<strong
									className="open_details_action"
									onClick={() => {
										dispatch(setIsOpen(true));
										dispatch(setCurrentEvent(event));
									}}
								>
									{event.title}
								</strong>
							</div>
							<div className="task">
								<a
									className="open_file_action"
									href={file.url}
									download={true}
									target="_blank"
									rel="noreferrer"
								>
									{file.name}
								</a>
							</div>
							<div className="due_date">
								<span>{convertStringToDatetime(event.event_start)}</span>
							</div>
							<div className="size">
								<span className="filesize">{formatFileSize(event.size)}</span>
							</div>
							<div className="actions">
								<a href={file.url} target="_blank" rel="noreferrer">
									<Tooltip title="Télécharger le fichier">
										<DownloadRoundedIcon />
									</Tooltip>
								</a>
								<span onClick={() => {}}>
									<Tooltip title="Supprimer le fichier">
										<HighlightOffOutlinedIcon />
									</Tooltip>
								</span>
							</div>
						</div>
					);
				})}
			</>
		);
	} else {
		return null;
	}
}
function ActionRow(params: any) {
	const event = params.row;
	const attributes =
		typeof event.attr === "string" ? JSON.parse(event.attr) : event.attr;
	let file: { url: string; name: string } = {
		url: "",
		name: "",
	};

	if (attributes && attributes.files && attributes?.files.length > 0) {
		file.url = attributes.files[0].url;
		file.name = attributes.files[0].name;
	}

	if (file.url) {
		return (
			<a href={file.url} download={file.name}>
				<DownloadRoundedIcon />
			</a>
		);
	} else {
		return <span></span>;
	}
}

function NoRow() {
	const { t } = useTranslation();
	return <Empty empty_label={t("EVENT.LABELS.no_file")} />;
}
export default Files;
