/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";
/** Modules  */
import Notifications from "Components/Parts/Notifications";
import AccountIcon from "Components/Parts/Account";
import Logout from "Components/Parts/Logout";

import Tabs from "./Tabs";

/** Views */

import Generals from "./Views/Generals";
import Subscription from "./Views/Subscription";
import Transactions from "./Views/Transactions";
import MySubscription from "Components/Parts/MySubscription";

/** Images */
const icon = require("Assets/Images/clear/navigation/user_icon.png");

function Account() {
  const { t } = useTranslation();
  const { id } = useParams();
  const current = id ? id : "settings";

  const user = useAppSelector(getUser);


  return (
    <div className="Page account">
      <header>
        <div className="pageName">
          <div className="avatar">
            {user.picture && <img src={user.picture} alt="User avatar" />}
          </div>
          <div className="infos">
            <strong>{user.email}</strong>
            <span className="plan">Free</span>
          </div>
        </div> 

        <MySubscription />
        <Logout />
        <div style={{ width: 20 }} />
        <AccountIcon />
      </header>

      <div className="infos">
        <Tabs />
      </div>

      <div className="content">
        {current === "settings" && <Generals />}
        {current === "subscription" && <Subscription />}
        {current === "transactions" && <Transactions />}
      </div>
    </div>
  );
}

export default Account;
