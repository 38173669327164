/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import "dayjs/locale/fr";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

/** Enums */
import { statusEnum, priorityEnum } from "Utils/enums/Tasks";
import COLORS from "Utils/colors";
/** Types */
import { EventType } from "Types/Event";

function AddTask(props: { event: EventType; onAdd: Function }) {
  const { event, onAdd } = props;

  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState(statusEnum[1]);
  const [selectedPriority, setSelectedPriority] = useState(priorityEnum[1]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startDateFormatted, setStartDateFormatted] = useState("");
  const [endDateFormatted, setEndDateFormatted] = useState("");

  const sourceStatus = statusEnum.filter((status: any, index: number) => {
    status.label = t(status.label);
    return status;
  });
  const sourcePriority = priorityEnum.filter((priority: any, index: number) => {
    priority.label = t(priority.label);
    return priority;
  });

  useEffect(() => {
    const newEvent = { ...event };
    newEvent.event_start = startDateFormatted;
    newEvent.event_end = endDateFormatted;
    newEvent.attr.status = selectedStatus?.value;
    newEvent.attr.priority = selectedPriority?.value;

    onAdd(newEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, selectedPriority, startDateFormatted, endDateFormatted]);

  return (
    <div>
      <div className="row">
        <div className="col">
          <label htmlFor="select_status">{t("TASK.status")}</label>
          {sourceStatus && (
            <Select
              classNamePrefix="react-select"
              id="select_status"
              className={"select_element " + selectedStatus?.value}
              options={sourceStatus}
              isClearable={false}
              defaultValue={selectedStatus}
              value={selectedStatus}
              onChange={(element: any) => setSelectedStatus(element)}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#e238324f",
                  primary50: "#e2383287",
                  primary: COLORS[0],
                },
              })}
            />
          )}
        </div>
        <div className="col">
          <label htmlFor="select_priority">{t("TASK.priority")}</label>
          <Select
            classNamePrefix="react-select"
            id="select_priority"
            className={"select_element " + selectedPriority?.value}
            options={sourcePriority}
            isClearable={false}
            defaultValue={selectedPriority}
            value={selectedPriority}
            onChange={(element: any) => setSelectedPriority(element)}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#e238324f",
                primary50: "#e2383287",
                primary: COLORS[0],
              },
            })}
          />
        </div>
      </div>
      <div className="row date">
        <div className="col">
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
            <label>{t("TASK.start_date")}</label>
            <DesktopDatePicker
              className="picker"
              inputFormat="d/M/y"
              disableMaskedInput={true}
              value={startDate ? startDate : new Date()}
              onChange={(date: any) => {
                const newDate =
                  date.c.month + "/" + date.c.day + "/" + date.c.year;

                const newDateFormatted =
                  date.c.year + "-" + date.c.month + "-" + date.c.day;

                setStartDate(newDate);
                setStartDateFormatted(newDateFormatted);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="col">
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
            <label>{t("TASK.end_date")}</label>
            <DesktopDatePicker
              className="picker"
              inputFormat="d/M/y"
              disableMaskedInput={true}
              value={endDate ? endDate : new Date()}
              onChange={(date: any) => {
                const newDate =
                  date.c.month + "/" + date.c.day + "/" + date.c.year;

                const newDateFormatted =
                  date.c.year + "-" + date.c.month + "-" + date.c.day;

                setEndDate(newDate);
                setEndDateFormatted(newDateFormatted);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
}

export default AddTask;
