/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import {
	open,
	setPictures,
	setVideo,
	getStatus,
} from "Redux/Reducers/Lightbox/LightboxSlice";

function VideoPlayer(props: any) {
	const { video } = props;
	const status = useAppSelector(getStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setVideo(video));
		console.log("set Pictures from video");
		dispatch(setPictures([] as any));
	}, [dispatch, video, status]);

	const playerStyle = {
		backgroundColor: "#EEE",
		cursor: "pointer",
		borderRadius: "5px",
	};
	return (
		<>
			<ReactPlayer
				url={video.url}
				width={200}
				height={100}
				style={playerStyle}
				onClick={() => {
					dispatch(open());
				}}
			/>
		</>
	);
}

export default VideoPlayer;
