import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

/** Form components */
import TextField from "Components/Parts/TextField";

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

/** Editor */
import Editor from "Components/Parts/Editor";

/** Icons */
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

/** Types */
import { defaultProject } from "Types/Project";

/** Redux */
import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

/** API */
import ProjectApi from "Api/Models/Project";

const projectApi = new ProjectApi();

function AddProject(props: { open: boolean; onClose: Function }) {
  const { open, onClose } = props;
  const navigate = useNavigate();

  const currentUser = useAppSelector(getUser);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(defaultProject);

  const postProject = () => {
    setLoading(true);

    if (!project.name) {
      setError(true);
      setLoading(false);
    } else {
      const now = new Date();
      now.setHours(now.getHours() + 1);

      project.start_date = now.toISOString().slice(0, 19).replace("T", " ");

      if (currentUser.id) {
        project.id_user = currentUser.id;
      }

      projectApi.post(project).then(
        (result: any) => {
          if (result.id) {
            navigate("/project/" + result.id);
            setProject(defaultProject);
          }
          setLoading(false);
        },
        (error) => {
          setError(true);
          setLoading(false);
        }
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  const updateDescription = (description: string) => {
    setProject({
      ...project,
      description: description,
    });
  };

  useEffect(() => {}, []);

  return (
    <div className={open ? "modal add_project open" : "modal add_project"}>
      <div className="overlay" onClick={handleClose}></div>
      <div className="content">
        <div className="title">
          <button className="closeModal" onClick={handleClose}>
            <CloseIcon />
          </button>
          <span>Ajouter un </span>
          <strong>Projet</strong>
        </div>
        <div className="row">
          <div className="col md8">
            <TextField
              placeholder={"Nom de votre projet"}
              value={project.name}
              onUpdate={(element: string) => {
                setProject({ ...project, name: element });
              }}
            />
          </div>
          <div className="col md4">
            <TextField
              placeholder={"Budget"}
              type="numeric"
              icon="euro"
              value={project.budget}
              onUpdate={(element: string) => {
                setProject({
                  ...project,
                  budget: parseFloat(element) ? parseFloat(element) : 0,
                });
              }}
            />
          </div>
        </div>

        <div className="sep row">
          <div className="col">
            <TextField
              placeholder={"Addresse, numéro de rue"}
              value={project.address}
              onUpdate={(element: string) => {
                setProject({ ...project, address: element });
              }}
            />
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <TextField
                  placeholder={"Code postal"}
                  value={project.zip}
                  onUpdate={(element: string) => {
                    setProject({ ...project, zip: element });
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  placeholder={"Ville"}
                  value={project.city}
                  onUpdate={(element: string) => {
                    setProject({ ...project, city: element });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Editor
          title={"Description"}
          content={project.description}
          onEdit={updateDescription}
          placeholder={"Ajouter une description..."}
        />
        {error && (
          <Alert severity="error">
            Attention, Veuillez vérifier les informations avant de continuer.
          </Alert>
        )}
        <Button
          className="action_button"
          variant="contained"
          onClick={postProject}
        >
          {loading ? <CircularProgress /> : <>Créer le projet</>}
        </Button>
      </div>
    </div>
  );
}

export default AddProject;
