import Config from "Config";

const API_KEY = Config.googleApi;

export const getPosition = async (address: string) => {

    if (!address) {
        return Promise.reject(new Error("Provided address is invalid"));
    }
    const endpoint = "https://maps.googleapis.com/maps/api/geocode/json";

    const response = await fetch(`${endpoint}?address=${encodeURIComponent(address)}&key=${API_KEY}`);
    const data = await response.json();

    if (data.status === "OK") {
        return {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng
        }

    } else {
        return { lat: false, lng: false }
    };


}

export const findByPlace = async(search:string) => {

    if (!search) {
        return Promise.reject(new Error("Provided search is invalid"));
    }

    const endpoint = "https://maps.googleapis.com/maps/api/place/findplacefromtext/json";
    const response = await fetch(`${endpoint}?inputtype=textquery&fields=Basic&input=${encodeURIComponent(search)}&key=${API_KEY}`);
    const data = await response.json();

    if (data.status === "OK") {
        return {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng
        }

    } else {
        return { lat: false, lng: false }
    };


}



