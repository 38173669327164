/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SmartToyIcon from '@mui/icons-material/SmartToy';

function LostPage() {
  const {t} = useTranslation();

  return (
    <div className="lost">
      <SmartToyIcon />
      <span>Uh oh !</span>
      <strong>Une erreur inattendue est survenue.</strong>
      <br />
      <span className="message">
        Si le problème persiste, veuillez contacter le support.
      </span>
      <Link to="/dashboard">{t('GLOBAL.ACTIONS.lost_go_back')}</Link>
    </div>
  );
}

export default LostPage;
