/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
  getProject,
  setProject,
  getProjectLoading,
} from "Redux/Reducers/Project/ProjectSlice";

import ProjectLoader from "Utils/Loaders/ProjectLoader";
/** Components */
import EditProject from "./EditProject";

import Tabs from "./Tabs";

import Activities from "./Views/Activities";
import Calendar from "./Views/Calendar";
import Description from "./Views/Description";
import Contributors from "./Views/Contributors";
import Files from "./Views/Files";
import Tasks from "./Views/Tasks";
import Budget from "./Views/Budget";
import Medias from "./Views/Medias";

import AddEvent from "./Events/AddEvent";

/** Modules  */
import Notifications from "Components/Parts/Notifications";
import AccountIcon from "Components/Parts/Account";
import Logout from "Components/Parts/Logout";

/** utils */
import { setItem, getItem } from "Utils/Storage";

/** Skeletons */
import SkeletonList from "./Skeletons/List";
import SkeletonHeader from "./Skeletons/Header";
import MySubscription from "Components/Parts/MySubscription";

function Project() {
  const { t } = useTranslation();
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const project = useAppSelector(getProject);
  const loading = useAppSelector(getProjectLoading);

  const [openEdit, setOpenEdit] = useState(false);
  const [error, setError] = useState(false);

  if (!id) {
    const currentId = getItem("current_project");
    if (currentId) {
      navigate("/project/" + currentId);
    }
  }

  const handleEdit = () => {
    setOpenEdit(true);
  };

  return (
    <>
      <ProjectLoader
        onLoad={(success: boolean) => !success && setError(true)}
      />

      {error ? (
        <div className="Project">
          <header></header>
          <div className="content">
            <span className="emptyMessage">
              {t("PROJECT.project_not_found")}
            </span>
          </div>
        </div>
      ) : (
        <div className="Project">
          <header>
            {loading ? (
              <SkeletonHeader />
            ) : (
              <>
                {project.id > 0 ? (
                  <>
                    <EditProject
                      project={project}
                      open={openEdit}
                      onClose={() => {
                        setOpenEdit(false);
                      }}
                      onSave={(projectEdit: any) => {
                        dispatch(setProject(projectEdit));
                      }}
                    />
                    <div className="projectName">
                      <div className="thumb">
                        {project.datas.name.substr(0, 1)}
                      </div>
                      <strong>{project.datas.name}</strong>
                      <Button onClick={handleEdit} color="info">
                        <EditRoundedIcon />
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="projectName"></div>
                )}
              </>
            )}

            {!loading && <MySubscription />}
            {!loading && <Logout />}
            {!loading && <AccountIcon />}
          </header>

          <div className="infos">
            <Tabs />
          </div>

          <div className="content">
            {(tab === "" ||
              tab === undefined ||
              tab === "project" ||
              tab === "activities") && (
              <>{loading ? <SkeletonList /> : <Activities />}</>
            )}
            {tab === "calendar" && (
              <>{loading ? <SkeletonList /> : <Calendar />}</>
            )}
            {tab === "description" && <Description />}
            {tab === "contractors" && <Contributors />}
            {tab === "tasks" && <Tasks />}
            {tab === "medias" && <Medias />}
            {tab === "files" && <Files />}
            {tab === "budget" && <Budget />}

            <AddEvent />
          </div>
        </div>
      )}
    </>
  );
}

export default Project;
