/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { BlockPicker } from "react-color";
import FormControl from "@mui/material/FormControl";

import { search } from "Api/Controllers/ContractorController";

import COLORS from "Utils/colors";
import TextField from "Components/Parts/TextField";
import Confirm from "Components/Parts/Confirm";

import { Button, Alert } from "@mui/material";

/** Icons */
import CloseIcon from "@mui/icons-material/Close";

/** Redux  */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
	getProject,
	getContractors,
	setContractors,
	setProject,
} from "Redux/Reducers/Project/ProjectSlice";

import { getAppSettings, setError } from "Redux/Reducers/App/AppSlice";
import { getProfessions } from "Redux/Reducers/App/CategorySlice";

import { postTips } from "Api/Controllers/CategoriesController";

import { selectDatasFormatter } from "Utils/SelectFormatter";

import { post } from "Api/Controllers/ContractorController";

const defaultContractor: any = {};

function AddContractor() {
	const { id, param } = useParams();
	const navigate = useNavigate();

	const [showModal, setShowModal] = useState(param === "add" ? true : false);
	const modalControl = useAnimation();
	const dispatch = useAppDispatch();
	const settings = useAppSelector(getAppSettings);
	const project = useAppSelector(getProject);
	const contractors = useAppSelector(getContractors);
	const professions = useAppSelector(getProfessions);
	const { t } = useTranslation();

	const [searchContractor, setSearchContractor] = useState("");
	const [contractorsFound, setContractorsFound] = useState([]);
	const [selectedContractor, setSelectedContractor] =
		useState(defaultContractor);
	const [showManual, setShowManual] = useState(true);
	const [newContributorFunction, setNewContributorFunction] = useState({
		value: -1,
		label: "",
		key: "",
	});

	const [roles, setRoles] = useState([{ value: -1, label: "" }]);
	const [error, setCreationError] = useState(false);
	const [currentColor, setCurrentColor] = useState(COLORS[0]);
	const [showPicker, setShowPicker] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const placeholders = {
		title_confirm: t("PROJECT.CONTRIBUTORS.LABELS.title_confirm_tips"),
		content_confirm: t("PROJECT.CONTRIBUTORS.LABELS.content_confirm_tips"),
		cancel_task_creation: t("PROJECT.CONTRIBUTORS.LABELS.cancel_task_creation"),
		confirm_task_creation: t(
			"PROJECT.CONTRIBUTORS.LABELS.confirm_task_creation"
		),
	};

	const closeAndClear = () => {
		setShowModal(false);
		setSelectedContractor({});
		setShowManual(false);
		setContractorsFound([]);
		setNewContributorFunction({
			value: 0,
			label: "",
			key: "",
		});
		setConfirmOpen(false);
	};

	const findContractor = (query: string) => {
		if (query !== "") {
			search(query).then((result: any) => {
				if (result.status === "success") {
					setContractorsFound(result.data);
				}
			});
		}
	};

	const saveContractor = () => {
		if (
			!selectedContractor ||
			!selectedContractor.company ||
			!selectedContractor.email ||
			selectedContractor.company === "" ||
			selectedContractor.email === ""
		) {
			setCreationError(true);
			setTimeout(() => setCreationError(false), 3000);
		} else {
			const newContractorAttribute = {
				...selectedContractor,
				color: currentColor,
				function: newContributorFunction.key,
				function_id: newContributorFunction.value,
			};

			if (newContractorAttribute.id > 0) {
				linkContractor(newContractorAttribute);
			} else {
				post(selectedContractor).then(
					(result: any) => {
						if (result.status === "success") {
							newContractorAttribute.id = result.data.id;
							linkContractor(newContractorAttribute);
							closeAndClear();
						}
					},
					(error: any) => {
						console.log(error);
					}
				);
			}
		}
	};

	const linkContractor = (contractor: any) => {
		const professionItem = professions.filter((item: any) => {
			return item.id === newContributorFunction.value;
		})[0];
		const newContractors = Array.isArray(structuredClone(contractors))
			? structuredClone(contractors)
			: [];
		newContractors.push(contractor);
		dispatch(setContractors(newContractors));

		if (professionItem?.hastips === "1") {
			setConfirmOpen(true);
		} else {
			closeAndClear();
		}
	};
	useEffect(() => {}, [selectedContractor]);
	useEffect(() => {
		if (professions.length) {
			const TranslatedRoles = professions.map((item: any) => {
				if (item.name === "other") return null;
				return {
					...item,
					name: t("PROJECT.PROFESSIONS." + item.name),
					key: item.name,
				};
			});

			const formattedRoles = selectDatasFormatter(TranslatedRoles);

			formattedRoles.push({
				value: "",
				label: t("PROJECT.PROFESSIONS.other"),
				key: "other",
			});

			setRoles(formattedRoles);
			setNewContributorFunction(formattedRoles[0].key);
		}
	}, [professions]);

	return (
		<>
			<Confirm
				cancelButton={placeholders.cancel_task_creation}
				confirmButton={placeholders.confirm_task_creation}
				status={confirmOpen}
				onClose={() => {
					closeAndClear();
				}}
				onConfirm={() => {
					postTips({
						id_project: project.id,
						id_profession: newContributorFunction.value,
						lang: settings.lang,
					}).then(
						(result: any) => {
							setConfirmOpen(false);
							if (result.status === "success") {
								navigate("/project/" + id + "/tasks");
							} else {
								dispatch(setError({ type: "api_error", message: "api_error" }));
							}
						},
						(error) => {
							dispatch(setError({ type: "api_error", message: "api_error" }));
						}
					);
					closeAndClear();
				}}
				title={placeholders.title_confirm}
				content={
					placeholders.content_confirm +
					" " +
					newContributorFunction.label +
					" ?"
				}
			/>

			<div className="addContributor">
				<Button variant="contained" onClick={() => setShowModal(true)}>
					+ Ajouter un intervenant
				</Button>
			</div>
			<div className="contributor_modal">
				<motion.div
					animate={modalControl}
					className={
						showModal
							? "modal add contributor open"
							: "modal add contributorModal hidden"
					}
				>
					<div className="overlay" onClick={() => closeAndClear()}></div>
					<div className="content">
						<div className="title">
							<button className="closeModal" onClick={() => closeAndClear()}>
								<CloseIcon />
							</button>
							<span>Ajouter un </span>
							<strong>intervenant</strong>
						</div>

						{showManual && (
							<div>
								<div className="row">
									<div className="col md6">
										<TextField
											onUpdate={(value: string) => {
												if (value) {
													findContractor(selectedContractor?.company);
													setSearchContractor(selectedContractor?.company);
												}

												setSelectedContractor({
													...selectedContractor,
													company: value,
												});
											}}
											placeholder={"Nom de l'intervenant / de la société *"}
											value={selectedContractor?.company}
											onBlur={() => {
												setTimeout(() => {
													setContractorsFound([]);
												}, 200);
											}}
											onClick={() => {
												if (selectedContractor?.company.length > 2) {
													findContractor(selectedContractor?.company);
													setSearchContractor(selectedContractor?.company);
												}
											}}
										/>
										{contractorsFound.length > 0 &&
											selectedContractor.company.length > 2 && (
												<div className="contractorsFoundModal">
													{contractorsFound.map((contractor: any) => {
														return (
															<div
																className="contractor"
																onClick={() => {
																	setShowManual(true);
																	setSelectedContractor(contractor);
																}}
															>
																<div className="contractorInfos">
																	<div className="row">
																		<div className="contractorCompany">
																			{contractor.company}
																		</div>
																		<div className="contractorName">
																			{contractor.firstname} {contractor.name}
																		</div>
																		<div className="contractorEmail">
																			{contractor.email}
																		</div>
																	</div>
																	<div className="row">
																		<div className="contractorAddress">
																			{contractor.fulladdress}
																		</div>
																	</div>
																</div>
																<Button
																	variant="outlined"
																	onClick={() => {
																		setContractorsFound([]);
																		setSelectedContractor({
																			...selectedContractor,
																			...contractor,
																		});
																	}}
																>
																	Sélectionner
																</Button>
															</div>
														);
													})}
												</div>
											)}
									</div>
									<div className="col md6">
										<TextField
											placeholder={"Adresse e-mail *"}
											value={selectedContractor?.email}
											onUpdate={(element: string) => {
												setSelectedContractor({
													...selectedContractor,
													email: element,
												});
											}}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col md6">
										<TextField
											placeholder={"Prénom de la personne de contact"}
											value={selectedContractor?.firstname}
											onUpdate={(element: string) => {
												setSelectedContractor({
													...selectedContractor,
													firstname: element,
												});
											}}
										/>
									</div>
									<div className="col md6">
										<TextField
											placeholder={"Nom de la personne de contact"}
											value={selectedContractor?.name}
											onUpdate={(element: string) => {
												setSelectedContractor({
													...selectedContractor,
													name: element,
												});
											}}
										/>
									</div>
								</div>

								<div className="row">
									<TextField
										placeholder={"Adresse complète"}
										value={selectedContractor?.fulladdress}
										onUpdate={(element: string) => {
											setSelectedContractor({
												...selectedContractor,
												fulladdress: element,
											});
										}}
									/>
								</div>
								<div className="row">
									<div className="col md6">
										<TextField
											placeholder={"Numéro de téléphone"}
											value={selectedContractor?.phone}
											onUpdate={(element: string) => {
												setSelectedContractor({
													...selectedContractor,
													phone: element,
												});
											}}
										/>
									</div>
									<div className="col md6">
										<div className="row row_color">
											<div className="col md6 color_picker_box">
												<span>Attribuer une couleur</span>
												<FormControl
													className="select_field"
													sx={{ m: 1, minWidth: 120 }}
												>
													<button
														className="colorPickerButton"
														onClick={() => {
															setShowPicker(true);
														}}
													>
														<div
															style={{ backgroundColor: currentColor }}
														></div>
													</button>
													{showPicker && (
														<BlockPicker
															className="colorPicker"
															colors={COLORS}
															color={currentColor}
															onChangeComplete={(color) => {
																setCurrentColor(color.hex);
																setShowPicker(false);
															}}
														/>
													)}
												</FormControl>
											</div>
											<div className="col md6">
												<div className="function_field">
													<Select
														classNamePrefix="react-select"
														aria-label="Sélectionner une fonction"
														placeholder="Sélectionner une fonction"
														options={roles}
														name="Fonction"
														isSearchable={true}
														isClearable={false}
														onChange={(element: any) => {
															const newFunctionObj = element;
															setNewContributorFunction(newFunctionObj);
														}}
														theme={(theme) => ({
															...theme,
															borderRadius: 0,
															colors: {
																...theme.colors,
																primary25: "#e238324f",
																primary50: "#e2383287",
																primary: COLORS[0],
															},
														})}
													/>

													{newContributorFunction?.value == 999 && (
														<div>
															<TextField
																placeholder={"Définir la fonction"}
																value={newContributorFunction.label}
																onUpdate={(element: string) => {
																	const newFunctionObj = {
																		value: newContributorFunction.value,
																		label: element,
																		key: newContributorFunction.key,
																	};
																	setNewContributorFunction(newFunctionObj);
																}}
															/>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className={"actions"}>
									{error && (
										<Alert severity="error">
											Veuillez vérifier tous les champs avant de continuer.
										</Alert>
									)}
									<Button
										className="action_button"
										variant="contained"
										onClick={saveContractor}
									>
										Ajouter
									</Button>
								</div>
							</div>
						)}

						{!showManual && (
							<div className="contractors_box">
								<div className="row">
									<div className="col">
										<span className="title">
											Rechercher votre intervenant en tapant son nom / adresse /
											email
										</span>
										<TextField
											onUpdate={(value: string) => {
												setSearchContractor(value);
												findContractor(value);
											}}
											placeholder="Rechercher un intervenant"
											value={searchContractor}
										/>
										{contractorsFound.length > 0 && (
											<div className="contractorsFound">
												{contractorsFound.map((contractor: any) => {
													return (
														<div
															className="contractor"
															onClick={() => {
																setShowManual(true);
																setSelectedContractor(contractor);
															}}
														>
															<div className="contractorInfos">
																<div className="row">
																	<div className="contractorCompany">
																		{contractor.company}
																	</div>
																	<div className="contractorName">
																		{contractor.firstname} {contractor.name}
																	</div>
																	<div className="contractorEmail">
																		{contractor.email}
																	</div>
																</div>
																<div className="row">
																	<div className="contractorAddress">
																		{contractor.fulladdress}
																	</div>
																</div>
															</div>
															<Button
																variant="outlined"
																onClick={() => {
																	setShowManual(true);
																	setSelectedContractor(contractor);
																}}
															>
																Sélectionner
															</Button>
														</div>
													);
												})}
											</div>
										)}
									</div>
									<div className="col">
										<span className="alternative">ou</span>
										<Button
											variant="contained"
											onClick={() => setShowManual(true)}
										>
											Ajouter un intervenant
										</Button>
									</div>
								</div>
							</div>
						)}
					</div>
				</motion.div>
			</div>
		</>
	);
}

export default AddContractor;
