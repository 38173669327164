export const selectDatasFormatter = (datas: any) => {

    if (Array.isArray(datas)) {
        let formattedDatas = datas.map((element: any) => {
            const tmpElement = { value: '', label: '', key: '' };

            if (element?.id) {
                tmpElement.value = element.id;
            } else if (element?.value) {
                tmpElement.value = element.value;
            }

            if (element?.name) {
                tmpElement.label = element.name;
            } else if (element?.title) {
                tmpElement.label = element.title;
            }

            if (element?.key) {
                tmpElement.key = element.key;
            }
            return tmpElement;

        })

        formattedDatas = formattedDatas.filter((element: any) => element.value !== '' && element.label !== '');
        return formattedDatas;
    } else {
        return datas;
    }
}