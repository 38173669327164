export const statusEnum = [
  { value: "", label: "TASK.STATUS.placeholder", isDisabled: true },
  { value: "assigned", label: "TASK.STATUS.assigned" },
  { value: "started", label: "TASK.STATUS.started" },
  { value: "review", label: "TASK.STATUS.review" },
  { value: "done", label: "TASK.STATUS.done" },
];

export const priorityEnum = [
  { value: "", label: "TASK.PRIORITY.placeholder", isDisabled: true },
  { value: "low", label: "TASK.PRIORITY.low" },
  { value: "mid", label: "TASK.PRIORITY.mid" },
  { value: "high", label: "TASK.PRIORITY.high" },
];
