import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

/** Components */
import Navigation from "Components/Parts/Navigation";
import LightBox from "Components/Parts/Lightbox";
import AppNotifications from "Components/Parts/AppNotifications";

/** Pages */

import Project from "./Project/Project";
import Dashboard from "./Dashboard/Index";
import Account from "./Account/Index";
import Settings from "./Settings/Index";
import Reports from "./Reports/Index";

/** Redux */
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { getUser, checkToken } from "Redux/Reducers/User/UserSlice";
import { clearEvents } from "Redux/Reducers/Project/EventsSlice";

/** Pages */
const pagesRoutes = ["project", "dashboard", "settings", "account", "reports"];

function Page() {
  const { page } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(getUser);

  let currentComponent: string = page ? page : "";

  if (currentComponent === "" || currentComponent === undefined) {
    currentComponent = "project";
  }

  useEffect(() => {
    dispatch(clearEvents({}));
    if (!pagesRoutes.includes(currentComponent)) {
      navigate("/notfound");
    } else {
      !currentUser.logged && dispatch(checkToken({}));

      console.log(typeof currentUser.wizard_done, currentUser.wizard_done);

      if (currentUser.id > 0 && !currentUser.wizard_done) {
        navigate("/welcome");
      }
    }
  }, [currentComponent, currentUser, dispatch, navigate]);


  return (
    <div className="App">
      <LightBox />
      <AppNotifications />
      <div className={"applicationContainer"}>
        <Navigation current={currentComponent} />
        {currentComponent === "project" && <Project />}
        {currentComponent === "reports" && <Reports />}
        {currentComponent === "account" && <Account />}
        {currentComponent === "settings" && <Settings />}
        {currentComponent === "dashboard" && <Dashboard />}
      </div>
    </div>
  );
}

export default Page;
