import React, { useEffect, useState } from "react";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

/** Icons */

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

import TitleIcon from "@mui/icons-material/Title";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

/*
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
*/
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";

import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

const MenuBar = (props: { editor: any; type?: string }) => {
  const { editor, type } = props;
  const [showColorNav, setShowColorNav] = useState(false);

  const colors = [
    "#650DF6",
    "#053B8C",
    "#0EB5C6",
    "#65C60E",
    "#FFB810",
    "#F65C0D",
    "#BC0101",
    "#F60D3F",
    "#9D9D9D",
    "#373737",
  ];

  if (!editor) {
    return null;
  }

  const triggerActionOnText = (action: string) => {
    const element = editor.chain().focus();

    switch (action) {
      case "bold":
        element.toggleBold().run();
        break;
      case "italic":
        element.toggleItalic().run();
        break;
      case "strike":
        element.toggleStrike().run();
        break;
      case "underline":
        element.toggleUnderline().run();
        break;
      case "rule":
        element.setHorizontalRule().run();
        break;
      case "link":
        element.setLink({ href: "https://example.com", target: "_blank" });
        break;
    }
  };

  const focusOnEditor = () => {
    editor.chain().focus().run();
  };

  return (
    <div className={type + " menubar"}>
      {type !== "min" && (
        <div className="menu_group">
          <button
            onFocus={focusOnEditor}
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().chain().focus().undo().run()}
          >
            <UndoIcon />
          </button>
          <button
            onFocus={focusOnEditor}
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().chain().focus().redo().run()}
          >
            <RedoIcon />
          </button>
        </div>
      )}

      <div className="menu_group">
        <button
          onFocus={focusOnEditor}
          onClick={() => triggerActionOnText("bold")}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <FormatBoldIcon />
        </button>
        <button
          onFocus={focusOnEditor}
          onClick={() => triggerActionOnText("italic")}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <FormatItalicIcon />
        </button>
        {type !== "min" && (
          <button
            onFocus={focusOnEditor}
            onClick={() => triggerActionOnText("strike")}
            className={editor.isActive("strike") ? "is-active" : ""}
          >
            <StrikethroughSIcon />
          </button>
        )}

        <button
          onFocus={focusOnEditor}
          onClick={() => triggerActionOnText("underline")}
          className={editor.isActive("underline") ? "is-active" : ""}
        >
          <FormatUnderlinedIcon />
        </button>
      </div>
      <div className="menu_group">
        <button
          onFocus={focusOnEditor}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }
        >
          <TitleIcon />
        </button>
        <button
          onFocus={focusOnEditor}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <FormatListBulletedIcon />
        </button>
        {type !== "min" && (
          <button
            onFocus={focusOnEditor}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "is-active" : ""}
          >
            <FormatListNumberedIcon />
          </button>
        )}

        <button
          onFocus={focusOnEditor}
          onClick={() => triggerActionOnText("rule")}
        >
          <HorizontalRuleIcon />
        </button>

        <button
          onFocus={focusOnEditor}
          className={showColorNav ? "open_color_nav active" : "open_color_nav"}
          onClick={() => setShowColorNav(!showColorNav)}
        >
          <FormatColorFillIcon />
        </button>
      </div>
      <div className={showColorNav ? "color_nav open" : "color_nav"}>
        {colors.map((color: string, index: number) => {
          return (
            <button
              key={index}
              onClick={() => editor.chain().focus().setColor(color).run()}
            >
              <div style={{ backgroundColor: color }}></div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const Editor = (props: {
  content: string;
  onEdit: Function;
  placeholder?: string;
  title?: string;
  type?: string;
  clear?: boolean;
}) => {
  const { content, onEdit, placeholder, title, type, clear } = props;
  const [defaultPlaceholder] = useState(placeholder);

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      StarterKit,
      TextStyle,
      Underline,
      Link.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder: defaultPlaceholder,
      }),
    ],
    content: content,
  });

  useEffect(() => {
    const updatedContent = editor?.getHTML();
    onEdit(updatedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor?.getHTML()]);

  useEffect(() => {
    if (clear) {
      editor?.commands.clearContent(true);
    }
  }, [clear, editor]);

  return (
    <div className="editor">
      {title && <span className="title">{title}</span>}
      <MenuBar editor={editor} type={type} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default Editor;
