import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import config from "Config";

import Pin from "Assets/Images/logo/pin.png";
const containerStyle = {
  width: "100%",
  height: "20em",
};

function Map(props: { coord?: { lat: number; lng: number } }) {
  const { coord } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleApi,
  });

  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded && coord?.lat && coord?.lng ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coord}
      zoom={18}
      onUnmount={onUnmount}
    >
      <Marker
        position={coord}
        icon={Pin}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map);
