import React from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import { removeItem } from "Utils/Storage";

const Logout = () => {
  const { t } = useTranslation();

  const logout = () => {
    removeItem("token");
    removeItem("current_project");
    window.location.href = "/login";
  };

  return (
    <Button
      className="logout"
      variant="outlined"
      color="error"
      onClick={logout}
    >
      {t("ACCOUNT.ACTIONS.logout")}
    </Button>
  );
};

export default Logout;
