/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Components */
import { EventPropsType } from "Types/Event";
import EventLine from "Components/Pages/Project/Events/Item";

function Flux(props: EventPropsType) {
	const { onOpen, events } = props;
	const { t } = useTranslation();

	const [formattedEvents, setFormattedEvents] = useState<any>({});

	useEffect(() => {
		const formatted: any = [];

		for (const event of events) {
			const dayStamp = Date.parse(event.event_start.split(" ")[0]);

			if (formatted[dayStamp]) {
				formatted[dayStamp][event.id] = event;
			} else {
				formatted[dayStamp] = {
					[event.id]: event,
				};
			}
		}
		setFormattedEvents(formatted);
	}, [events]);

	return (
		<>
			<div className="event_flux">
				{formattedEvents && Object.keys(formattedEvents).length > 0 ? (
					<>
						{Object.keys(formattedEvents).map(
							(dayStamp: any, index: number) => {
								const dayData = new Date(parseInt(dayStamp));
								const today = new Date();
								const yesterday = new Date();
								yesterday.setDate(yesterday.getDate() - 1);

								let dayLabel = "le " + dayData.toLocaleDateString("fr-fr");

								if (
									dayData.toLocaleDateString("fr-fr") ===
									today.toLocaleDateString("fr-fr")
								) {
									dayLabel = "Aujourd'hui";
								} else if (
									dayData.toLocaleDateString("fr-fr") ===
									yesterday.toLocaleDateString("fr-fr")
								) {
									dayLabel = "Hier";
								}

								return (
									<div key={index}>
										<strong className="day_line">{dayLabel}</strong>
										{Object.values(formattedEvents[dayStamp])
											.reverse()
											.map((event: any, subIndex: number) => {
												return (
													<EventLine
														key={subIndex}
														event={event}
														onOpen={onOpen}
													/>
												);
											})}
									</div>
								);
							}
						)}
					</>
				) : (
					""
				)}
			</div>
		</>
	);
}

export default Flux;
