/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

function SkeletonList() {
  return (
    <div>
      <div className="filters"></div>
      <div className="listContainer">
        <Stack className="columns">
          <div className="item">
            <div className="type">
              <Skeleton variant="rectangular" width={40} height={20} />
            </div>
            <div className="content">
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
            <div className="date">
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
            <div className="contributors">
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
            <div className="tags">
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
          </div>
        </Stack>
        <div className="list">
          <div className="item">
            <div className="type">
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="content">
              <Skeleton variant="rectangular" width={300} height={20} />
            </div>
            <div className="date">
              <Skeleton variant="rectangular" width={100} height={20} />
            </div>
            <div className="contributors">
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="tags row">
              <Skeleton variant="rectangular" width={60} height={20} />
              <span>&nbsp;</span>
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
          </div>
          <div className="item">
            <div className="type">
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="content">
              <Skeleton variant="rectangular" width={500} height={20} />
            </div>
            <div className="date">
              <Skeleton variant="rectangular" width={100} height={20} />
            </div>
            <div className="contributors">
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="tags row">
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
          </div>
          <div className="item">
            <div className="type">
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="content">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
            <div className="date">
              <Skeleton variant="rectangular" width={100} height={20} />
            </div>
            <div className="contributors">
            </div>
            <div className="tags row">
              <Skeleton variant="rectangular" width={60} height={20} />
              <span>&nbsp;</span>
              <Skeleton variant="rectangular" width={60} height={20} />
              <span>&nbsp;</span>
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
          </div>
          <div className="item">
            <div className="type">
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="content">
              <Skeleton variant="rectangular" width={300} height={20} />
            </div>
            <div className="date">
              <Skeleton variant="rectangular" width={100} height={20} />
            </div>
            <div className="contributors">
              <Skeleton variant="circular" width={30} height={30} />
            </div>
            <div className="tags row">
              <Skeleton variant="rectangular" width={60} height={20} />
              <span>&nbsp;</span>
              <Skeleton variant="rectangular" width={60} height={20} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SkeletonList;
