import abstractApiObject from "./abstractApiObject";

class projectApiObject extends abstractApiObject {
  constructor() {
    super();
    this.controller = "project";
  }

  getAll(parameters: { id_user: number }) {
    const params = {
      url: this.controller + "&id_user=" + parameters.id_user,
      method: "GET",
      body: {},
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getBudget(id: number | undefined) {
    const params = {
      url: this.controller + "/budget/" + id,
      method: "GET",
      body: {},
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  sendTest(email: string) {
    const params = {
      url: this.controller + "/test",
      method: "POST",
      body: { email: email },
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  
}

export default projectApiObject;
