import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

/** Pages */
import Error from "./Components/Pages/Error";
import Lost from "./Components/Pages/404";
import Page from "./Components/Pages/Index";
import Cgu from "./Components/Pages/Cgu";
import Wizard from "./Components/Pages/Wizard";
import Login from "./Components/Pages/Account/Login/Login";
import Register from "./Components/Pages/Account/Login/Register";
import Validate from "./Components/Pages/Account/Login/Validate";
import Recover from "./Components/Pages/Account/Login/Recover";
import Reset from "./Components/Pages/Account/Login/Reset";
import ValidationDateStatus from "./Components/Pages/External/ValidationDateStatus";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    errorElement: <Error />,
  },
  {
    path: "/:page",
    element: <Page />,
    errorElement: <Error />,
  },
  {
    path: "/:page/:id",
    element: <Page />,
    errorElement: <Error />,
  },
  {
    path: "/:page/:id/:tab",
    element: <Page />,
    errorElement: <Error />,
  },
  {
    path: "/:page/:id/:tab/:param",
    element: <Page />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/login/:id",
    element: <Login />,
    errorElement: <Error />,
  },

  {
    path: "/register",
    element: <Register />,
    errorElement: <Error />,
  },
  {
    path: "/register/:id",
    element: <Register />,
    errorElement: <Error />,
  },
  {
    path: "/account/validate/:hash",
    element: <Validate />,
    errorElement: <Error />,
  },
  {
    path: "/account/recover",
    element: <Recover />,
    errorElement: <Error />,
  },
  {
    path: "/account/resetpassword/:hash",
    element: <Reset />,
    errorElement: <Error />,
  },
  {
    path: "/notfound",
    element: <Lost />,
    errorElement: <Error />,
  },
  {
    path: "/welcome",
    element: <Wizard />,
    errorElement: <Error />,
  },
  {
    path: "/cgu",
    element: <Cgu />,
    errorElement: <Error />,
  },{
    path: "/validationdatestatus/:hash/:status",
    element: <ValidationDateStatus />,
    errorElement: <Error />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
