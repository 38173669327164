/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import "dayjs/locale/fr";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { EventType } from "../../../../Types/Event";

function DateEvent(props: {
  event: EventType;
  onEdit: Function;
  editing: boolean;
}) {
  const { event, editing, onEdit } = props;
  const { t } = useTranslation();

  const eventStart = new Date(event.event_start);
  const eventEnd = new Date(event.event_end);

  const [startDate, setStartDate] = useState(event.event_start);
  const [endDate, setEndDate] = useState(event.event_end);

  const [startDateFormatted, setStartDateFormatted] = useState(
    event.event_start
  );
  const [endDateFormatted, setEndDateFormatted] = useState(event.event_end);

  const [currentEvent, setcurrentEvent] = useState(event);

  const handleHtml = (content = "") => {
    return { __html: content };
  };

  const saveEvent = () => {
    onEdit(currentEvent);
  };

  useEffect(() => {
    const newEvent = { ...currentEvent };
    newEvent.event_start = startDateFormatted;
    newEvent.event_end = endDateFormatted;
    setcurrentEvent(newEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateFormatted, endDateFormatted]);

  useEffect(() => {
    if (editing) {
      saveEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent]);

  return (
    <div className={"eventDate"}>
      <strong>Phase</strong>
      {editing ? (
        <>
          <div className="row">
            <div className="col">
              <LocalizationProvider
                dateAdapter={AdapterLuxon}
                adapterLocale="fr"
              >
                <label>{t("TASK.start_date")}</label>
                <DesktopDatePicker
                  className="picker"
                  inputFormat="d/M/y"
                  disableMaskedInput={true}
                  value={startDate ? startDate : new Date()}
                  onChange={(date: any) => {
                    const newDate =
                      date.c.month + "/" + date.c.day + "/" + date.c.year;

                    const newDateFormatted =
                      date.c.year + "-" + date.c.month + "-" + date.c.day;

                    setStartDate(newDate);
                    setStartDateFormatted(newDateFormatted);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col">
              <LocalizationProvider
                dateAdapter={AdapterLuxon}
                adapterLocale="fr"
              >
                <label>{t("TASK.end_date")}</label>
                <DesktopDatePicker
                  className="picker"
                  inputFormat="d/M/y"
                  disableMaskedInput={true}
                  value={endDate ? endDate : new Date()}
                  onChange={(date: any) => {
                    const newDate =
                      date.c.month + "/" + date.c.day + "/" + date.c.year;

                    const newDateFormatted =
                      date.c.year + "-" + date.c.month + "-" + date.c.day;

                    setEndDate(newDate);
                    setEndDateFormatted(newDateFormatted);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"date"}>
            {eventStart && eventEnd && (
              <>
                <span>du</span>{" "}
                {eventStart.toLocaleDateString("fr-fr", {
                  month: "numeric",
                  day: "numeric",
                })}{" "}
                <span>au</span>{" "}
                {eventEnd.toLocaleDateString("fr-fr", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DateEvent;
