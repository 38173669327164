/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";

import { amount_format } from "Utils/Locale";
/* icons */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
/** Utils */
import { convertStringToDate } from "Utils/DateFormatter";

/** Components */
import Tags from "Components/Parts/Tags";
import Avatars from "Components/Parts/Avatars";
import { EventType } from "Types/Event";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { setFilters, getFilters } from "Redux/Reducers/Project/EventsSlice";
import { hasSomeParentTheClass } from "Utils/Globals";

import { getContractors } from "Redux/Reducers/Project/ProjectSlice";

import {
	open,
	getStatus,
	setPictures,
	setVideo,
	setCurrent,
} from "Redux/Reducers/Lightbox/LightboxSlice";

const Item = (props: { event: EventType; onOpen: Function }) => {
	const { event, onOpen } = props;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const contributorsList = useAppSelector(getContractors);
	const filters = useAppSelector(getFilters);
	const [avatarsContractors, setAvatarsContractors] = useState<any>([]);
	const [tags, setTags] = useState([]);

	const galleryStatus = useAppSelector(getStatus);

	const handleHtml = (content = "") => {
		return { __html: content };
	};

	useEffect(() => {
		const attributes =
			typeof event?.attr === "string" ? JSON.parse(event?.attr) : event?.attr;

		let contributorsAttr =
			typeof attributes?.contractors === "string"
				? JSON.parse(attributes?.contractors)
				: attributes?.contractors;
		let tagsAttr =
			typeof attributes?.tags === "string"
				? JSON.parse(attributes?.tags)
				: attributes?.tags;

		tagsAttr = typeof tagsAttr === "string" ? JSON.parse(tagsAttr) : tagsAttr;
		contributorsAttr =
			typeof contributorsAttr === "string"
				? JSON.parse(contributorsAttr)
				: contributorsAttr;

		setTags(tagsAttr);

		if (contributorsList && contributorsAttr) {
			let AvatarContractors: any = contributorsList.filter((item: any) => {
				return contributorsAttr.includes(item.id);
			});
			AvatarContractors = AvatarContractors.map((item: any) => {
				return { value: item.id, label: item.company, color: item.color };
			});
			setAvatarsContractors(AvatarContractors);
		}
	}, [event]);

	return (
		<motion.div
			whileTap={{ scale: 0.95 }}
			onClick={(element: any) => {
				!hasSomeParentTheClass(element.target, [
					"tag",
					"picture",
					"video",
					"file",
				]) && onOpen(event);
			}}
		>
			<div className="event_flux_item">
				<div className="event_header">
					<div className={"type " + event.type}>
						<Tooltip title={t("TYPE." + event.type)}>
							<div>
								{event.type === "task" && <AssignmentTurnedInIcon />}
								{event.type === "date" && <CalendarMonthIcon />}
								{event.type === "text" && <ChatBubbleOutlineIcon />}
								{event.type === "file" && <AttachFileIcon />}
								{event.type === "picture" && <InsertPhotoIcon />}
								{event.type === "video" && <VideocamIcon />}
								{event.type === "sound" && <VolumeUpIcon />}
								{event.type === "mail" && <ForwardToInboxIcon />}
							</div>
						</Tooltip>
					</div>
					<div className="event_action">
						<strong>Vous</strong>
						<span> avez</span>
						&nbsp;
						{event.type === "file" || event.type === "picture" ? (
							<span>
								{t("TYPE.ACTIONS.added")} {event?.attr?.files?.length}{" "}
								{event?.attr?.files?.length > 1 ? (
									<>{t("TYPE.PLURIALS." + event.type)}</>
								) : (
									<>{t("TYPE." + event.type)}</>
								)}
							</span>
						) : (
							<span>{t("TYPE.ACTIONS.add_" + event.type)}</span>
						)}
						<span>{t("GLOBAL.LABELS.at")}</span>
						<span className="event_created">
							{convertStringToDate(event.event_start, true)}
						</span>
						{event.attr?.fileType && event.attr.fileType === "estimate" && (
							<div className="file_type estimate">
								<span className="label">{t("EVENT.FILTERS.estimate")}</span>
								<span>{amount_format(event.attr.amount)}</span>
							</div>
						)}
						{event.attr?.fileType && event.attr.fileType === "invoice" && (
							<div className="file_type invoice">
								<span className="label">{t("EVENT.FILTERS.invoice")}</span>
								<span>{amount_format(event.attr.amount)}</span>
							</div>
						)}
					</div>
					<div className="tags">
						{tags && tags.length > 0 ? (
							<Tags
								order="left"
								tags={tags}
								handleClick={(tag: string) => {
									const newFilters = { ...filters, search: tag };
									dispatch(setFilters(newFilters));
								}}
							/>
						) : (
							<span className="no_tag">Aucune étiquette</span>
						)}
					</div>

					<div className="contributors">
						{avatarsContractors && avatarsContractors.length > 0 ? (
							<Avatars order="right" items={avatarsContractors} />
						) : (
							<span className="no_contractor">Aucun intervenant</span>
						)}
					</div>
					<div className={event?.comments > 0 ? "comments" : "comments empty"}>
						{event?.comments > 0 ? (
							<>
								<span>{event.comments}</span>
								<ChatBubbleOutlineIcon />
							</>
						) : (
							<span>{"Aucun commentaire"}</span>
						)}
					</div>
				</div>
				<div className="event_content">
					<div className="main_content">
						{(event.type === "text" ||
							event.type === "mail" ||
							event.type === "task" ||
							event.type === "date") && (
							<>
								<span className="title">
									{event.title ? event.title : t("TYPE." + event.type)}
								</span>
							</>
						)}
					</div>
				</div>
				<div className="event_footer">
					<div className="content">
						{event.attr.files && event.attr.files.length > 0 && (
							<>
								{event.attr.files
									.slice(0, 3)
									.map((file: any, index: number) => {
										return (
											<div key={index}>
												{event.type === "file" && (
													<a
														className="file"
														href={file.url}
														download
														target="_blank"
													>
														{file.name}
													</a>
												)}
												{event.type === "picture" && (
													<img
														className="picture"
														onClick={() => {
															dispatch(setVideo({} as any));
															console.log('event pictures',event.attr.files);
															dispatch(setPictures(event.attr.files));
															dispatch(setCurrent(index));
															dispatch(open());
														}}
														src={file.url}
														alt={file.name}
													/>
												)}
												{event.type === "video" && (
													<ReactPlayer
														className="video"
														url={file.url}
														width={200}
														height={100}
														style={{
															backgroundColor: "#EEE",
															cursor: "pointer",
															borderRadius: "5px",
														}}
														onClick={() => {
															dispatch(setPictures([] as any));
															console.log('event video',event.attr.files[0]);
															dispatch(setVideo(event.attr.files[0]));
															dispatch(open());
														}}
													/>
												)}
											</div>
										);
									})}
								{event.attr.files.length > 3 && (
									<span>+ {event.attr.files.length - 3}</span>
								)}
							</>
						)}

						{event.type === "date" && (
							<div className="date_range">
								{event.event_start !== event.event_end ? (
									<>
										<span>Du {convertStringToDate(event.event_start)}</span>
										<span> </span>
										<span>au {convertStringToDate(event.event_end)}</span>
									</>
								) : (
									<span>Le {convertStringToDate(event.event_end)}</span>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default Item;
