/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import "dayjs/locale/fr";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

/** Types */
import { EventType } from "../../../../../Types/Event";

function AddDate(props: { event: EventType; onAdd: Function }) {
  const { event, onAdd } = props;

  const { t } = useTranslation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startDateFormatted, setStartDateFormatted] = useState("");
  const [endDateFormatted, setEndDateFormatted] = useState("");

  useEffect(() => {
    const newEvent = { ...event };
    newEvent.event_start = startDateFormatted;
    newEvent.event_end = endDateFormatted;

    onAdd(newEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateFormatted, endDateFormatted]);

  return (
    <div>
      <div className="row date">
        <div className="col">
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
            <label>{t("TASK.start_date")}</label>
            <DesktopDatePicker
              className="picker"
              inputFormat="d/M/y"
              disableMaskedInput={true}
              value={startDate ? startDate : new Date()}
              onChange={(date: any) => {
                const newDate =
                  date.c.month + "/" + date.c.day + "/" + date.c.year;

                const newDateFormatted =
                  date.c.year + "-" + date.c.month + "-" + date.c.day;

                setStartDate(newDate);
                setStartDateFormatted(newDateFormatted);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="row date">
        <div className="col">
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
            <label>{t("TASK.end_date")}</label>
            <DesktopDatePicker
              className="picker"
              inputFormat="d/M/y"
              disableMaskedInput={true}
              value={endDate ? endDate : new Date()}
              onChange={(date: any) => {
                const newDate =
                  date.c.month + "/" + date.c.day + "/" + date.c.year;

                const newDateFormatted =
                  date.c.year + "-" + date.c.month + "-" + date.c.day;

                setEndDate(newDate);
                setEndDateFormatted(newDateFormatted);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
}

export default AddDate;
