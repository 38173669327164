/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import Skeleton from "@mui/material/Skeleton";

function SkeletonHeader() {
  return (
    <div className="projectName">
      <Skeleton
        className=""
        variant="rounded"
        width={40}
        height={40}
        style={{ marginRight: 25 }}
      />
      <Skeleton variant="rectangular" width={250} height={25} />
    </div>
  );
}

export default SkeletonHeader;
