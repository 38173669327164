/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import filterButton from "Assets/Images/clear/filters/filter.png";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import "dayjs/locale/fr";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
//import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useAppDispatch } from "Redux/hooks";



import Stack from "@mui/material/Stack";



const defaultFilters: any = {
  search: "",
  start_date: new Date(),
  from: new Date(),
  to: new Date(),
  end_date: new Date(),
};
function Filters(props: { filtersParams: any; onFilter: Function }) {
  const dispatch = useAppDispatch();
  const { onFilter, filtersParams } = props;
  const { t } = useTranslation();

  const [filters, setFilters] = useState(defaultFilters);
  const [amountActiveFilters, setAmountActiveFilters] = useState(0);

  const [open, setOpen] = useState(false);

  const clearFilters = () => {
    const clearFilters = defaultFilters;
    delete clearFilters.from;
    delete clearFilters.to;
    delete clearFilters.end_date;
    delete clearFilters.start_date;
    setFilters(clearFilters);
    onFilter(clearFilters);
    setOpen(false);
    setTimeout(() => {
      setAmountActiveFilters(0);
    }, 10);

    
  };

  useEffect(() => {
    let amount = 0;

    if (filtersParams.from) {
      amount++;
    }

    if (filtersParams.to) {
      amount++;
    }

    if(filtersParams.type){
      amount++;
    }

    if(filtersParams.filetype){
      amount++;
    }


    setAmountActiveFilters(amount);

    const formattedFilters = { ...filtersParams };
    setFilters(formattedFilters);
  }, [filtersParams]);

  return (
    <div className="filtersActions">
      <TextField
        id="search_event"
        className="search_event"
        label={t("EVENT.FILTERS.placeholder")}
        variant="outlined"
        value={filters.search}
        onKeyDown={(e) => {
          e.key === "Enter" && onFilter(filters);
        }}
        onChange={(element) => {
          const newFilters = { ...filters };
          newFilters.search = element.target.value;
          setFilters(newFilters);
        }}
      />
      <span
        className={
          filters.search && filters.search !== ""
            ? "enter_message"
            : "enter_message empty"
        }
      >
        Appuyer sur la touche <KeyboardReturnIcon /> pour lancer la recherche
      </span>
      <button className="switchBtn" onClick={() => setOpen(!open)}>
        {amountActiveFilters > 0 && (
          <span className="amount">{amountActiveFilters}</span>
        )}
        {open ? (
          <img src={filterButton} alt="filter" />
        ) : (
          <img src={filterButton} alt="filter" />
        )}
      </button>

      <div className={open ? "filtersBox open" : "filtersBox"}>
        <div className="header">
          <h3>{t("EVENT.FILTERS.title")}</h3>
          <button className="clear" onClick={clearFilters}>
          {t("EVENT.FILTERS.clear_filters")}
          </button>
        </div>
        <div className="separator"></div>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
          <span className="filterLabel">
            {t("EVENT.FILTERS.by_publication_date")}
          </span>
          <Stack className="dateFilter" spacing={3}>
            <DesktopDatePicker
              className="picker"
              label="Du"
              inputFormat="d/M/y"
              disableMaskedInput={true}
              value={filters?.start_date ? filters.start_date : new Date()}
              onChange={(date) => {
                const formattedForField =
                  date.c.month + "/" + date.c.day + "/" + date.c.year;

                const formattedForSearch =
                  date.c.year + "-" + date.c.month + "-" + date.c.day;

                const newFilters = { ...filters };

                newFilters.start_date = formattedForField;
                newFilters.from = formattedForSearch;
                setFilters(newFilters);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label="Au"
              className="picker right"
              inputFormat="d/M/y"
              value={filters?.end_date ? filters.end_date : new Date()}
              disableMaskedInput={true}
              onChange={(date) => {
                const formattedForField =
                  date.c.month + "/" + date.c.day + "/" + date.c.year;

                const formattedForSearch =
                  date.c.year + "-" + date.c.month + "-" + date.c.day;

                const newFilters = { ...filters };

                newFilters.end_date = formattedForField;
                newFilters.to = formattedForSearch;
                setFilters(newFilters);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {/*
            <MobileDatePicker
              label="Date mobile"
              inputFormat="MM/DD/YYYY"
              value={"02/02/2023"}
              onChange={() => {}}
              renderInput={(params) => <TextField {...params} />}
            />
        */}
          </Stack>
        </LocalizationProvider>
        <div className="separator"></div>
        <span className="filterLabel">Recherche par type</span>
        <div className="row type_filter">
          <div className="col">
            <InputLabel className="input_select" id="type_select">
              Type d'évènement
            </InputLabel>

            <Select
              labelId="type_select"
              id="select_type"
              value={filters.type ? filters.type : "*"}
              label="Fonction"
              onChange={(element) => {
                const newFilters = { ...filters };
                if (element.target.value === "*") {
                  delete newFilters.type;
                } else {
                  newFilters.type = element.target.value;
                }
                setFilters(newFilters);
              }}
            >
              <MenuItem value={"*"}>{t("EVENT.FILTERS.all")}</MenuItem>
              <MenuItem value={"picture"}>{t("EVENT.FILTERS.picture")}</MenuItem>
              <MenuItem value={"sound"}>{t("EVENT.FILTERS.sound")}</MenuItem>
              <MenuItem value={"file"}>{t("EVENT.FILTERS.file")}</MenuItem>
              <MenuItem value={"video"}>{t("EVENT.FILTERS.video")}</MenuItem>
              <MenuItem value={"text"}>{t("EVENT.FILTERS.text")}</MenuItem>
              <MenuItem value={"task"}>{t("EVENT.FILTERS.task")}</MenuItem>
              <MenuItem value={"date"}>{t("EVENT.FILTERS.date")}</MenuItem>
              <MenuItem value={"mail"}>{t("EVENT.FILTERS.mail")}</MenuItem>
            </Select>
          </div>

          <div className="col">
            <InputLabel className="input_select" id="type_file">
              Type de fichier
            </InputLabel>

            <Select
              labelId="type_file"
              id="seleft_filetype"
              value={filters.filetype ? filters.filetype : "*"}
              label="Fonction"
              onChange={(element) => {
                const newFilters = { ...filters };
                if (element.target.value === "*") {
                  delete newFilters.filetype;
                } else {
                  newFilters.filetype = element.target.value;
                }
                setFilters(newFilters);
              }}
            >
              <MenuItem value={"*"}>{t("EVENT.FILTERS.all")}</MenuItem>
              <MenuItem value={"estimate"}>{t("EVENT.FILTERS.estimate")}</MenuItem>
              <MenuItem value={"invoice"}>{t("EVENT.FILTERS.invoice")}</MenuItem>
            </Select>
          </div>
        </div>

        <div className="separator"></div>

        <Button
          className="filterButton"
          variant="contained"
          onClick={() => {
            onFilter(filters);
            setOpen(false);
          }}
        >
          {t("EVENT.ACTIONS.search")}
        </Button>
      </div>
    </div>
  );
}

export default Filters;
