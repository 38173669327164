/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import "dayjs/locale/fr";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

/** Enums */
import { statusEnum, priorityEnum } from "Utils/enums/Tasks";
import COLORS from "Utils/colors";

import { EventType } from "Types/Event";

function TaskEvent(props: { event: EventType; onEdit: Function }) {
	const { event, onEdit } = props;
	const { t } = useTranslation();

	const sourceStatus = statusEnum.filter((status: any, index: number) => {
		status.label = t(status.label);
		return status;
	});
	const sourcePriority = priorityEnum.filter((priority: any, index: number) => {
		priority.label = t(priority.label);
		return priority;
	});

	const [startDate, setStartDate] = useState(event.event_start);
	const [endDate, setEndDate] = useState(event.event_end);

	const [startDateFormatted, setStartDateFormatted] = useState(
		event.event_start
	);
	const [endDateFormatted, setEndDateFormatted] = useState(event.event_end);
	const [currentEvent, setcurrentEvent] = useState(event);

	const [selectedStatus, setSelectedStatus] = useState(statusEnum[1]);
	const [selectedPriority, setSelectedPriority] = useState(priorityEnum[1]);

	useEffect(() => {
		console.log("event", event);

		const eventStatus = statusEnum.filter((item: any, index: number) => {
			return item.value === event.attr.status;
		});
		setSelectedStatus(eventStatus[0]);

		const eventPriority = priorityEnum.filter((item: any, index: number) => {
			return item.value === event.attr.priority;
		});
		setSelectedPriority(eventPriority[0]);
	}, [event]);

	useEffect(() => {
		const newEvent = structuredClone(currentEvent);
		newEvent.event_start = startDateFormatted;
		newEvent.event_end = endDateFormatted;
		onEdit(newEvent);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDateFormatted, endDateFormatted]);

	useEffect(() => {
		const newEvent = { ...currentEvent };
		newEvent.event_start = startDateFormatted;
		newEvent.event_end = endDateFormatted;
		setcurrentEvent(newEvent);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDateFormatted, endDateFormatted]);

	return (
		<div className={"eventDate"}>
			<>
				<div className="row">
					<div className="col">
						<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
							<label>{t("TASK.start_date")}</label>
							<DesktopDatePicker
								className="picker"
								inputFormat="d/M/y"
								disableMaskedInput={true}
								value={startDate ? startDate : new Date()}
								onChange={(date: any) => {
									const newDate =
										date.c.month + "/" + date.c.day + "/" + date.c.year;

									const newDateFormatted =
										date.c.year + "-" + date.c.month + "-" + date.c.day;

									setStartDate(newDate);
									setStartDateFormatted(newDateFormatted);
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div className="col">
						<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fr">
							<label>{t("TASK.end_date")}</label>
							<DesktopDatePicker
								className="picker"
								inputFormat="d/M/y"
								disableMaskedInput={true}
								value={endDate ? endDate : new Date()}
								onChange={(date: any) => {
									const newDate =
										date.c.month + "/" + date.c.day + "/" + date.c.year;

									const newDateFormatted =
										date.c.year + "-" + date.c.month + "-" + date.c.day;

									setEndDate(newDate);
									setEndDateFormatted(newDateFormatted);
								}}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label htmlFor="select_status">{t("TASK.status")}</label>
						{sourceStatus && (
							<Select
								classNamePrefix="react-select"
								id="select_status"
								className={"select_element " + selectedStatus?.value}
								options={sourceStatus}
								isClearable={false}
								defaultValue={selectedStatus}
								value={selectedStatus}
								onChange={(element: any) => {
									const newEvent = structuredClone(currentEvent);
									newEvent.attr.status = element.value;
									newEvent.attr.priority = selectedPriority?.value
										? selectedPriority.value
										: priorityEnum[1].value;
									onEdit(newEvent);
									setSelectedStatus(element);
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										primary25: "#e238324f",
										primary50: "#e2383287",
										primary: COLORS[0],
									},
								})}
							/>
						)}
					</div>
					<div className="col">
						<label htmlFor="select_priority">{t("TASK.priority")}</label>
						<Select
							classNamePrefix="react-select"
							id="select_priority"
							className={"select_element " + selectedPriority?.value}
							options={sourcePriority}
							isClearable={false}
							defaultValue={selectedPriority}
							value={selectedPriority}
							onChange={(element: any) => {
								const newEvent = structuredClone(currentEvent);
								newEvent.attr.priority = element.value;
								newEvent.attr.status = selectedStatus?.value
									? selectedStatus?.value
									: statusEnum[1].value;

								onEdit(newEvent);
								setSelectedPriority(element);
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									primary25: "#e238324f",
									primary50: "#e2383287",
									primary: COLORS[0],
								},
							})}
						/>
					</div>
				</div>
			</>
		</div>
	);
}

export default TaskEvent;
