import abstractApiObject from "./abstractApiObject";

class eventApiObject extends abstractApiObject {
	constructor() {
		super();
		this.controller = "event";
	}

	getByProject(parameters: any) {
		let url = this.controller + "?1=1";

		for (const param in parameters) {
			url += "&" + param + "=" + parameters[param];
		}

		const params = {
			url: url,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getAmounts(id_project: number) {
		let url = this.controller + "/amounts/" + id_project;

		const params = {
			url: url,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postShare(id: number, email: string, message: string) {
		const params = {
			url: this.controller + "/sendbymail/" + id,
			method: "POST",
			body: { email: email, message: message },
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postConfirmDate(id: number, message: string) {
		const params = {
			url: this.controller + "/proposal/" + id,
			method: "POST",
			body: { message: message },
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
	postCancelDate(id: number, message: string) {
		const params = {
			url: this.controller + "/cancel/" + id,
			method: "post",
			body: { message: message },
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getHistory(id: number) {
		const params = {
			url: this.controller + "/history/" + id,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postComent(datas: { comment: string; id_event: number; id_user: number }) {
		const params = {
			url: this.controller + "/comment/" + datas.id_event,
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postDuplicate(id: number) {
		const params = {
			url: this.controller + "/duplicate/" + id,
			method: "POST",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	postStatus(hash: string | number, status: string, isHash?: boolean) {
		const params = {
			url: this.controller + "/status/" + hash,
			method: "POST",
			body: { status: status, isHash: isHash },
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}

export default eventApiObject;
