/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid, Paper, Button, Divider } from "@mui/material";

import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

import Plan from "Api/Models/Plan";

import { defaultPlan } from "Types/Plan";

const planApi = new Plan();

function Subscription() {
  const { t } = useTranslation();
  const currentUser = useAppSelector(getUser);

  const [currentPlan, setCurrentPlan] = useState(defaultPlan);
  const [plans, setPlans] = useState([]);
  const [monthlyView, setMonthlyView] = useState(true);

  const reduction = true;

  useEffect(() => {
    planApi.getList().then(
      (results: any) => {
        if (results.status === "success") {
          if (Array.isArray(results.data)) {
            const current = results.data.filter((plan: any) => {
              return plan.id === currentUser.id_plan;
            });
            if (current.length) {
              setCurrentPlan(current[0]);
            }
          }
          setPlans(results.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, [currentUser.id_plan]);

  return (
    <div className="subscription">
      <div className="row row_actual_plan">
        <div className="col">
          <h3 className="title">{t("PLAN.LABELS.actual")}: 
            <span className="actual_plan_label">{currentPlan?.name}</span>
          </h3>
        </div>
      </div>
      <Divider light />

      <div className="row row_selector_title">
        <h3 className="title">
          {t("PLAN.LABELS.select_label_1")}{" "}
          <span>{t("PLAN.LABELS.select_label_2")}</span>
        </h3>
        <div className="toggle">
          <Button
            className={monthlyView ? "active" : ""}
            onClick={() => setMonthlyView(true)}
          >
            Mois
          </Button>
          <Button
            className={!monthlyView ? "active" : ""}
            onClick={() => setMonthlyView(false)}
          >
            an
          </Button>
        </div>
      </div>
      <div className="row">
        <Grid className="plan_selector" container spacing={6}>
          {plans.map((plan: any, index: number) => {
            return (
              <Grid item xs={2.4} key={index}>
                <Paper
                  className="plan"
                  elevation={currentUser.id_plan === plan.id ? 24 : 3}
                >
                  {currentUser.id_plan === plan.id ? (
                    <span className="actual_selected">{t('PLAN.LABELS.selected_plan')}</span>
                  ) : (
                    <span className="plan_not_selected"></span>
                  )}
                  <div className="content">
                    <strong>{plan.name}</strong>

                    <span className="price">
                      {plan.price > 0 ? (
                        <>
                          {monthlyView ? (
                            <>
                              {plan.price} € / {t("PLAN.PRICE.monthly")}
                            </>
                          ) : (
                            <>
                              {reduction ? (
                                <>
                                  {Math.floor(plan.price * 10)}€ /{" "}
                                  {t("PLAN.PRICE.yearly")}
                                </>
                              ) : (
                                <>
                                  {Math.floor(plan.price * 12)} € /{" "}
                                  {t("PLAN.PRICE.yearly")}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>{t("PLAN.PRICE.free")}</>
                      )}
                    </span>

                    <ul className="advantages">
                      <li>
                        {plan.projectlimit === "-1" ? (
                          <>{t("PLAN.ADVANTAGES.unlimited_projects")}</>
                        ) : (
                          <>
                            {plan.projectlimit === "1" ? (
                              <>
                                {plan.projectlimit} {t("PROJECT.singular")}
                              </>
                            ) : (
                              <>
                                {plan.projectlimit} {t("PROJECT.plurial")}
                              </>
                            )}
                          </>
                        )}
                      </li>
                      <li>
                        {plan.storagelimit === "-1" ? (
                          <>{t("PLAN.ADVANTAGES.unlimited_storage")}</>
                        ) : (
                          <>
                            {parseInt(plan.storagelimit) >= 1000 ? (
                              <>
                              <span className="storage">
                                {parseInt(plan.storagelimit) / 1000}
                                {" Gb"}
                                </span>
                                 {t("PLAN.ADVANTAGES.storage_space")}
                              </>
                            ) : (
                              <>
                              <span className="storage">
                                {plan.storagelimit}
                                {" Mb"}
                                </span>
                                 {t("PLAN.ADVANTAGES.storage_space")}
                              </>
                            )}
                          </>
                        )}
                      </li>

                      <li>
                        {plan.userlimit === "-1" ? (
                          <>{t("PLAN.ADVANTAGES.unlimited_users")}</>
                        ) : (
                          <>
                            {parseInt(plan.userlimit) > 0 ? (
                              <>
                                {plan.userlimit === "1" ? (
                                  <>
                                    {plan.userlimit} {t("PLAN.ADVANTAGES.user")}
                                  </>
                                ) : (
                                  <>
                                    {plan.userlimit}{" "}
                                    {t("PLAN.ADVANTAGES.users")}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <span className="disabled">
                                  {t("PLAN.ADVANTAGES.no_user")}
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </li>
                     
                        <li>
                        {parseInt(plan.email) === 0 ? (
                          <span className="disabled">
                            <>{t("PLAN.ADVANTAGES.no_email")}</>
                          </span>
                        ) : (
                          <>{t("PLAN.ADVANTAGES.email")}</>
                        )}
                      </li>
                      <li>
                        {parseInt(plan.sms) === 0 ? (
                          <span className="disabled">
                            <>{t("PLAN.ADVANTAGES.no_sms")}</>
                          </span>
                        ) : (
                          <> {plan.smslimit} {t("PLAN.ADVANTAGES.sms")}</>
                        )}
                      </li>
                      
                      {!monthlyView && plan.price > 0 && reduction ? (
                        <li className="reduction">{t('PLAN.ADVANTAGES.reduction')}</li>
                      ) : (
                        <li>&nbsp;</li>
                      )}
                    </ul>
                    <Button
                      className="select_button"
                      disabled={plan.id === currentPlan.id ? true : false}
                    >
                      {t("PLAN.ACTIONS.select_plan")}
                    </Button>
                  </div>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}

export default Subscription;
