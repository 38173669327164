/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

const recordsArray = [] as any[];

function SoundPlayer(props: any) {
  const { sounds } = props;
  const [records, setRecords] = useState(recordsArray);

  
  useEffect(() => {
    setRecords(recordsArray);
  },[]);
  
  useEffect(() => {
    const formattedRecords = [...records];
    for (const recordIndex in sounds) {
      const record = sounds[recordIndex];

      console.log(record);

      fetch(record.url).then(
        (sound) => {
          record.loaded = true;
          record.error = false;
          formattedRecords.push(record);
          setRecords(formattedRecords);
        },
        (error) => {
         // record.loaded = true;
          //record.error = true;
          formattedRecords.push(record);
          setRecords(formattedRecords);
        }
      );
    }
  }, [sounds]);

  return (
    <div className={"soundPlayer"}>
      {records.map((sound: any, index: number) => {
        return (
          <div className={"player"} key={index}>
            <span>{sound.name}</span>
            {!sound.error ? (
              <audio id={"record_" + index} controls={true} src={sound.url}>
              </audio>
            ):
              <span>Audio</span>
            }
          </div>
        );
      })}
    </div>
  );
}

export default SoundPlayer;
