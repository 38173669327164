import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

import categoryApiObject from "Api/Models/Categories";
const categoryApi = new categoryApiObject();

export const getProfessions = () => {
  const returnDatas: ApiReturnDatas = defaultReturnObject;

  return new Promise((resolve, reject) => {
    categoryApi.getProfessions().then(
      (result: any) => {
        returnDatas.status = "success";
        returnDatas.data = result;
       resolve(returnDatas);
      },
      (error) => {
        returnDatas.message = "request_failure";
        reject(returnDatas);
      }
    );
  });
};


export const postTips = (props: {
  id_project: number;
  id_profession: number;
  lang: string;
}) => {
  const returnDatas: ApiReturnDatas = defaultReturnObject;
  return new Promise((resolve, reject) => {
    categoryApi.postTips(props).then(
      (result: any) => {
        returnDatas.status = "success";
        returnDatas.message = "";
        resolve(returnDatas);
      },
      (error: any) => {
        reject(returnDatas);
      }
    );
  });
};
