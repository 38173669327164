import React from "react";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";

import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import Logo from "Assets/Images/logo/logo_min.png";

function Navigation(props: { current: string | undefined }) {
	const dashboardIcon = require("Assets/Images/clear/navigation/dashboard_icon.png");
	const reportIcon = require("Assets/Images/clear/navigation/report_icon.png");
	const settingsIcon = require("Assets/Images/clear/navigation/settings_icon.png");

	//const projectIcon = require("Assets/Images/clear/navigation/project_icon.png");

	const user = useAppSelector(getUser);

	return (
		<div className={"navigation"}>
			<img className="app_icon" src={Logo} alt="Sayto" />
			<div className={"menu"}>
				<Link
					to={props.current === "project" ? "" : "/project"}
					className={props.current === "project" ? "active" : ""}
				>
					<CottageOutlinedIcon />
					<span>Accueil</span>
				</Link>
				{/*
        <Link
          to={"/project/"+currentProjectId}
          className={props.current === "project" ? "active" : ""}
        >
          <img src={projectIcon} alt="User" />
        </Link>
  */}
				<Link
					to="/reports"
					className={props.current === "reports" ? "active" : ""}
				>
					<LeaderboardOutlinedIcon />
					<span>Statistiques</span>
				</Link>
				<Link
					to="/settings"
					className={props.current === "settings" ? "active" : ""}
				>
					<SettingsOutlinedIcon />
					<span>Paramètres</span>
				</Link>

				<Link
					to="/account"
					className={props.current === "account" ? "active" : ""}
				>
					<Tooltip title="Gestion de votre profil et de votre abonnement">
						<AccountCircleOutlinedIcon />
					</Tooltip>
					<span>Profil</span>
				</Link>
			</div>
		</div>
	);
}

export default Navigation;
