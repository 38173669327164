import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface AppState {
	isSync: boolean;
	lastSync: string;
	lang: string;
	currency: string;
	notifications: Array<{
		type: "success" | "error" | "warning" | "";
		message: string;
	}>;
}

const initialState: AppState = {
	isSync: false,
	lastSync: "0000-00-00",
	lang: "fr",
	currency: "euro",
	notifications: [],
};

export const AppSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		doneSync: (state, action: PayloadAction<any>) => {
			state.isSync = true;
			state.lastSync = new Date().toString();
		},
		setError: (state, action: PayloadAction<any>) => {
			state.notifications.push({
				type: "error",
				message: action.payload?.message,
			});
		},
		setNotification: (state, action: PayloadAction<any>) => {
			state.notifications.push({
				type: action.payload?.type,
				message: action.payload?.message,
			});
		},
		clearNotifications: (state, action: PayloadAction<any>) => {
			state.notifications = [];
		},
		deleteNotification: (state, action: PayloadAction<any>) => {
			state.notifications = state.notifications.filter(
				(notification, index: number) => {
					return index !== action.payload;
				}
			);
		},
	},
});

export const {
	doneSync,
	setError,
	deleteNotification,
	clearNotifications,
	setNotification,
} = AppSlice.actions;

export const isSync = (state: RootState) => state.app.isSync;
export const getNofications = (state: RootState) => state.app.notifications;
export const getAppSettings = (state: RootState) => state.app;

export default AppSlice.reducer;
